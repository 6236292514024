import * as Actions from "./actionsType";
const notifications = (
    state: Actions.NotificationProps[] = [],
    action: Actions.NotificationProps
) => {
    switch(action.type){
        case Actions.ADD_NOTIFICATION:
            return [
                ...state,
            {status: action.status,
            title: action.title,
            text: action.text}]
        case Actions.REMOVE_NOTIFICATION: 
            return state.slice(1, state.length)
    }
    return state
}

export default notifications