export const verifyEmail = (email: string) => {
    if(!/^[A-Z0-9._%+-]+@[A-Z0-9-]+\.[A-Z]{2,4}$/i.test(email) && !/^[A-Z0-9._%+-]+@[A-Z0-9-]+\.[A-Z0-9-]+\.[A-Z]{2,4}$/i.test(email)){
        return false
    }
    return true
}

export const checkPasswordRestrictions = (text:string) => {

    const getMatch = (regex: RegExp) => {

      const value = text.match(regex)
      return value == null? 0:value.length

    }

      const getLength = () => {
            return text.length >= 12 ? true:false
      }
      const getLowercase = () => {
          return getMatch(/[a-z]/g) > 0
      }

      const getUppercase = () => {
            return getMatch(/[A-Z]/g) > 0
      }

      const getSpecial = () => {
          return getMatch(/[-!@#$%ˆ&*()+~`_={}[\]|\\;:'"<>,.?/]/g) > 0
      }

      const verify = {lenght: getLength(), lowercase: getLowercase(), uppercase: getUppercase(),
                     special: getSpecial()}

      return verify
}

export const numberRegex = (text: string, number: string) => {
    let newText = text

    if(text.length === 10 && text.length > number.length){
        newText = text.slice(0, 4) + text.slice(5,6) + '-' + text.slice(6)
    }
    if(text.length === 4 && text.length > number.length){
        newText = text+'-'
    }
    if(number.length===4 && text.length > number.length){
        newText = text.slice(0,4 ) + '-' + text.slice(4)
    }

    if(number.length === 10) {
        newText = text.slice(0,4) + '-' + text.slice(6)
    }

    return newText
}

export const ReplaceSpecialCharacters = (text: string) => {
    let newText = text
    newText = newText.replaceAll('+','%2B')
    newText = newText.replaceAll('$','%24')
    newText = newText.replaceAll('&','%26')
    newText = newText.replaceAll(',','%2C')
    newText = newText.replaceAll('/','%2F')
    newText = newText.replaceAll(':','%3A')
    newText = newText.replaceAll(';','%3B')
    newText = newText.replaceAll('=','%3D')
    newText = newText.replaceAll('?','%3F')
    newText = newText.replaceAll('@','%40')
    newText = newText.replaceAll('-','%2D')
    newText = newText.replaceAll('_','%5F')

    return newText
}

export const UnreplaceSpecialCharacters = (text: string) => {
    let newText = text
    newText = newText.replaceAll('%2B','+')
    newText = newText.replaceAll('%24','$')
    newText = newText.replaceAll('%26','&')
    newText = newText.replaceAll('%2C',',')
    newText = newText.replaceAll('%2F','/')
    newText = newText.replaceAll('%3A',':')
    newText = newText.replaceAll('%3B',';')
    newText = newText.replaceAll('%3D','=')
    newText = newText.replaceAll('%3F','?')
    newText = newText.replaceAll('%40','@')
    newText = newText.replaceAll('%2D','-')
    newText = newText.replaceAll('%5F','_')

    return newText
}

export const ResolveErrors = (status: number, code: string, message: string, navigate: any, dispatch: any, 
        content?: any) => {
    switch(status){
        case 400:
            if(code === 'ACS-11'){
                dispatch({type: 'ADD_NOTIFICATION', status: 2, title: '', text: message})
                navigate('/privacidade')
                return
            }
            if(code === 'LGN-1'){
                dispatch({type: 'ADD_NOTIFICATION', status: 3, title: '', text: message})
                return
            }
            if(code === 'LGN-3'){
                dispatch({type: 'ADD_NOTIFICATION', status: 3, title: '', text: message})
                return
            }   
            if(code === 'DSS-1'){
                dispatch({type: 'ADD_NOTIFICATION', status: 2, title: '', text: message})
                navigate('/privacidade-empresa', {state: {nivel: 1, nome: content.nomeUsuario, filial: content.nomeFilial,
                    idFilial: content.idFilial}})
                    return
            }
            if(code === 'DSS-2'){
                dispatch({type: 'ADD_NOTIFICATION', status: 2, title: '', text: message})
                navigate('/privacidade-empresa', {state: {nivel: 2, nome: content.nomeUsuario, filial: content.nomeFilial,
                    idFilial: content.idFilial}})
                return
            }
            dispatch({type: 'ADD_NOTIFICATION', status: 3, title: '', text: message})
            break;
        case 401:
            if(code === 'ACS-9'){
                navigate('/')
            }
            if(code === 'ACS-10'){
                navigate('/')
            }
            localStorage.removeItem("@logged")
            dispatch({type: 'ADD_NOTIFICATION', status: 3, title: '', text: message})
            break; 
        case 403:
            if(code === 'ACS-4'){
                dispatch({type: 'ADD_NOTIFICATION', status: 2, title: '', text: message})
                navigate("/ativar-conta")
                return
            }
            if(code === 'ACS-6'){
                dispatch({type: 'ADD_NOTIFICATION', status: 2, title: '', text: message})
                navigate('/autenticacao')
                return
            }
            if(code === 'ACS-7'){
                dispatch({type: 'ADD_NOTIFICATION', status: 2, title: '', text: message})
                navigate('/autenticacao')
                return
            }
            dispatch({type: 'ADD_NOTIFICATION', status: 3, title: '', text: message})
            break; 
        case 500:
            dispatch({type: 'ADD_NOTIFICATION', status: 3, title: '', text: "Internal server error"})
            break  
            default:
                dispatch({type: 'ADD_NOTIFICATION', status: 3, title: "", text: message})
                break   
    }       
}

export const getTime = (time: number) => {
    if(time === 120){
        return "02:00"
    }
    if(time > 59){
        if(time < 70){
            return "01:0"+(time-60)
        }
        return "01:"+(time-60)
    }
    else{
        if(time < 10){
            return "00:0"+time
        }
        return "00:"+time
    }
}

export const SortResponse = (r: any, key: string) => {
    r.sort((a: any, b: any) => {
        const nameA = a[key].toUpperCase();
        const nameB = b[key].toUpperCase(); 
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
    })
    
    return r
}