import styled from "styled-components";
import * as defines from '../../../utils/defines/Defines'

export const Container = styled.div`
    background: ${defines.BackgroundColor};
    width: 100vw;
    min-height: 100vh;

    box-sizing: border-box;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    overflow-y: scroll;
`

export const Content = styled.div`
    padding-top: 50px;
    width: 100%;
    display: flex;
    grid-gap: 10px 10px;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;

`

export const Title = styled.h1`
    font-family: 'Nunito';
    font-weight: bold;
    font-size: 16px;
    color: #000;
    margin-bottom: 10px;
`

export const Row = styled.div`
    display: flex;
    grid-gap: 0px 20px;
    flex-wrap: wrap;
`

export const ResultContent = styled.div`
    display: flex;
    flex-direction: column;
`

export const SubTitle = styled.a`
    font-family: 'Nunito';
    font-size: 14px;    
    color: #6D7989;
    margin-bottom: 5px;
`
export const Text = styled.a`
    font-family: 'Nunito';
    font-weight: bold;
    font-size: 14px;    
    color: #000;
    margin-bottom: 5px;
`
export const HalfContent = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 10px 10px;
    max-height: 100%;
`

export const Photo = styled.img`
    width: 180px;
    height: 180px;
    border-radius: 90px;
`