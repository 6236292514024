import React, { useEffect, useState } from "react";
import * as Body from './Styles'
import * as defines from '../../../utils/defines/Defines' 
import * as Functions from '../../../utils/functions/Functions' 
import CodeInput from "../../components/Inputs/InputCode/Input";
import Button from "../../components/Buttons/Button/Button";
import ButtonWithoutBG from "../../components/Buttons/ButtonWithoutBG/Button";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

const ActivateAccount = () => {
    const [tfaCode, setCodigo] = useState("")
    const [loading, toggleLoading] = useState(false)
    const [loadingCode, toggleCode] = useState(false)
    const [time, setTime] = useState(120)
    const [lock, toggleLock] = useState(false)
    const [params] = useSearchParams()
    const email = params.get('email')

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        if(time === 0){
            toggleLock(false)
            return
        }
        const interval = setInterval(() => {
            setTime(seconds => seconds - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, [time]);

    const tryResend = () => {
        toggleCode(true)
        axios.get(defines.apiURL+"/api/LoginAccountActivation", {withCredentials: true})
            .then((response)=>{
                toggleCode(false)
                setTime(120)
                toggleLock(true)
                dispatch({type: 'ADD_NOTIFICATION', title: 'Código enviado', status: 1})
            })
            .catch((error)=>{
                toggleCode(false)
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            })
    }

    const tryActivate = () => {
        toggleLoading(true)
        axios.post(defines.apiURL+"/api/LoginAccountActivation", {tfaCode, api: process.env.REACT_APP_API_URL!},
            {withCredentials: true})
            .then((response)=> {
                dispatch({type: 'ADD_NOTIFICATION', status: 1, title: "Conta ativada"})
                navigate("/minha-conta")
                toggleLoading(false)
                return
            })
            .catch((error)=>{
                const status = error.response.status
                const code = error.response.data.code
                const message = error.response.data.message
                toggleLoading(false)
                switch(status){
                    case 400:   
                        if(code === 'ACS-11'){
                            dispatch({type: 'ADD_NOTIFICATION', status: 2, title: '', text: message})
                            navigate('/lgpd')
                            return
                        }
                        Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                        break;
                } 
                return
            })
    }

    useEffect(()=>{
        toggleCode(true)
        axios.get(defines.apiURL+"/api/LoginAccountActivation", {withCredentials: true})
        .then((response)=>{
            setTime(120)
            toggleLock(true)
            toggleCode(false)
            dispatch({type: 'ADD_NOTIFICATION', title: 'Código enviado', status: 1})
        })
        .catch((error)=>{
            toggleCode(false)
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
        })
    }, [dispatch, navigate])

    return <Body.Container>
        <Body.Row>
            <Body.TitleCompany color={defines.BlueTec}>
                tectrol
            </Body.TitleCompany>
            <Body.Circle />
            <Body.TitleCompany color={defines.GreenTec}>
                dínamo
            </Body.TitleCompany>
        </Body.Row>
        <Body.Title>Conecta</Body.Title>
        <Body.CardLogin >
            <Body.SubTitle>Ativação de conta</Body.SubTitle>
            <Body.Text>
                Sua conta ainda não está ativa! Enviamos um código de verificação para seu email. Insira para prosseguir.
            </Body.Text>
            <CodeInput setText={setCodigo} action={()=>tryActivate()}/>
            <Button text="Ativar" action={()=>tryActivate()} loading={loading}/>
            {lock?<Body.Text>Aguarde {Functions.getTime(time)} para enviar novamente</Body.Text>
            :<ButtonWithoutBG text="Reenviar código" loading={loadingCode} action={()=>tryResend()}/>}
        </Body.CardLogin>
    </Body.Container>
}

export default ActivateAccount