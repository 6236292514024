import styled from "styled-components";
import * as defines from '../../../../utils/defines/Defines'

export const FindContent = styled.div`
    width: 100%;
    position: relative;
`

export const Icon = styled.img`
    position: absolute;
    top: 7.5px;
    left: 7.5px;
    width: 15px;
    height: 15px;
`

export const Find = styled.input`
    width: 100%;
    height: 30px;
    border-radius: 15px;
    border: 1px solid #1080D6;
    padding-left: 30px;
    font-family: "Nunito";
`

export const Scroll = styled.div`
    width: 100%;
    padding-top: 10px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none; 
    }
    box-sizing: border-box; 
    max-height: 50vh;
`

export const User = styled.div`
    width: 100%;
    height: 60px;
    background: #FFF;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid rgba(0,0,0,0.05);
    align-items: center;
    justify-content: space-between;
`

export const UserTitle = styled.a`
    font-family: 'Nunito';
    font-size: 14px;
    font-weight: 400;
    color: #000;
`

export const UserPhoto = styled.img`
    width: 35px;
    height: 35px;
    border-radius: 20px;
`

export const UserText = styled.a`
    font-family: 'Nunito';
    font-size: 14px;
    font-weight: 400;
    color: #6D7989;
`

export const SelectCard = styled.div<{active?: boolean}>`
    width: 150px;
    border-radius: 12px;
    color: #FFF;
    padding: 20px;
    border: 1px solid ${props => props.active?defines.BlueTec:"rgba(0,0,0,0.05)"};
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 10px 0px;
`

export const ButtonIcon = styled.img`
    height: 30px;
    width: 30px;
    cursor: pointer;
`