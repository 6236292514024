import styled from "styled-components";
import * as defines from '../../../utils/defines/Defines'

export const Card = styled.div`
    width: 280px;
    height: 80%;
    border-radius: 20px;
    border: none;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    background-color: ${defines.BackgroundColor};
    -webkit-box-shadow: 10px 10px 30px 1px rgba(211,211,212,0.4),
        -10px -10px 30px 1px rgba(255,255,255,0.8); 
    box-shadow: -10px -10px 30px 1px rgba(255,255,255,0.8),
        10px 10px 30px 1px rgba(211,211,212,0.4);
    position: absolute;
    left: 60px;
`

export const Photo = styled.img`
    width: 120px;
    height: 120px;
    border-radius: 60px;
`
export const Title = styled.a`
    font-family: "Comfortaa";
    font-weight: medium;
    fonts-size: 18px;
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 10px 0px;
`

export const Image = styled.img`
    width: 150px;
    height: 100px;
`

export const BottomBar = styled.div`
    width: 100%;
    height: 1px;
    background-color: rgba(10,10,10,0.4);
`