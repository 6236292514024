import React from 'react'
import * as Body from './Styles'

import CancelIcon from '../../../assets/icons/close-icon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../redux/store'
import { AlertProps } from '../../redux/alert/actionsType'


const Alert= () => {

    const data: AlertProps[] = useSelector((state: State) => state.alert)
    const dispatch = useDispatch()

    const removeAlert = () => {
        dispatch({type: 'REMOVE_ALERT'})
    }

    if(data.length === 0){
        return <></>
    }

    return <Body.Container>
        <Body.Modal>
            <Body.Header>
                <Body.Row>
                   {data[0].title? <Body.Title>{data[0].title}</Body.Title>:null}
                </Body.Row>
                <Body.Row>
                    <Body.Button onClick={()=>removeAlert()}>
                        <Body.BtnIcon src={CancelIcon} />
                    </Body.Button>
                </Body.Row>
            </Body.Header>
            <Body.Text>
                {data[0].text}
            </Body.Text>
        </Body.Modal>
    </Body.Container>
}

export default Alert