import React from "react";
import * as Body from './Styles'

import SpinnerIcon from '../../../assets/icons/spinner.svg'
import SpinnerBlue from '../../../assets/icons/spinner-blue.svg'

type SpinnerProps = {
    size: number,
    blue?: boolean
}

const Spinner: React.FC <SpinnerProps> = ({
    size, blue
}) => {
    return <Body.Icon src={blue?SpinnerBlue:SpinnerIcon} size={size}/>
}

export default Spinner