import * as Actions from "./actionsType";
const acessLevel = (
    state: Actions.AcessLevelProps = {acessLevel: 1},
    action: Actions.AcessLevelProps
) => {
    switch(action.type){
        case Actions.SET_ACESS_LEVEL:
            return {acessLevel: action.acessLevel}
    }
    return state
}

export default acessLevel