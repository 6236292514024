import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../components/SideBar/Sidebar";
import * as Body from './Styles'
import SearchIcon from '../../../assets/icons/Search.svg'
import AddIcon from '../../../assets/icons/add-icon.svg'
import * as Functions from  '../../../utils/functions/Functions'
import * as defines from  '../../../utils/defines/Defines'
import * as Types from '../../../utils/types/Types'
import Input from "../../components/Inputs/Input/Input";
import Button from "../../components/Buttons/Button/Button";
import * as Modals from './Modals/Modals'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loading from "../../components/Loading/Loading";
import IsAuth from "../../components/Modals/IsAuth/IsAuth";

const ManageUsers = () => {
    const [hasLength, toggleHasLength] = useState(false)
    const [hasUser, toggleHasUser] = useState(false)
    const [modalCreateUser, toggleCreateUser] = useState(false)
    const [modalUnits, toggleUnits] = useState(false)
    const [modalDelete, toggleDelete] = useState(false)
    const [data, setData] = useState<Types.UserList>([])
    const [filteredUsers, setFiltered] = useState<Types.UserList>([])
    const [loading, toggleLoading] = useState(true)
    const [isTectrol, toggleTectrol] = useState(false)
    const [isAuthCreate, toggleAuthCreate] = useState(false)
    const [isAuthDelete, toggleAuthDelete] = useState(false)
    const [isAuthChange, toggleAuthChange] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [nomeCompleto, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [ddd, setDDD] = useState('')
    const [celular, setTelefone] = useState('')
    const [idUsuario, setId] = useState('')
    const [loadedUserData, setUserData] = useState({email: '', celular:'', ddd: '', nomeCompleto: ''})
    const [unidades, setUnidades] = useState<Types.Unidade[]>([])
    const inputRef = useRef<any>()

    const getUsers = () => {
        toggleLoading(true)
        axios.get(defines.apiURL+"/api/UsuarioCRUD",  {withCredentials: true})
            .then((response) => {
                const sorted = Functions.SortResponse(response.data.content, "nomeCompleto")
                setData(sorted)
                setFiltered(sorted)
                setTimeout(()=>{
                    toggleLoading(false)
                }, 2000)
            })
            .catch((error) => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    }

    const getUnitys = (replacedId: string) => {
        setUnidades([])
        axios.get(defines.apiURL+"/api/ParticipationList?userUnavailableBranches="+replacedId,  {withCredentials: true})
        .then((response)=>{
            setUnidades(response.data.content)
            toggleHasUser(true)
            toggleLoading(false)
        })
        .catch((error)=>{
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
        })
    }

    const selectUser = (id: string) => {
        const replacedId = Functions.ReplaceSpecialCharacters(id)
        if(replacedId === idUsuario && hasUser){
            toggleHasUser(false)
            return
        }
        setNome('')
        setDDD('')
        setEmail('')
        setTelefone('')
        setUserData({email: '', celular:'', ddd: '', nomeCompleto: ''})
        setId(replacedId)
        toggleLoading(true)
        axios.get(defines.apiURL+"/api/UsuarioCRUD?id="+replacedId,  {withCredentials: true})
            .then((response)=>{
                let responseData = response.data.content
                setNome(responseData.nomeCompleto)
                setDDD(responseData.ddd)
                setEmail(responseData.email)
                setTelefone(responseData.celular)
                setUserData(responseData)
                setId(replacedId)
                if(responseData.nivelAcesso !== defines.NivelAcesso.TECTROL){
                    getUnitys(replacedId)
                    toggleTectrol(false)
                }else{
                    toggleHasUser(true)
                    toggleTectrol(true)
                    toggleLoading(false)
                }
            })
            .catch((error) => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
                return
            })
    }

    const filterData = (name: string) => {
        if(name.length === 0){
            setFiltered(data)
            toggleHasLength(false)
            return
        }
        toggleHasLength(true)
        const filtered = data.filter(item => item.nomeCompleto.toUpperCase().includes(name.toUpperCase()))
        setFiltered(filtered!)
    }

    const deleteUser = () => {
        axios.delete(defines.apiURL+"/api/UsuarioCRUD?id="+idUsuario,  {withCredentials: true})
        .then((response)=>{
            dispatch({type: 'ADD_NOTIFICATION', status: 1, text: 'Usuário excluido com sucesso', title: ''})
            toggleHasUser(false)
            toggleDelete(false)
            getUsers()
        })
        .catch((error)=>{
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
        })
    }


    const updateUser = () => {
        const novoEmail = email === loadedUserData.email?'':email
        const novoCelular = celular === loadedUserData.celular?'':celular
        const novoDDD = ddd === loadedUserData.ddd?'':ddd
        const novoNome = nomeCompleto === loadedUserData.nomeCompleto?'':nomeCompleto

        axios.put(defines.apiURL+"/api/UsuarioCRUD", 
            {email: novoEmail, nomeCompleto: novoNome, 
                ddd: novoDDD, celular: novoCelular, 
                idUsuario: Functions.UnreplaceSpecialCharacters(idUsuario)},  {withCredentials: true})
            .then((response)=>{
                dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '',
                    text: 'Dados do usuario alterados com sucesso'})
                selectUser(idUsuario)
                getUsers()
            })
            .catch((error)=>{
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            })
    }


    useEffect(()=>{
        toggleLoading(true)
        axios.get(defines.apiURL+"/api/UsuarioCRUD", {withCredentials: true})
            .then((response) => {
                const sorted = Functions.SortResponse(response.data.content, "nomeCompleto")
                setData(sorted)
                setFiltered(sorted)
                setTimeout(()=>{
                    toggleLoading(false)
                }, 2000)
            })
            .catch((error) => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    }, [dispatch, navigate])


    return <Body.Container>
        <Sidebar content={defines.ContentSidebar.MANAGER_USERS} />
        <Body.Content style={{flexDirection: 'row'}}>
            <Body.HalfContent style={hasUser?{width: 'calc(100% - 120px)'}:{width: '100%'}}>
                <Body.Row style={{width: "100%", height: '140px',
                    justifyContent: 'space-between', alignItems: 'center'}}>
                    <Body.Title >
                        Usuários
                    </Body.Title>
                    <Body.Row style={{maxWidth: '90%', minWidth: '50px'}}>
                        <Body.FindContent>
                            <Body.Find style={hasLength?{width: '300px'}:{}}
                                onChange={e => filterData(e.target.value)}
                                ref={inputRef} />
                            <Body.Icon src={SearchIcon} style={{cursor: 'text'}}
                                onClick={()=>inputRef.current.focus()} />
                        </Body.FindContent>
                        <Body.HeaderButton onClick={()=>toggleAuthCreate(true)}>
                            <Body.Icon src={AddIcon} style={{position: 'relative', top: 0, left: 0}} />
                        </Body.HeaderButton>
                    </Body.Row>                        
                </Body.Row>
                <Body.Scroll style={{maxHeight: 'calc(100% - 120px)'}}>
                    {filteredUsers.map((item, index)=> {
                        return <Body.SelectUser onClick={()=>selectUser(item.idUsuario)} 
                            key={index.toString()} >
                            {/* <Body.Photo src={item.imagemUsuario} /> */}
                            <Body.Text>{item.nomeCompleto}</Body.Text>
                        </Body.SelectUser>
                    })}
                </Body.Scroll>
            </Body.HalfContent>
            {hasUser?<Body.HalfContent style={{alignItems: 'center'
                    , width: hasUser?'100px':'0px'}}>
                    {/* <Body.Photo src={selectedUser.imagemUsuario} style={{width: '120px',
                        borderRadius: '60px',height: '120px'}}/> */}
                    <Body.Scroll style={{height: '100%', marginBottom: '10px',
                        maxHeight: 'calc(100vh - 80px)',
                        display: 'flex', flexDirection: 'column', gridGap: '20px 0px'}}>
                        <Input text={nomeCompleto} setText={setNome} title="Nome Completo" />
                        <Input text={email} setText={setEmail} title="Email" />
                        {!isTectrol?<div>
                            <Body.SubTitle style={{marginBottom: '20px'}}>
                                Unidades</Body.SubTitle>
                            <Body.CardUnit onClick={()=>toggleAuthChange(true)}>
                                <Body.TextUnit>
                                    {unidades.map((item, index)=>{
                                        return item.nomeFilial.concat(index !== unidades.length-1?", ":"")
                                    })}
                                </Body.TextUnit>
                            </Body.CardUnit>
                        </div>:null}
                        <Body.Row>
                            <div style={{width: '20%'}}>
                                <Input text={ddd} setText={setDDD} title="DDD"
                                    limit={2} />
                            </div>
                            <div style={{width: '79%'}}>
                                <Input text={celular} setText={setTelefone} title="Telefone" />
                            </div>
                        </Body.Row>
                    </Body.Scroll>
                    <Body.Row>
                        <Button text="Salvar" action={()=>updateUser()} width={155}/>
                        <Button text="Apagar usuário" action={()=>toggleAuthDelete(true)} width={155}
                            color="red"/>
                    </Body.Row>
            </Body.HalfContent>:null}
        </Body.Content>
        {modalCreateUser?<Modals.CreateUser toggle={toggleCreateUser} action={()=>getUsers()}  />:null}
        {modalDelete?<Modals.ModalDeleteUser toggle={toggleDelete} idUsuario={idUsuario}
            nome={nomeCompleto} action={deleteUser} />:null}
        {modalUnits?<Modals.ChangeUnit toggle={toggleUnits} idUsuario={idUsuario}
            nome={nomeCompleto} email={email} />:null}
        {loading?<Loading />:null}
        {isAuthCreate?<IsAuth action={()=>toggleCreateUser(true)} toggleIsAuth={toggleAuthCreate} />:null}
        {isAuthDelete?<IsAuth action={()=>toggleDelete(true)} toggleIsAuth={toggleAuthDelete} />:null}
        {isAuthChange?<IsAuth action={()=>toggleUnits(true)} toggleIsAuth={toggleAuthChange} />:null}
    </Body.Container>
}

export default ManageUsers
