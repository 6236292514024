import React, { useEffect, useState } from "react";
import Sidebar from "../../components/SideBar/Sidebar";
import * as Body from './Styles'
import * as defines from '../../../utils/defines/Defines'
import * as Functions from '../../../utils/functions/Functions'
import Input from "../../components/Inputs/Input/Input";
import * as Types from '../../../utils/types/Types'
// import ButtonWithoutBG from "../../components/Buttons/ButtonWithoutBG/Button";
// import imageCompression from "browser-image-compression";
import Button from "../../components/Buttons/Button/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading/Loading";
import * as Modals from './modals/Modals'
import { State } from "../../redux/store";
import IsAuth from "../../components/Modals/IsAuth/IsAuth";

const MyAccount = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const acessLevel = useSelector((state: State) => state.acessLevel.acessLevel)
    const [loading, toggleLoading] = useState(true)
    const [changePWD, togglePWD] = useState(false)
    const [deleteAccount, toggleDelete] = useState(false)
    const [isAuth, toggleIsAuth] = useState(false)

    // const hiddenFileInput = useRef<HTMLInputElement>(null)
    // const [foto, setFoto] = useState("")

    const [idUsuario, setId] = useState("")
    const [nomeCompleto, setNome] = useState('')
    const [ddd, setDDD] = useState('')
    const [celular, setCelular] = useState('')
    const [email, setEmail] = useState('')
    const [empresa, setEmpresa] = useState('')
    const [cnpj, setCNPJ] = useState('')
    const [data, setData] = useState<Types.MinhaContaProps>()
    const [filiais, setFiliais] = useState<Types.Unidade[]>([])
    const [loadingSave, toggleSave] = useState(false)

    const getCompanyData = () => {
        axios.get(defines.apiURL+"/api/ParticipationList",  {withCredentials: true})
            .then((response)=>{
                setEmpresa(response.data.content.nomeEmpresa)
                setCNPJ(response.data.content.cnpj)
                setFiliais(response.data.content.filiais)
                toggleLoading(false)
            })
            .catch((error)=>{
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
                return
            })
    }

    const getData = () => {
        toggleLoading(true)

        axios.get(defines.apiURL+"/api/UsuarioCRUD?currentUser=1", {withCredentials: true})
            .then((response)=>{
                setData(response.data.content)
                setId(response.data.content.idUsuario)
                if(response.data.content.nivelAcesso !== defines.NivelAcesso.TECTROL){
                    getCompanyData()
                }else{
                    toggleLoading(false)
                }
            })
            .catch((error)=>{
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
                return
            })
    }
    
    useEffect(()=>{
        toggleLoading(true)

        axios.get(defines.apiURL+"/api/UsuarioCRUD?currentUser=1", {withCredentials: true})
            .then((response)=>{
                setData(response.data.content)
                setId(response.data.content.idUsuario)
                if(response.data.content.nivelAcesso !== defines.NivelAcesso.TECTROL){
                    axios.get(defines.apiURL+"/api/ParticipationList", {withCredentials: true})
                    .then((response)=>{
                            setEmpresa(response.data.content.nomeEmpresa)
                            setCNPJ(response.data.content.cnpj)
                            setFiliais(response.data.content.filiais)
                            toggleLoading(false)
                        })
                        .catch((error)=>{
                            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                            toggleLoading(false)
                            return
                        })
                }else{
                    toggleLoading(false)
                }
            })
            .catch((error)=>{
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
                return
            })
    },[dispatch, navigate])

    const changeUserData = () => {
        toggleSave(true)
        axios.put(defines.apiURL+"/api/UsuarioCRUD", {nomeCompleto, email, celular, ddd,  idUsuario},
        {withCredentials: true})
        .then(response => {
            dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '', text: 'Dados alterados com sucesso'})
            getData()
            setNome('')
            setDDD('')
            setCelular('')
            setEmail('')
            toggleSave(false)
        })
        .catch(error => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleSave(false)
        })
    }

    // const handleClick = () => {
    //     if(hiddenFileInput.current){
    //         hiddenFileInput.current.click();
    //     }
    // };

    // const compressImage = async (fileUploaded: any) => {
    //     const options = {
    //         maxSizeMB: 0.256,
    //         maxWidthOrHeight: 300,
    //         useWebWorker: true
    //     }


    //     try{         
    //         const compressedImage = await imageCompression(fileUploaded, options)
    //         const compressedBase64 = await imageCompression.getDataUrlFromFile(compressedImage)

    //         console.log(compressedBase64)

    
    //         setFoto(compressedBase64)

    //     }catch(error){
    //         console.log(error)
    //         alert('Não foi possível selecionar essa imagem. Verifique a imagem e tente novamente.')
    //     }finally{
    //     }     

    //     setFoto(fileUploaded)
    // };


    return <Body.Container>
        <Sidebar content={defines.ContentSidebar.MY_ACCOUNT} />
        <Body.Content>
            <Body.HalfContent style={{width: '560px'}}>
            <Body.Title>DADOS PESSOAIS</Body.Title>
            <Body.Row>
                <Input text={nomeCompleto} setText={setNome} placeholder={data?.nomeCompleto} title="Nome Completo"/>
            </Body.Row>
            <Body.Row style={{marginBottom: '10px'}}>
                <Body.Row style={{width: '220px', gridGap: '0px 10px'}}>
                    <Input text={ddd} setText={setDDD} limit={2} title="DDD"  placeholder={data?.ddd}
                        width={50}/>
                    <Input text={celular} setText={setCelular} title="Telefone" placeholder={data?.celular}
                        limit={9} width={150}/>
                </Body.Row>
                <Input text={email} setText={setEmail} title="Email" width={320} placeholder={data?.email} />
            </Body.Row>
            <Body.Row>
                <Button text="Salvar" color="blue" action={()=>changeUserData()}
                    loading={loadingSave} width={150}/>
                <Button text="Alterar senha" action={()=>toggleIsAuth(true)} width={150}/>
                <Button text="Deletar conta" color="red" action={()=>toggleDelete(true)} width={150}/>
            </Body.Row>
            {acessLevel !== defines.NivelAcesso.TECTROL?
            <>
            <Body.Title style={{marginTop: '20px'}}>DADOS DA EMPRESA</Body.Title>
            <Body.Row>
                <Body.ResultContent>
                    <Body.SubTitle>Nome</Body.SubTitle>
                    <Body.Text>{empresa}</Body.Text>
                </Body.ResultContent>
                <Body.ResultContent>
                    <Body.SubTitle>CNPJ</Body.SubTitle>
                    <Body.Text>{cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4.$5")}</Body.Text>
                </Body.ResultContent>
            </Body.Row>
            <Body.Row>
                <Body.ResultContent>
                    <Body.SubTitle>Unidades vinculadas à você</Body.SubTitle>
                    <Body.Row style={{gridGap: '0px 5px'}}>
                        {filiais.map((item, index)=>{
                            return <Body.Text key={index.toString()}>
                                {item.nomeFilial}{index!==(filiais.length! - 1)?", ":''}
                            </Body.Text>
                        })}
                    </Body.Row>
                </Body.ResultContent>
            </Body.Row>
            <Body.SubTitle>Você não pode alterar os dados da empresa.
                Se algo estiver divergente, por favor, entre em contato.</Body.SubTitle></>: null}
            </Body.HalfContent>
            {/* <Body.HalfContent style={{alignItems: 'center'}}>
                <Body.Photo src={foto?foto:data.imagemUsuario} />
                <input type="file" style={{display:'none'}} 
                    ref={hiddenFileInput}
                    accept="image/*"
                    onChange={(e) => compressImage(e.target.files![0])}/> 
                <ButtonWithoutBG text="Alterar foto" action={handleClick} />
            </Body.HalfContent> */}
        </Body.Content>
        {loading?<Loading />:null}
        {changePWD?<Modals.ChangePWD toggle={togglePWD} idUsuario={idUsuario} />:null}
        {deleteAccount?<Modals.DeleteAccount toggle={toggleDelete} idUsuario={Functions.ReplaceSpecialCharacters(idUsuario)} />:null}
        {isAuth?<IsAuth action={()=>togglePWD(true)} toggleIsAuth={toggleIsAuth} />:null}
    </Body.Container>
}

export default MyAccount