import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import * as Body from './Styles'
import * as defines from '../../../utils/defines/Defines' 
import ButtonWithoutBG from "../../components/Buttons/ButtonWithoutBG/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import Input from "../../components/Inputs/Input/Input";
import Button from "../../components/Buttons/Button/Button";
import CodeInput from "../../components/Inputs/InputCode/Input";
import * as Functions from '../../../utils/functions/Functions'
import axios from "axios";
import { useDispatch } from "react-redux";

const Content = {
    SEND: 1,
    RECOVER: 3
}

type SendProps = {
    email: string,
    setEmail: Dispatch<SetStateAction<string>>,
    setNumero: Dispatch<SetStateAction<string>>,
    toggleContent: Dispatch<SetStateAction<number>>,
}

type RedefineProps = {
    email: string,
    numero: string
}


const Send: React.FC <SendProps> = ({
    email, setEmail, setNumero, toggleContent
}) => {
    const navigate = useNavigate()
    const [loading, toggleLoading] = useState(false) 

    const [errorEmail, toggleErrorEmail] = useState(false)
    const dispatch = useDispatch()

    const trySend = () => {
        if(!Functions.verifyEmail(email)){
            toggleErrorEmail(true)
            return
        }
        toggleLoading(true)
        axios.get(defines.apiURL+"/api/ForgottenPassword?email="+email)
         .then((response)=>{
            toggleLoading(false)
            toggleContent(Content.RECOVER)
         })
         .catch((error)=>{
            toggleLoading(false)
            switch(error.response.status){
                case 406:
                    if(error.response.data.code === "TFA-3"){
                        toggleContent(Content.RECOVER)
                        return
                    }
            }
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch)
         })
    }

    return <Body.Content style={{minHeight: '200px'}}>
                <Input text={email} setText={setEmail} placeholder="Email"
                    error={errorEmail} action={()=>trySend()} />
                {errorEmail?<Body.TextError>
                    Formato de email inválido ex: email@email.com
                </Body.TextError>:
                    null}
                <Button text="Enviar" action={()=>trySend()} 
                    loading={loading}/>
                <ButtonWithoutBG text="Voltar para o login?" action={()=>navigate('/')} />
            </Body.Content>
}

const Redefine: React.FC <RedefineProps> = ({
    email, numero
}) => {
    const navigate = useNavigate()

    const [tfaCode, setCode] = useState('')
    const [novaSenha, setSenha] = useState('')
    const [loading, toggleLoading] = useState(false)
    const [loadingCode, toggleCode] = useState(false)

    const [time, setTime] = useState(120)
    const [lock, toggleLock] = useState(true)
    
    const [hasLow, toggleHasLow] = useState(false)
    const [hasUpper, toggleHasUpper] = useState(false)
    const [hasSpecial, toggleHasSpecial] = useState(false)
    const [hasLength, toggleHasLength] = useState(false)

    const dispatch = useDispatch()


    useEffect(() => {
        if(time === 0){
            toggleLock(false)
            return
        }
        const interval = setInterval(() => {
            setTime(seconds => seconds - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, [time]);

    const tryResend = () => {
        toggleCode(true)
        axios.get(defines.apiURL+"/api/ForgottenPassword?email="+email)
        .then((response)=>{
            setTime(120)
            toggleLock(true)
            toggleCode(false)
         })
         .catch((error)=>{
            toggleCode(false)
            dispatch({type: 'ADD_NOTIFICATION', status: 3, title: 'Erro', text: error.response.data.message})
         })
    }

    const fieldPassword = (value: string) => {
        setSenha(value)

        const verify = Functions.checkPasswordRestrictions(value)

        toggleHasLow(verify.lowercase)
        toggleHasUpper(verify.uppercase)
        toggleHasSpecial(verify.special)
        toggleHasLength(verify.lenght)
    }

    const tryRedefine = () => {
        if(!hasLow || !hasSpecial || !hasLength || !hasUpper){
            dispatch({type: 'ADD_NOTIFICATION', status: 3, title: 'Erro', text: 'A senha informada não cumpre os requisitos'})
            return
        }
        toggleLoading(true)
        axios.post(defines.apiURL+"/api/ForgottenPassword", {novaSenha, email, tfaCode})
         .then((response)=>{
            toggleLoading(false)
            navigate('/')
         })
         .catch((error)=>{
            toggleLoading(false)
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch)
         })
    }

    return <Body.Content style={{minHeight: '500px'}}>
        <Body.Text>
            <strong>Enviamos um código para seu celular.</strong><br/>Se o número cadastrado estiver 
            correto, em instantes você irá recebe-lo, insira-o para prosseguir com a redefinição.
        </Body.Text>
        <CodeInput setText={setCode} />
        <Input password={true} text={novaSenha} setText={fieldPassword} action={()=>tryRedefine()}/>
        <Body.Text>
            A sua senha deve conter: letras 
            <strong style={{color: hasLow?'green':'red'}}> minúsculas, </strong>
            <strong style={{color: hasUpper?'green':'red'}}> maiúsculas, </strong>
            <strong style={{color: hasLength?'green':'red'}}> no minímo 12 caracters, </strong>
            <strong style={{color: hasSpecial?'green':'red'}}> caracteres especiais. </strong>
        </Body.Text>
        <Button text="Redefinir" action={()=>tryRedefine()} loading={loading} />
        {lock?<Body.Text>Aguarde {Functions.getTime(time)} para enviar novamente</Body.Text>
            :<ButtonWithoutBG text="Reenviar código" loading={loadingCode}  action={()=>tryResend()}/>}
    </Body.Content>
}

const Recover = () => {
    const [params] = useSearchParams()
    const emailParam = params.get('email')

    const [email, setEmail] = useState('')
    const [numero, setNumero] = useState('')
    
    const [content, toggleContent] = useState(Content.SEND)

    const getContent = () => {
        switch(content){
            case Content.SEND:
                return <Send email={email} setEmail={setEmail} setNumero={setNumero}
                    toggleContent={toggleContent} />
            case Content.RECOVER:
                return <Redefine email={email} numero={numero} />
        }
    }

    useEffect(()=>{
        if(emailParam){
            setEmail(emailParam)
        }
    }, [emailParam])

    return <Body.Container >
        <Body.TitleContent>
            <Body.Row>
                <Body.TitleCompany color={defines.BlueTec}>
                    tectrol
                </Body.TitleCompany>
                <Body.Circle />
                <Body.TitleCompany color={defines.GreenTec}>
                    dínamo
                </Body.TitleCompany>
            </Body.Row>
            <Body.Title>
                Conecta
            </Body.Title>
        </Body.TitleContent>
        {getContent()}
    </Body.Container>
}

export default Recover