import React, {useState, useRef, useEffect} from 'react'
import Loading from '../../components/Loading/Loading'
import Sidebar from '../../components/SideBar/Sidebar'
import * as Body from  './Styles'
import * as defines from '../../../utils/defines/Defines'
import SearchIcon from '../../../assets/icons/Search.svg'
import AddIcon from '../../../assets/icons/add-icon.svg'
import * as Types from '../../../utils/types/Types'
import * as Functions from '../../../utils/functions/Functions'
import * as Modals from './modals/Modals'
import axios from 'axios'
import Input from '../../components/Inputs/Input/Input'
import Button from '../../components/Buttons/Button/Button'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import IsAuth from '../../components/Modals/IsAuth/IsAuth'

const ManageCompanies = () => {
    const [loading, toggleLoading] = useState(false)
    const [loadingChange, toggleChange] = useState(false)
    const inputCompanyRef = useRef<any>()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [modalAddCompany, toggleModalCompany] = useState(false)

    const [hasLenght, toggleHasLength] = useState(false)
    const [hasCompany, toggleHasCompany] = useState(false)
    const [companies, setCompanies] = useState<Types.EmpresaInfo[]>([])
    const [nomeEmpresa, setEmpesa] = useState('')
    const [cnpj, setCNPJ] = useState('')
    const [idEmpresa, setId] = useState('')
    const [filteredCompanies, setFilteredCompanies] = useState<Types.EmpresaInfo[]>([])
    const [filiais, setFiliais] = useState<Types.FilialInfo[]>([])

    const [modalUnits, toggleUnits] = useState(false)
    const [modalDelete, toggleDelete] = useState(false) 
    const [isAuthCreate, toggleAuthCreate] = useState(false)
    const [isAuthDelete, toggleAuthDelete] = useState(false)
    const [isAuthChange, toggleAuthChange] = useState(false)

    const getFiliais = (id: string) => {
        toggleLoading(true)
        setFiliais([])
        axios.get(defines.apiURL+"/api/FilialCRUD?idEmpresa="+Functions.ReplaceSpecialCharacters(id),
            {withCredentials: true})
        .then((response)=>{
            setFiliais(response.data.content)
            toggleLoading(false)
        })
        .catch(error => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
        })
    }

    const getCompanies = () => {
        toggleLoading(true)
        axios.get(defines.apiURL+"/api/EmpresaCRUD",  {withCredentials: true})
        .then(response => {
            const sorted = Functions.SortResponse(response.data.content, "nomeEmpresa")
            setCompanies(sorted)
            setFilteredCompanies(sorted)
            setTimeout(()=>{
                toggleLoading(false)
            }, 2000)
        })
        .catch(error => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
        })
    }

    useEffect(()=>{
        toggleLoading(true)
        axios.get(defines.apiURL+"/api/EmpresaCRUD",  {withCredentials: true})
            .then(response => {
                const sorted = Functions.SortResponse(response.data.content, "nomeEmpresa")
                setCompanies(sorted)
                setFilteredCompanies(sorted)
                setTimeout(()=>{
                    toggleLoading(false)
                }, 2000)
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    }, [dispatch, navigate])

    const selectCompany = (company: Types.EmpresaInfo) => {
        
       
        if(idEmpresa === company.idEmpresa && hasCompany){
            toggleHasCompany(false)
            return
        }
        setId(company.idEmpresa)
        setCNPJ(company.cnpj)
        setEmpesa(company.nomeEmpresa)
        getFiliais(company.idEmpresa)
        toggleHasCompany(true)
    }

    const filterCompanyData = (name: string) => {
        if(name.length === 0){
            setFilteredCompanies(companies)
            toggleHasLength(false)
            return
        }
        toggleHasLength(true)
        const filtered = companies.filter(item => item.nomeEmpresa.toUpperCase().includes(name.toUpperCase()))
        setFilteredCompanies(filtered!)
    }

    const actionDelete = () => {
        getCompanies()
        toggleHasCompany(false)
    }

    const updateCompany = () => {
        if(nomeEmpresa.length === 0 || cnpj.length === 0){
            dispatch({type: 'ADD_NOTIFICATION', status: 3, title: '', text: 'Preencha todos os campos'})
            return
        }
        toggleChange(true)
        axios.put(defines.apiURL+"/api/EmpresaCRUD", {idEmpresa, nomeEmpresa},  {withCredentials: true})
        .then(response => {
            getCompanies()
            dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '', text: 'Dados da empresa alterados com sucesso'})
            toggleChange(false)
        }) 
        .catch(error => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleChange(false)
        })       
    }

    return <Body.Container>
            <Sidebar content={defines.ContentSidebar.MANAGE_COMPANY} />
            <Body.Content>
                <Body.Row style={{width: '100%', height: '100%'}}>
                    <Body.HalfContent style={{width: hasCompany?'calc(100% - 120px)':'100%'}}>
                            <Body.Row style={{width: "100%", height: '120px',
                            justifyContent: 'space-between', alignItems: 'center'}}>
                            <Body.Title >
                                Empresas
                            </Body.Title>
                            <Body.Row style={{maxWidth: '90%', minWidth: '50px'}}>
                                <Body.FindContent>
                                    <Body.Find style={hasLenght?{width: '300px'}:{}}
                                        onChange={e => filterCompanyData(e.target.value)}
                                        ref={inputCompanyRef} />
                                    <Body.Icon src={SearchIcon} style={{cursor: 'text'}}
                                        onClick={()=>inputCompanyRef.current.focus()} />
                                </Body.FindContent>
                                <Body.HeaderButton onClick={()=>toggleAuthCreate(true)} >
                                    <Body.Icon src={AddIcon} style={{position: 'relative', top: 0, left: 0}} />
                                </Body.HeaderButton>
                            </Body.Row>                        
                        </Body.Row>
                        <Body.Scroll style={{maxHeight: 'calc(100% - 80px)'}}>
                            {filteredCompanies.map((item, index) => {
                                return <Body.SelectUser onClick={()=>selectCompany(item)} 
                                    key={index.toString()} >
                                    <Body.Text>{item.nomeEmpresa}</Body.Text>
                                </Body.SelectUser>
                            })}

                        </Body.Scroll>
                        </Body.HalfContent>
                    
                    {hasCompany?<Body.HalfContent  style={{alignItems: 'center'
                            , width: hasCompany?'100px':'0px'}}>
                        <Input text={nomeEmpresa} setText={setEmpesa} title="Nome" />
                        <Input text={cnpj} setText={setCNPJ} title="CNPJ" disabled /> 
                        <div style={{width: '100%'}}>
                            <Body.SubTitle style={{marginBottom: '20px'}}>
                                Unidades</Body.SubTitle>
                            <Body.CardUnit onClick={()=>toggleAuthChange(true)}>
                                <Body.TextUnit>
                                    {filiais.map((item, index)=>{
                                        return item.nomeFilial.concat(index !== filiais.length-1?", ":"")
                                    })}
                                </Body.TextUnit>
                            </Body.CardUnit>
                        </div>
                        <Body.Row>
                            <Button text='Salvar' action={()=>updateCompany()} width={155} loading={loadingChange} />
                            <Button text='Excluir' color="red" action={()=>toggleAuthDelete(true)} width={155} 
                                disabled={loadingChange}/>
                        </Body.Row>                    
                    </Body.HalfContent>:null}
                </Body.Row>
            </Body.Content>
        {loading?<Loading />:null}
        {modalAddCompany?<Modals.AddCompany toggle={toggleModalCompany} getData={getCompanies}  />:null}
        {modalUnits?<Modals.Units id={idEmpresa} nomeEmpresa={nomeEmpresa} toggle={toggleUnits} />:null}
        {modalDelete?<Modals.ConfirmDeleteCompany nome={nomeEmpresa} id={idEmpresa} toggle={toggleDelete}
            action={actionDelete}  />:null}
        {isAuthCreate?<IsAuth action={()=>toggleModalCompany(true)} toggleIsAuth={toggleAuthCreate} />:null}
        {isAuthDelete?<IsAuth action={()=>toggleDelete(true)} toggleIsAuth={toggleAuthDelete} />:null}
        {isAuthChange?<IsAuth action={()=>toggleUnits(true)} toggleIsAuth={toggleAuthChange} />:null}
    </Body.Container>
}

export default ManageCompanies