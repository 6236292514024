import styled, { keyframes } from "styled-components";
import * as defines from '../../../utils/defines/Defines'

export const Container = styled.div`
    background: ${defines.BackgroundColor};
    width: 100vw;
    height: 100vh;
    padding: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
`

export const Title = styled.h1`
    font-family: "Comfortaa";
    font-weight: 400;
    color: #000;
    font-size: 80px;
`

export const SubTitle = styled.h3`
    font-family: "Comfortaa";
    font-weight: normal;
    color: #000;
    font-size: 25px;
`

export const Row = styled.div`
    display: flex;
    grid-gap: 0px 10px;
    align-items: center;
    flex-wrap: wrap;
`
export const Circle = styled.div`
    width: 10px;
    height: 10px;
    background: #000;
    border-radius: 10px;
`

export const TitleCompany = styled.h2<{color: string}>`
    font-family: "Signa";
    font-weight: black;
    font-style: italic;
    font-size: 25px;
    color: ${props => props.color};
`

const TextAnimate = keyframes`
    from{
        margin-top: -10px;
        opacity: 0;
    }
    to{
        margin-top: 0px;
        opacity: 1;
    }
`

export const TextError = styled.a`
    font-family: "Comfortaa";
    font-size: 10px;
    color: ${defines.Red};
    animation: .35s ${TextAnimate} ease-in;
`

export const Text = styled.a`
    font-family: "Comfortaa";
    font-size: 12px;
    color: #000;
`

const CardAnimate = keyframes`
    from{
        right: -10vw;
        opacity: 0;
    }
    to{
        right: 80px;
        opacity: 1;
    }
`

export const Card = styled.div`
    position: absolute;
    width: 450px;
    border-radius: 20px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    grid-gap: 15px 0px;
    animation: .5s ${CardAnimate} ease-in;
    transition: .5s;
    -webkit-box-shadow: 10px 10px 30px 1px rgba(211,211,212,0.4),
        -10px -10px 30px 1px rgba(255,255,255,0.8); 
    box-shadow: -10px -10px 30px 1px rgba(255,255,255,0.8),
        10px 10px 30px 1px rgba(211,211,212,0.4);
    right: 80px;
`