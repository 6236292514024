import React, { useEffect, useState } from "react";
import * as Body from './Styles'
import * as defines from '../../../utils/defines/Defines'
import * as Functions from '../../../utils/functions/Functions'
import Input from "../../components/Inputs/Input/Input";
import * as Types from '../../../utils/types/Types'
import Button from "../../components/Buttons/Button/Button";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loading from "../../components/Loading/Loading";
import * as Modals from './Modals/Modals'
import ButtonWithoutBG from "../../components/Buttons/ButtonWithoutBG/Button";
import IsAuth from "../../components/Modals/IsAuth/IsAuth";

const ChangeUserData = () => {
    const location = useLocation()
    const params = location.state as {user: string}

    const [nomeCompleto, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [ddd, setDDD] = useState('')
    const [celular, setTelefone] = useState('')
    const [loadedUserData, setUserData] = useState({email: '', celular:'', ddd: '', nomeCompleto: ''})
    const [unidades, setUnidades] = useState<Types.Unidade[]>([])

    const [isTectrol, toggleTectrol] = useState(false)
    const [loading, toggleLoading] = useState(true)
    const [loadingSave, toggleLoadingSave] = useState(false)
    const [isAuthDelete, toggleAuthDelete] = useState(false)
    const [isAuthChange, toggleAuthChange] = useState(false)

    const [modalDelete, toggleDelete] = useState(false)
    const [modalUnits, toggleUnits] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(()=>{
        setNome('')
        setDDD('')
        setEmail('')
        setTelefone('')
        setUserData({email: '', celular:'', ddd: '', nomeCompleto: ''})
        toggleLoading(true)
        axios.get(defines.apiURL+"/api/UsuarioCRUD?id="+params.user,  {withCredentials: true})
            .then((response)=>{
                let responseData = response.data.content
                setNome(responseData.nomeCompleto)
                setDDD(responseData.ddd)
                setEmail(responseData.email)
                setTelefone(responseData.celular)
                setUserData(responseData)
                if(responseData.nivelAcesso !== defines.NivelAcesso.TECTROL){
                    setUnidades([])
                    axios.get(defines.apiURL+"/api/ParticipationList?userUnavailableBranches="+params.user,  {withCredentials: true})
                        .then((response)=>{
                            setUnidades(response.data.content)
                            toggleLoading(false)
                        })
                        .catch((error)=>{
                            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                            toggleLoading(false)
                        })
                    toggleTectrol(false)
                }else{
                    toggleTectrol(true)
                    toggleLoading(false)
                }
            })
            .catch((error) => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
                return
            })
    }, [dispatch, navigate, params.user])

    const deleteUser = () => {
        axios.delete(defines.apiURL+"/api/UsuarioCRUD?id="+params.user,  {withCredentials: true})
        .then((response)=>{
            dispatch({type: 'ADD_NOTIFICATION', status: 1, text: 'Usuário excluido com sucesso', title: ''})
            toggleDelete(false)
            navigate(-1)
        })
        .catch((error)=>{
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
        })
    }

    const updateUser = () => {
        const novoEmail = email === loadedUserData.email?'':email
        const novoCelular = celular === loadedUserData.celular?'':celular
        const novoDDD = ddd === loadedUserData.ddd?'':ddd
        const novoNome = nomeCompleto === loadedUserData.nomeCompleto?'':nomeCompleto

        toggleLoadingSave(true)
        axios.put(defines.apiURL+"/api/UsuarioCRUD", 
            {email: novoEmail, nomeCompleto: novoNome, 
                ddd: novoDDD, celular: novoCelular, 
                idUsuario: Functions.UnreplaceSpecialCharacters(params.user)},  {withCredentials: true})
            .then((response)=>{
                dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '',
                    text: 'Dados do usuario alterados com sucesso'})
                    toggleLoadingSave(false)
            })
            .catch((error)=>{
                toggleLoadingSave(false)
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            })
    }



    return <Body.Container>
                <Body.Content>
                    <Body.Row style={{justifyContent: 'space-between', width: '100%',
                        marginBottom: '10px', alignItems: 'center'}}>
                        <Body.Title>ALTERAR DADOS</Body.Title>
                        <ButtonWithoutBG text="Voltar" action={()=>navigate(-1)} size={16} />
                    </Body.Row>
                        <Input text={nomeCompleto} setText={setNome} title="Nome Completo"/>
                        <Input text={email} setText={setEmail} title="Email" />
                        <Body.Row style={{gridGap: '0px 10px'}}>
                            <div style={{width: 'calc(25% - 5px)'}}>
                                <Input text= {ddd} setText={setDDD} limit={2} title="DDD" />
                            </div>
                            <div style={{width: 'calc(75% - 5px)'}}>
                                <Input text={celular} setText={setTelefone} title="Telefone"
                                    limit={9}/>
                            </div>
                        </Body.Row>
                        {!isTectrol?
                        <>
                        <Body.Row>
                            <Body.ResultContent>
                                <Body.SubTitle>Unidades </Body.SubTitle>
                                <Body.Row style={{gridGap: '0px 5px'}}>
                                    {unidades.map((item, index)=>{
                                        return <Body.Text key={index.toString()}>
                                            {item.nomeFilial}{index!==(unidades.length! - 1)?", ":''}
                                        </Body.Text>
                                    })}
                                </Body.Row>
                            </Body.ResultContent>
                        </Body.Row></>: null}
                        <Button text="Editar unidades" action={()=>toggleAuthChange(true)} width={320}/>
                        <Body.Row>
                            <Button text="Salvar" color="blue" action={()=>updateUser()}
                                loading={loadingSave} width={150}/>
                            <Button text="Apagar usuário" color="red" action={()=>toggleAuthDelete(true)}
                                width={150}/>
                        </Body.Row>
                </Body.Content>
                {modalDelete?<Modals.ModalDeleteUser toggle={toggleDelete} nome={nomeCompleto} 
                    idUsuario={params.user!} action={deleteUser} />:null}
                {modalUnits?<Modals.ChangeUnit idUsuario={params.user!} nome={nomeCompleto} 
                    toggle={toggleUnits} email={email} />:null}
                {loading?<Loading />:null}
                {isAuthDelete?<IsAuth action={()=>toggleDelete(true)} toggleIsAuth={toggleAuthDelete} />:null}
                {isAuthChange?<IsAuth action={()=>toggleUnits(true)} toggleIsAuth={toggleAuthChange} />:null}
            </Body.Container>
}

export default ChangeUserData