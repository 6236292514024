import styled from "styled-components";
import * as defines from '../../../../utils/defines/Defines'

export const DigitsContent = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`
export const CodeInput = styled.input`
    width: 15%;
    height: 40px;
    border: none;
    background: #FFF;
    border-radius: 6px;
    border: solid 1px rgba(200,200,200,0.5);
    outline: none;
    color: #000;
    margin-top: 10px;
    padding-left: 6%;
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box;    
    box-sizing: border-box;
    outline: none;
    transition: .5s;

    :focus{
        border: solid 1px ${defines.BlueTec};
        width: 16%;
        transition: .5s;
    }
`

export const Content = styled.div<{width?: number}>`
    width: ${props => props.width?props.width+"px":"100%"};
    display: flex;
    flex-direction: column;
    position: relative;
`
export const Text = styled.a`
    font-family: 'Nunito';
    font-size: 14px;    
    color: #6D7989;
`