import React, { useEffect, useState } from "react";
import * as Body from './Styles'
import * as defines from '../../../utils/defines/Defines'
import * as Types from '../../../utils/types/Types'
import * as Functions from '../../../utils/functions/Functions'
import ButtonWithoutBG from "../Buttons/ButtonWithoutBG/Button";
import MyAccountImg from '../../../assets/images/my-account-image.png'
import ConnectedAppsImg from '../../../assets/images/connected-apps-image.png'
import ManagerUserImg from '../../../assets/images/manager-users-image.png'
import ManagerPermissionImg from '../../../assets/images/manager-permission-image.png'
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";

type SidebarProps = {
    content: number
}

const Sidebar: React.FC <SidebarProps> = ({
    content
}) => {
    const [data, setData] = useState<Types.UserInfo>()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [open, toggleSidebar] = useState(false)

    useEffect(()=>{
        axios.get(defines.apiURL+"/api/ParticipationList", {withCredentials: true})
            .then((response)=> {
                setData(response.data.content)
                dispatch({type: 'SET_ACESS_LEVEL', acessLevel: response.data.content.nivelAcesso})
            })
            .catch((error)=>{ 
                if(error.response.data.code === "ACS-11" || error.response.data.code === "DSS-2"){
                    return
                }
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            })
    }, [dispatch, navigate])

    const getImage = () => {
        switch(content){
            case defines.ContentSidebar.MY_ACCOUNT:
                return MyAccountImg
            case defines.ContentSidebar.CONNECTED_APPS:
                return ConnectedAppsImg
            case defines.ContentSidebar.MANAGER_USERS:
                return ManagerUserImg
            case defines.ContentSidebar.MANAGE_PERMISSIONS:
                return ManagerPermissionImg
            case defines.ContentSidebar.MANAGE_COMPANY:
                return ManagerPermissionImg
        }
    }

    // const getUserImage = () => {
    //     if(data?.fotoUsuario){
    //         return data.fotoUsuario
    //     }
    //     return Avatar
    // }

    const getNome = () => {
        if(data?.nomeCompleto){
            return data.nomeCompleto.split(' ')[0]
        }
        return ''
    }

    // if(loading){
    //     return <Body.Card>
    //                 <Spinner size={80} blue={true} />
    //             </Body.Card>
    // }

    const logout = () => {
        axios.get(defines.apiURL+"/api/Logout",  {withCredentials: true})
            .then((response)=>{
                navigate('/')
            })
            .catch((error: any)=>{
                if(error.response.data.code === 'ACS-9'){
                    navigate('/')
                    return
                }
                dispatch({type: 'ADD_NOTIFICATION', title: "Erro", status: 3, text: error.response.data.message})
            })
            .finally(() => {
                localStorage.removeItem("@logged")
            })
    }

    return <>
            <Body.StyledBurger open={open} onClick={()=>toggleSidebar(!open)} >
                <div />
                <div />
                <div />
            </Body.StyledBurger>

            {open?<Body.Card >
                <Body.Content>
                    {/* <Body.Photo src={getUserImage()} draggable={false}/> */}
                    <Body.Title>Olá, {getNome()}</Body.Title>
                </Body.Content>

                <Body.Content>
                    <ButtonWithoutBG text="Minha Conta" action={()=>navigate('/minha-conta')} size={20}
                        active={content === defines.ContentSidebar.MY_ACCOUNT}/>
                    <Body.BottomBar />
                    <ButtonWithoutBG text="Apps Conectados" action={()=>navigate('/apps-conectados')} size={20}
                        active={content === defines.ContentSidebar.CONNECTED_APPS ||
                            content === defines.ContentSidebar.MANAGE_PERMISSIONS}/>
                    <Body.BottomBar />
                    {data?.nivelAcesso === defines.NivelAcesso.ADMINISTRADOR 
                    || data?.nivelAcesso === defines.NivelAcesso.TECTROL ?<>
                    <ButtonWithoutBG text="Gerenciar Usuários"
                            action={()=>navigate("/gerenciar-usuarios")} size={20}
                            active={content === defines.ContentSidebar.MANAGER_USERS}/>
                    <Body.BottomBar /></>:null}
                    {data?.nivelAcesso === defines.NivelAcesso.TECTROL ?<>
                    <ButtonWithoutBG text="Gerenciar Empresas"
                            action={()=>navigate("/gerenciar-empresas")} size={20}
                            active={content === defines.ContentSidebar.MANAGE_COMPANY}/>
                    <Body.BottomBar /></>:null}

                    <ButtonWithoutBG text="Sair" action={()=>logout()} size={20}/>
                </Body.Content>

                <Body.Image src={getImage()} />
            </Body.Card>:null}
        </>
}

export default Sidebar