import styled, { keyframes } from "styled-components";
import * as defines from '../../../utils/defines/Defines'

export const Container = styled.div`
    background: ${defines.BackgroundColor};
    width: 100vw;
    height: 100vh;
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    align-items: flex-end;
`

export const Content = styled.div`
    width: calc(100vw - 480px);
    height: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 10px 10px;
    justify-content: center;
    align-items: flex-start;

`

export const Title = styled.h1`
    font-family: 'Nunito';
    font-weight: bold;
    font-size: 16px;
    color: #000;
    margin-bottom: 10px;
`

export const Row = styled.div`
    display: flex;
    grid-gap: 0px 20px;
`
export const SubTitle = styled.a`
    font-family: 'Nunito';
    font-size: 14px;    
    color: #6D7989;
    margin-bottom: 5px;
`
export const Text = styled.a`
    font-family: 'Nunito';
    font-weight: bold;
    font-size: 14px;    
    color: #000;
    margin-bottom: 5px;
`
export const Card = styled.div`
    width: 220px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 20px;
    box-sizing: border-box;
    grid-gap: 10px 0px;
    text-align: center;
`

export const Icon = styled.div`
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-color: gray;
`   