import React, { useEffect, useState } from "react";
import Sidebar from "../../components/SideBar/Sidebar";
import * as Body from './Styles'
import * as defines from '../../../utils/defines/Defines'
import * as Functions from '../../../utils/functions/Functions'
import DHMIcon from '../../../assets/icons/dhm-icon.svg'
import ConectaIcon from '../../../assets/icons/conecta-icon.svg'
import Button from "../../components/Buttons/Button/Button";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const ConnectedApps = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const acessLevel = useSelector((state: any) => state.acessLevel.acessLevel)

    const [loading, toggleLoading] = useState(true)
    const [apps, setApps] = useState<string[]>([])

    useEffect(()=>{
        toggleLoading(true)
        axios.get(defines.apiURL+"/api/UsuarioCRUD?registeredApps=1",{withCredentials: true})
            .then(response => {
                setApps(response.data.content)
                toggleLoading(false)
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    }, [dispatch, navigate])


    const openApp = (link: string) => {
        window.open(link, "_blank");
    };	

    return <Body.Container>
        <Sidebar content={defines.ContentSidebar.CONNECTED_APPS} />

        <Body.Content>
            <Body.Text style={{marginBottom: '20px'}}>
                Aqui estão as aplicações que estão vinculadas à sua conta. <br/>
                Caso não veja a aplicação que precise acessar vinculada aqui, entre em contato.
            </Body.Text>

            <Body.Row>
                {apps.includes("DHM")?
                <Body.Card>
                    <Body.Icon src={DHMIcon} />
                    <Body.Title>
                        DHM4.0
                    </Body.Title>
                    <Body.SubTitle>
                        Monitore e personalize o sistema DHM4.0 operando em sua usina
                    </Body.SubTitle>
                    <Button text="Abrir app" action={()=>openApp("https://www.dhm.dinamoautomacao.com.br")}/>
                    {acessLevel >= defines.NivelAcesso.ADMINISTRADOR?<Button text="Gerenciar permissões" action={()=>navigate('/gerenciar-dhm')}
                        color="yellow" />:null}
                </Body.Card>:null}
                {/* {apps.includes("INS")?
                <Body.Card>
                    <Body.Icon src={ConectaIcon} />
                    <Body.Title>
                        Industry Sight
                    </Body.Title>
                    <Body.SubTitle>
                    </Body.SubTitle>
                    <Button text="Abrir app" action={()=>openApp("#")}/>
                    <Button text="Gerenciar permissões" action={()=>navigate('/gerenciar-insight')} color="yellow" />
                </Body.Card>:null} */}
            </Body.Row>
            
        </Body.Content>

      {loading?<Loading />:null}
    </Body.Container>
}

export default ConnectedApps