import styled, {keyframes} from "styled-components";
import * as defines from '../../../utils/defines/Defines'

export const AnimateOpen = keyframes`
    from{
        right: -200px;
    }
    to{
        right: 0px;
    }
`

export const Card = styled.div`
    width: 100vw;
    height: 100vh;
    border-radius: 20px;
    border: none;
    left: 0px;
    top: 0px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    animation-name: ${AnimateOpen};
    animation-duration: .10s;
    animation-timing-function: ease-in;

    background-color: ${defines.BackgroundColor};
    -webkit-box-shadow: 10px 10px 30px 1px rgba(211,211,212,0.4),
        -10px -10px 30px 1px rgba(255,255,255,0.8); 
    box-shadow: -10px -10px 30px 1px rgba(255,255,255,0.8),
        10px 10px 30px 1px rgba(211,211,212,0.4);
    position: absolute;
    z-index: 10;
`

export const Photo = styled.img`
    width: 120px;
    height: 120px;
    border-radius: 60px;
`
export const Title = styled.a`
    font-family: "Comfortaa";
    font-weight: medium;
    font-size: 20px;
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 10px 0px;
`

export const Image = styled.img`
    width: 200px;
    height: 140px;
`

export const BottomBar = styled.div`
    width: 100%;
    height: 1px;
    background-color: rgba(10,10,10,0.4);
`

export const StyledBurger = styled.button<{open: boolean}>`
  position: absolute;
  top: 30px;
  right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 11;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: #000;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`