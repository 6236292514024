import React, { useEffect, useState } from "react";
import * as Body from './Styles'
import * as defines from '../../../utils/defines/Defines'
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import Input from "../../components/Inputs/Input/Input";
import Button from "../../components/Buttons/Button/Button";
import * as Functions from '../../../utils/functions/Functions'
import ButtonWithoutBG from "../../components/Buttons/ButtonWithoutBG/Button";
import CodeInput from "../../components/Inputs/InputCode/Input";


const Auth = () => {

    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [errorEmail, toggleErrorEmail] = useState(false)
    const [errorPassword, toggleErrorPassword] = useState(false)

    const [tfaCode, setCode] = useState('')
    const [time, setTime] = useState(120)
    const [lock, toggleLock] = useState(false)
    const [loadingCode, toggleCode] = useState(false)
    const [loading, toggleLoading] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if(time === 0){
            toggleLock(false)
            return
        }
        const interval = setInterval(() => {
            setTime(seconds => seconds - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, [time]);

    const tryResend = () => {
        if(lock){
            return
        }
        toggleCode(true)
        axios.get(defines.apiURL+"/api/LoginPermissionTFA", {withCredentials: true})
            .then((response)=> {
                toggleLock(true)
                toggleCode(false)
                setTime(120)
                dispatch({type: 'ADD_NOTIFICATION', status: 1, title: "Código enviado"})
            })
            .catch((error)=>{
                toggleCode(false)
                switch(error.response.status){
                    case 406:
                        if(error.response.data.code === "TFA-3"){
                            setTime(120)
                            toggleLock(true)
                        }
                }
                dispatch({type: 'ADD_NOTIFICATION', status: 3, title: 'Erro', text: error.response.data.message})
            })
    }

    useEffect(()=>{
        toggleCode(true)
        axios.get(defines.apiURL+"/api/LoginPermissionTFA", {withCredentials: true})
            .then((response)=> {
                toggleLock(true)
                toggleCode(false)
                setTime(120)
                dispatch({type: 'ADD_NOTIFICATION', status: 1, title: "Código enviado"})
            })
            .catch((error)=>{
                toggleCode(false)
                switch(error.response.status){
                    case 406:
                        if(error.response.data.code === "TFA-3"){
                            setTime(120)
                            toggleLock(true)
                        }
                }
                dispatch({type: 'ADD_NOTIFICATION', status: 3, title: 'Erro', text: error.response.data.message})
            })
    }, [dispatch])

    const tryAuth = () => {
        toggleErrorEmail(false)
        toggleErrorPassword(false)
        if(!Functions.verifyEmail(email)){
            toggleErrorEmail(true)
            return
        }
        if(senha.length < 12){
            toggleErrorPassword(true)
            return
        }

        axios.post(defines.apiURL+"/api/LoginPermissionTFA", {email, senha, tfaCode, api: 'sso'},{withCredentials: true})
        .then((response)=>{
            toggleLoading(false)
            navigate('/minha-conta')
        })
        .catch((error)=>{
            toggleLoading(false)
            switch(error.response.status){
                case 400:
                    if(error.response.data.code === 'LGN-1'){
                        dispatch({type: 'ADD_NOTIFICATION', status: 3, title: 'Erro', text: error.response.data.message})
                        return
                    }
                    if(error.response.data.code === 'LGN-3'){
                        dispatch({type: 'ADD_NOTIFICATION', status: 3, title: 'Erro', text: error.response.data.message})
                        return
                    }
                    if(error.response.data.code === 'ACS-11'){
                        dispatch({type: 'ADD_NOTIFICATION', status: 2, title: '', text: error.response.data.message})
                        navigate('/privacidade')
                        return
                    }
                    dispatch({type: 'ADD_NOTIFICATION', status: 3, title: 'Erro', text: error.response.data.message})
                    break;
                case 401:
                    dispatch({type: 'ADD_NOTIFICATION', status: 3, title: 'Erro', text: error.response.data.message})
                    localStorage.removeItem("@logged")
                    break; 
                case 403:
                    if(error.response.data.code === 'ACS-4'){
                        dispatch({type: 'ADD_NOTIFICATION', status: 2, title: '', text: error.response.data.message})
                        navigate("/ativar-conta")
                        return
                    }
                    dispatch({type: 'ADD_NOTIFICATION', status: 3, title: 'Erro', text: error.response.data.message})
                    break; 
                case 404:
                     if(error.response.data.code === 'LGN-2'){
                        dispatch({type: 'ADD_NOTIFICATION', status: 3, title: 'Erro', text: error.response.data.message})
                        return
                    }
                    break;
                case 500:
                    dispatch({type: 'ADD_NOTIFICATION', status: 3, title: "Internal server error"})
                    break  
                default:
                    dispatch({type: 'ADD_NOTIFICATION', status: 3, title: "", text: error.response.data.message})
                    break            
            }
        })
    }

    return <Body.Container>
        <Body.Row>
            <Body.TitleCompany color={defines.BlueTec}>
                tectrol
            </Body.TitleCompany>
            <Body.Circle />
            <Body.TitleCompany color={defines.GreenTec}>
                dínamo
            </Body.TitleCompany>
        </Body.Row>
        <Body.Title>Conecta</Body.Title>
        <Body.Card>
            <Body.SubTitle>Autenticação</Body.SubTitle>
            <Body.Text>A sua permissão para alterar dados sensiveis expirou, realize a autenticação e tente novamente!</Body.Text>
            <Input text={email} setText={setEmail} title="Email" error={errorEmail} />
            <Input text={senha} setText={setSenha} title="Senha" error={errorPassword}
                password={true} />
            <CodeInput setText={setCode} title="Código" action={()=>tryAuth()}/>
            <Button text="Continuar" action={()=>tryAuth()} 
                loading={loading}/>
            {lock?<Body.Text>Aguarde {Functions.getTime(time)} para enviar novamente</Body.Text>
            :<ButtonWithoutBG text="Reenviar código" loading={loadingCode} action={tryResend}/>}
        </Body.Card>
    </Body.Container>
}

export default Auth