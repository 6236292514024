
import {combineReducers} from 'redux'
import notifications from "./notifications/reducer"; 

import { configureStore } from "@reduxjs/toolkit";
import acessLevel from './acessLevel/reducer';
import alert from './alert/reducer';

const rootReducer = combineReducers({
  notifications: notifications,
  acessLevel: acessLevel,
  alert: alert
})


const store = configureStore({
  reducer: rootReducer
})

export default store

export type State = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch