import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Modal from "../../../components/Modals/Modal/Modal";
import * as Body from '../Styles'
import * as ModalBody from './Styles'
import * as defines from '../../../../utils/defines/Defines'
import * as Types from '../../../../utils/types/Types'
import * as Functions from '../../../../utils/functions/Functions'

import SearchIcon from '../../../../assets/icons/Search.svg'
import TrashIcon from '../../../../assets/icons/trash-icon.svg'
import ChangeIcon from '../../../../assets/icons/change-icon.svg'
import Button from "../../../components/Buttons/Button/Button";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Input from "../../../components/Inputs/Input/Input";


/** MUDAR PERMISSÃO */

type ChangePermissionProps = {
    user: {
        idInSight: string,
        nomeCompleto: string,
    },
    action: any,
    toggle: Dispatch<SetStateAction<boolean>>
}

export const ChangePermission: React.FC <ChangePermissionProps> = ({
    user, toggle, action
}) => {

    const [selected, setSelected] = useState(defines.InSightAcesso.TECNICO)
    const [loading, toggleLoading] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const getCargo = () => {
        switch(selected){
            case defines.InSightAcesso.ADMINISTRADOR:
                return "Administrador"
            case defines.InSightAcesso.SUPERVISOR:
                return "Supervisor"
            case defines.InSightAcesso.TECNICO:
                return "Técnico"
        }
    }

    const tryChange = () => {
        toggleLoading(true)
        axios.put(defines.apiURL+"/api/InSightCRUD", { idInSight: user.idInSight, nivelAcesso: selected},  {withCredentials: true})
        .then(response => {
            dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '', text: user.nomeCompleto+' agora é um '+getCargo()})
            toggleLoading(false)
            toggle(false)
            action()
        })
        .catch(error => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
        })
    }
    return <Modal title="Mudar permissão">
        <>
            <ModalBody.UserTitle>Alterar permissão de <strong>{user.nomeCompleto}</strong> para <strong>InSight</strong></ModalBody.UserTitle>
            <Body.Row style={{marginTop: '15px'}}>
                <ModalBody.SelectCard active={selected === defines.InSightAcesso.ADMINISTRADOR} 
                    onClick={()=>setSelected(defines.InSightAcesso.ADMINISTRADOR)}>
                        <Body.Icon style={{width: '50px', height: '50px',
                            backgroundColor: selected === defines.InSightAcesso.ADMINISTRADOR?
                            defines.BlueTec:"rgba(0,0,0,0.05)"}}/>
                        <Body.Text>Administrador</Body.Text>
                </ModalBody.SelectCard>
                <ModalBody.SelectCard active={selected === defines.InSightAcesso.SUPERVISOR}  
                    onClick={()=>setSelected(defines.InSightAcesso.SUPERVISOR)}>
                        <Body.Icon style={{width: '50px', height: '50px',
                            backgroundColor: selected === defines.InSightAcesso.SUPERVISOR?
                            defines.BlueTec:"rgba(0,0,0,0.05)"}}/>
                        <Body.Text>Supervisor</Body.Text>
                    </ModalBody.SelectCard>
                <ModalBody.SelectCard active={selected === defines.InSightAcesso.TECNICO} 
                    onClick={()=>setSelected(defines.InSightAcesso.TECNICO)}>
                        <Body.Icon style={{width: '50px', height: '50px',
                            backgroundColor: selected === defines.InSightAcesso.TECNICO?
                            defines.BlueTec:"rgba(0,0,0,0.05)"}}/>
                        <Body.Text>Técnico</Body.Text>
                    </ModalBody.SelectCard>
            </Body.Row>
            <Body.Row style={{gridGap: '0px 10px', justifyContent: 'center', marginTop: '20px'}}>
                <Button text="Alterar" color="green" action={()=>tryChange()}
                    width={120} loading={loading} />
                <Button text="Cancelar" color="red" action={()=>toggle(false)}
                    width={120} disabled={loading} />
            </Body.Row>
        </>
    </Modal>
}


/** ADICIONAR USUARIO */

type AddUserProps = {
    toggle: Dispatch<SetStateAction<boolean>>,
    nome: string,
    cargo: string,
    action: any,
    idFilial: string,
    idInSight: string,
    newUser?: boolean
}

const AddUserToFunction: React.FC <AddUserProps> = ({
    nome, cargo, action, toggle, idFilial, idInSight, newUser
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, toggleLoading] = useState(false) 

     const getLevel = () => {
        switch(cargo){
            case 'Administrador':
                return defines.InSightAcesso.ADMINISTRADOR
            case 'Supervisor':
                return defines.InSightAcesso.SUPERVISOR
            case 'Técnico':
                return defines.InSightAcesso.TECNICO
        }
    }


    const tryCreate = () => {
        toggleLoading(true)
        axios.post(defines.apiURL+"/api/InSightCRUD", { idUsuario: idInSight, idFilial, nivelAcesso: getLevel()},  {withCredentials: true})
        .then(response => {
            dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '', text: nome+' agora é um '+cargo})
            toggleLoading(false)
            toggle(false)
            action()
        })
        .catch(error => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
        })
    }

    const tryAdd = () => {
        toggleLoading(true)
        axios.put(defines.apiURL+"/api/InSightCRUD", { idInSight, nivelAcesso: getLevel()},  {withCredentials: true})
        .then(response => {
            dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '', text: nome+' agora é um '+cargo})
            toggleLoading(false)
            toggle(false)
            action()
        })
        .catch(error => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
        })
    }

    return <Modal title="" >
                <div style={{display: 'flex', flexDirection: 'column',
                        alignItems: 'center'}}>
                    <Body.Text style={{marginBottom: '20px', fontWeight: 400}}>
                        Conceder privilégios de <strong>{cargo}</strong> para <strong>{nome}</strong> no app
                        <strong> InSight</strong></Body.Text>
                    <Body.Row>
                        {newUser?<Button text="Salvar" width={200} action={()=>tryCreate()} loading={loading} />
                            :<Button text="Salvar" width={200} action={()=>tryAdd()} loading={loading} />}
                        <Button text="Cancelar" width={200} action={()=>toggle(false)} disabled={loading} />
                    </Body.Row>
                </div>
            </Modal>
}


/** DELETE USER */

type DeleteUserProps = {
    toggle: Dispatch<SetStateAction<boolean>>,
    nome: string,
    cargo: string,
    action: any,
    idInSight: string
}


const DeleteUser: React.FC <DeleteUserProps> = ({
    nome, toggle, cargo, action, idInSight
}) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, toggleLoading] = useState(false) 


    const tryDelete = () => {
        toggleLoading(true)
        axios.delete(defines.apiURL+"/api/InSightCRUD?idInSight="+Functions.ReplaceSpecialCharacters(idInSight),  {withCredentials: true})
        .then(response => {
            dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '', text: nome+' não é mais um '+cargo})
            toggleLoading(false)
            toggle(false)
            action()
        })
        .catch(error => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
        })
    }

    return <Modal title="" >
                <div style={{display: 'flex', flexDirection: 'column',
                        alignItems: 'center'}}>
                    <Body.Text style={{marginBottom: '20px'}}>
                        Remover privilégios de <strong>{cargo}</strong> de <strong>{nome}</strong> no app
                        <strong> InSight</strong></Body.Text>
                    <Body.Row>
                        <Button text="Remover" color="red" width={200}
                            loading={loading} action={()=>tryDelete()} />
                        <Button text="Cancelar" width={200} action={()=>toggle(false)} disabled={loading} />
                    </Body.Row>
                </div>
            </Modal>
}

/** SELECIONAR USUÁRIOS **/

type SelectUser = {
    toggle: Dispatch<SetStateAction<boolean>>,
    idFilial: string,
    cargo: string,
    att: any
}

export const AddUser: React.FC <SelectUser> = ({
    toggle, cargo, idFilial, att
}) => {
    const [selectedUser, setSelectedUser] = useState({id: '', nome: ''})
    const [changePermissionModal, toggleChangePermissionModal] = useState(false)
    const [createModal, toggleCreateModal] = useState(false)
    const [loading, toggleLoading] = useState(false)
    const [filteredUsers, setFilteredUsers] = useState<Types.UserToDHM[]>([])
    const [dataUsers, setDataUsers] = useState<Types.UserToDHM[]>([])
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(()=>{
        toggleLoading(true)
        axios.get(defines.apiURL+"/api/InSightCRUD?availableInSightNewUsers=1&idFilial="+Functions.ReplaceSpecialCharacters(idFilial),
            {withCredentials: true})
            .then((response)=>{
                const sorted = Functions.SortResponse(response.data.content, "nomeCompleto")
                setDataUsers(sorted)
                setFilteredUsers(sorted)
                toggleLoading(false)
            })
            .catch((error)=>{
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    }, [dispatch, idFilial, navigate])


    const selectNewUser = (id: string, nome: string) => {
        setSelectedUser({id, nome})
        toggleCreateModal(true)
    }

    const filterData = (name: string) => {
        if(name.length === 0){
            setFilteredUsers(dataUsers)
            return
        }
        const usersFiltered = dataUsers.filter(item => item.nomeCompleto.toUpperCase().includes(name.toUpperCase()))
        setFilteredUsers(usersFiltered!)
    }

    const action = () => {
        att()
        toggle(false)
    }

    return <Modal title="Selecionar usuário" toggleModal={toggle} loading={loading} >
        <>
        {dataUsers.length !== 0?<ModalBody.FindContent>
                <ModalBody.Find onChange={e => filterData(e.target.value)}/> 
                <ModalBody.Icon src={SearchIcon} />       
        </ModalBody.FindContent>:null}
        {(filteredUsers.length === 0)?<ModalBody.Text>Não foram encontrados usuários</ModalBody.Text>:<>
            <ModalBody.Scroll style={{width: '500px'}}>
            {filteredUsers.map((item, index) =>{
                return <ModalBody.User style={{marginBottom: '5px'}} key={index.toString()} onClick={()=>selectNewUser(item.idUsuario, item.nomeCompleto)}>
                    <Body.Row style={{alignItems: 'center', gridGap: '0px 10px'}}>
                        <ModalBody.UserTitle>{item.nomeCompleto}</ModalBody.UserTitle>
                    </Body.Row>
                </ModalBody.User>}
            )}
        </ModalBody.Scroll></>}
        {changePermissionModal?<AddUserToFunction nome={selectedUser.nome} idInSight={selectedUser.id} idFilial={idFilial}
            cargo={cargo} toggle={toggleChangePermissionModal} action={action} />:null}
        {createModal?<AddUserToFunction nome={selectedUser.nome} idInSight={selectedUser.id} idFilial={idFilial}
            cargo={cargo} toggle={toggle} action={action} newUser={true} />:null}
        </>
    </Modal>
}

/** GERENCIAR PERMISSÕES **/

type ManagePermissionProps = {
    cargo: string,
    idFilial: string,
    userAcess: number,
    toggle: Dispatch<SetStateAction<boolean>>
}
export const ManagePermissions: React.FC <ManagePermissionProps> = ({
    cargo, toggle, idFilial, userAcess
}) => {
    const [selectUserModal, toggleSelectUserModal] = useState(false)
    const [data, setData] = useState<Types.InSightInfo[]>([])
    const [filteredUsers, setFiltered] = useState<Types.InSightInfo[]>([])
    const [selectedUser, setSelectedUser] = useState({idInSight:'', nomeCompleto: ''})
    const [changePermissionModal, toggleChangePermissionModal] = useState(false)
    const [modalDelete, toggleDelete] = useState(false)
    const [loading, toggleLoading] = useState(true)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const select = (idInSight: string, nomeCompleto: string) => {
        setSelectedUser({idInSight, nomeCompleto})
        toggleChangePermissionModal(true)
    }

    const getBody = () => {
        switch(cargo){
            case 'Administrador':
                return 'InSightAdministratorsList=1'
            case 'Supervisor':
                return 'InSightSupervisorsList=1'
            case 'Técnico':
                return 'InSightTechniciansList=1'
        }
    }

    const getData = () => {
        toggleLoading(true)
        axios.get(defines.apiURL+"/api/InSightCRUD?"+getBody()+"&idFilial="+Functions.ReplaceSpecialCharacters(idFilial),
            {withCredentials: true})
            .then((response)=>{
                const sorted = Functions.SortResponse(response.data.content, "nomeCompleto")
                setData(sorted)
                setFiltered(sorted)
                toggleLoading(false)
            })
            .catch((error)=>{
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    }

    useEffect(()=>{
        const getBodyUE = () => {
            switch(cargo){
                case 'Administrador':
                    return 'InSightAdministratorsList=1'
                case 'Supervisor':
                    return 'InSightSupervisorsList=1'
                case 'Técnico':
                    return 'InSightTechniciansList=1'
            }
        }

        toggleLoading(true)
        axios.get(defines.apiURL+"/api/InSightCRUD?&idFilial="+
            Functions.ReplaceSpecialCharacters(idFilial)+"&"+getBodyUE(),
            {withCredentials: true})
            .then((response)=>{
                const sorted = Functions.SortResponse(response.data.content, "nomeCompleto")
                setData(sorted)
                setFiltered(sorted)
                toggleLoading(false)
            })
            .catch((error)=>{
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    }, [dispatch, idFilial, navigate, cargo])

    const filterData = (name: string) => {
        if(name.length === 0){
            setFiltered(data)
            return
        }
        const filtered = data.filter(item => item.nomeCompleto.toUpperCase().includes(name.toUpperCase()))
        setFiltered(filtered!)
    }

    const selectDelete = (idInSight: string, nomeCompleto: string) => {
        setSelectedUser({idInSight, nomeCompleto})
        toggleDelete(true)
    }

    return <Modal change_permission={{app: 'Insight', function: cargo}} 
    loading={loading}
     add={()=>toggleSelectUserModal(true)} toggleModal={toggle}>
        <>
            {data.length !== 0?<ModalBody.FindContent>
                <ModalBody.Find onChange={e => filterData(e.target.value)}/> 
                <ModalBody.Icon src={SearchIcon} />       
            </ModalBody.FindContent>:null}
            {filteredUsers.length === 0?
            <><ModalBody.Text>Não foram encontrados usuários</ModalBody.Text></>:
            <ModalBody.Scroll>
                {filteredUsers.map((item, index)=> {
                    return <Body.Row style={{width: '100%', alignItems: 'center', marginBottom: '5px'}} key={index.toString()}>
                    <ModalBody.SelectCard style={{width: 'calc(100% - 60px)',
                        height: '40px', padding: '5px', alignItems: 'flex-start',
                        paddingLeft: '10px'}} onClick={()=>select(item.idUsuario, item.nomeCompleto)}>
                        <Body.Text>{item.nomeCompleto}</Body.Text>
                    </ModalBody.SelectCard>
                    <ModalBody.ButtonIcon src={TrashIcon} onClick={()=>selectDelete(item.idInSight, item.nomeCompleto)} />
                    {userAcess >=  defines.InSightAcesso.ADMINISTRADOR?
                    <ModalBody.ButtonIcon src={ChangeIcon} onClick={()=>select(item.idInSight, item.nomeCompleto)} />:null}
                </Body.Row>
                })}
            </ModalBody.Scroll>}
            {selectUserModal?<AddUser toggle={toggleSelectUserModal} cargo={cargo} idFilial={idFilial} att={()=>getData()} />:null}
            {changePermissionModal?<ChangePermission toggle={toggleChangePermissionModal}
                user={selectedUser} action={getData} />:null}
            {modalDelete?<DeleteUser toggle={toggleDelete} cargo={cargo}  idInSight={selectedUser.idInSight} 
                action={getData} nome={selectedUser.nomeCompleto} />:null}
        </>
    </Modal>
}

//** Visitante */

type CreateVisitorProps = {
    action: any,
    toggle: Dispatch<SetStateAction<boolean>>
    idFilial: string,
    change?:Types.Visitante
}

const CreateVisitor: React.FC<CreateVisitorProps> = ({action, toggle, idFilial, change}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [nomeCompleto, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [ddd, setDDD] = useState('')
    const [celular, setTelefone] = useState('')
    
    const [hasLenght, toggleLength] = useState(false)
    const [hasUpper, toggleUpper] = useState(false)
    const [hasLower, toggleLower] = useState(false)
    const [hasSpecial, toggleSpecial] = useState(false)
    const [loading, toggleLoading] = useState(false)
    
    const checkPass = (pass: string) => {
        const verify = Functions.checkPasswordRestrictions(pass)
        setSenha(pass)
        
        toggleLength(verify.lenght)
        toggleLower(verify.lowercase)
        toggleUpper(verify.uppercase)
        toggleSpecial(verify.special)
    }
    
    const getColor = (verify: boolean) => {
        if(verify){
            return "green"
        }
        return "red"
    }

    const tryCreate = () => {
        toggleLoading(true)
        axios.post(defines.apiURL+"/api/UsuarioVisitanteCRUD", {nomeCompleto, email, senha, ddd, celular, idFilial},
            {withCredentials: true})
            .then((response)=>{
                dispatch({type: 'ADD_NOTIFICATION', status: 1, text: 'Usuário criado com sucesso'})
                toggleLoading(false)
                action()
                toggle(false)
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    }

    const tryUpdate = () => {
        toggleLoading(true)
        axios.put(defines.apiURL+"/api/UsuarioVisitanteCRUD", {idUsuarioVisitante: change!.idUsuarioVisitante,nomeCompleto, email, senha, ddd, celular, idFilial},
            {withCredentials: true})
            .then((response)=>{
                dispatch({type: 'ADD_NOTIFICATION', status: 1, text: 'Usuário alterado com sucesso'})
                toggleLoading(false)
                action()
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    }

    return <Modal title={change?"Alterar cadastro de visitante":"Cadastrar visitante"} toggleModal={toggle}>
        <>
        <Body.Scroll style={{width: '400px', maxHeight: "400px", height: "auto", 
            gridGap: '10px 0px', marginBottom: '10px'}}>        
            <Input text={nomeCompleto} setText={setNome} title="Nome completo" placeholder={change?change.nomeCompleto:""} />
            <div style={{height: '10px'}} />
            <Input text={email} setText={setEmail} title="Email" placeholder={change?change.email:""} />
            <div style={{height: '10px'}} />
            {!change?<><Input text={senha} setText={checkPass} title="Senha" password={true}/>
            <div style={{height: '10px'}} />
            <Body.Text style={{fontWeight: '300', fontSize: 14}}>
                Sua senha deve conter letras <strong style={{color: getColor(hasUpper)}}>maiúsculas</strong>
                , <strong style={{color: getColor(hasLower)}}>minúsculas</strong>
                , <strong style={{color: getColor(hasSpecial)}}>caracteres especiais (ex: @#$)
                </strong> e no minimo<strong style={{color: getColor(hasLenght)}}> 12 caracteres</strong></Body.Text>
            </>:null}
            <div style={{height: '10px'}} />
            <Body.Row style={{width: '400px', justifyContent: 'space-between'}}>
                <Input text={ddd} setText={setDDD} title="DDD" placeholder={change?change.ddd:""}
                        limit={2} width={70} />
                <Input text={celular} setText={setTelefone} title="Telefone" placeholder={change?change.celular:""}
                        limit={9} width={310} />
            </Body.Row>
        </Body.Scroll>
        <Button text={change?"Alterar":"Criar"} action={change?()=>tryUpdate():()=>tryCreate()} loading={loading}/>
        </>
    </Modal>
}

type ManageVisitorsProps = {
    idFilial: string,
    toggle: Dispatch<SetStateAction<boolean>>
}

type DeleteVisitorProps = {
    toggle: Dispatch<SetStateAction<boolean>>,
    nome: string,
    action: any,
    idUsuarioVisitante: string
}


const DeleteVisitor: React.FC <DeleteVisitorProps> = ({
    nome, toggle, action, idUsuarioVisitante
}) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, toggleLoading] = useState(false) 


    const tryDelete = () => {
        toggleLoading(true)
        axios.delete(defines.apiURL+"/api/UsuarioVisitanteCRUD?idUsuarioVisitante="+Functions.ReplaceSpecialCharacters(idUsuarioVisitante),  {withCredentials: true})
        .then(response => {
            dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '', text: nome+' não é mais um visitante'})
            toggleLoading(false)
            toggle(false)
            action()
        })
        .catch(error => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
        })
    }

    return <Modal title="" >
                <div style={{display: 'flex', flexDirection: 'column',
                        alignItems: 'center'}}>
                    <Body.Text style={{marginBottom: '20px'}}>
                        Excluir visitante <strong>{nome}</strong> no app
                        <strong> InSight</strong></Body.Text>
                    <Body.Row>
                        <Button text="Remover" color="red" width={200}
                            loading={loading} action={()=>tryDelete()} />
                        <Button text="Cancelar" width={200} action={()=>toggle(false)} disabled={loading} />
                    </Body.Row>
                </div>
            </Modal>
}

export const ManageVisitors: React.FC <ManageVisitorsProps> = ({
    toggle, idFilial
}) => {
    const [createtUserModal, toggleCreateModal] = useState(false)
    const [data, setData] = useState<Types.Visitante[]>([])
    const [filteredUsers, setFiltered] = useState<Types.Visitante[]>([])
    const [selectedUser, setSelectedUser] = useState<Types.Visitante>({nomeCompleto: "", celular: "", ddd: "", email: "", idUsuarioVisitante: ""})
    const [changeUser, toggleChange] = useState(false)
    const [modalDelete, toggleDelete] = useState(false)
    const [loading, toggleLoading] = useState(true)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const select = (user: Types.Visitante) => {
        setSelectedUser(user)
        toggleChange(true)
    }


    const getData = () => {
        toggleLoading(true)
        axios.get(defines.apiURL+"/api/UsuarioVisitanteCRUD?idFilial="+Functions.ReplaceSpecialCharacters(idFilial), {withCredentials: true})
        .then((response)=>{
            const sorted = Functions.SortResponse(response.data.content, "nomeCompleto")
            setData(sorted)
            setFiltered(sorted)
            toggleLoading(false)
        })
        .catch((error)=>{
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
        })
    }

    useEffect(()=>{
            toggleLoading(true)
            axios.get(defines.apiURL+"/api/UsuarioVisitanteCRUD?idFilial="+Functions.ReplaceSpecialCharacters(idFilial), {withCredentials: true})
            .then((response)=>{
                const sorted = Functions.SortResponse(response.data.content, "nomeCompleto")
                setData(sorted)
                setFiltered(sorted)
                toggleLoading(false)
            })
            .catch((error)=>{
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })

    }, [dispatch, idFilial, navigate])

    const filterData = (name: string) => {
        if(name.length === 0){
            setFiltered(data)
            return
        }
        const filtered = data.filter(item => item.nomeCompleto.toUpperCase().includes(name.toUpperCase()))
        setFiltered(filtered!)
    }

    const selectDelete = (user: Types.Visitante) => {
        setSelectedUser(user)
        toggleDelete(true)
    }

    return <Modal change_permission={{app: 'Insight', function: "visitante"}} 
    loading={loading}
     add={()=>toggleCreateModal(true)} toggleModal={toggle}>
        <>
            {data.length !== 0?<ModalBody.FindContent>
                <ModalBody.Find onChange={e => filterData(e.target.value)}/> 
                <ModalBody.Icon src={SearchIcon} />       
            </ModalBody.FindContent>:null}
            {filteredUsers.length === 0?
            <><ModalBody.Text>Não foram encontrados usuários</ModalBody.Text></>:
            <ModalBody.Scroll>
                {filteredUsers.map((item, index)=> {
                    return <Body.Row style={{width: '100%', alignItems: 'center', marginBottom: '5px'}} key={index.toString()}>
                    <ModalBody.SelectCard style={{width: 'calc(100% - 60px)',
                        height: '40px', padding: '5px', alignItems: 'flex-start',
                        paddingLeft: '10px'}} onClick={()=>select(item)}>
                        <Body.Text>{item.nomeCompleto}</Body.Text>
                    </ModalBody.SelectCard>
                    <ModalBody.ButtonIcon src={TrashIcon} onClick={()=>selectDelete(item)} />
                </Body.Row>
                })}
            </ModalBody.Scroll>}
        {createtUserModal?<CreateVisitor toggle={toggleCreateModal} idFilial={idFilial} action={getData} />:null}
        {changeUser?<CreateVisitor toggle={toggleChange} idFilial={idFilial} action={getData} change={selectedUser} />:null}
        {modalDelete?<DeleteVisitor toggle={toggleDelete} idUsuarioVisitante={selectedUser!.idUsuarioVisitante} 
                action={getData} nome={selectedUser!.nomeCompleto} />:null}
        </>
    </Modal>
}