import React, { useState } from "react";
import Sidebar from "../../components/SideBar/Sidebar";
import * as Body from './Styles'
import * as defines from '../../../utils/defines/Defines'
import { useSearchParams } from "react-router-dom";
import Button from "../../components/Buttons/Button/Button";
import * as Modals from './Modals/Modals'


const ManagePermissions = () => {
    const [params] = useSearchParams()
    const app = params.get('app')

    const [changePermissions, setChangePermissions] = useState({app: '', function: ''})
    const [modal, toggleModal] = useState(false)

    const openModal = (cargo: string) => {
        setChangePermissions({
            app: app!,
            function: cargo
        })
        toggleModal(true)
    }

    return <Body.Container>
        <Sidebar content={defines.ContentSidebar.MANAGE_PERMISSIONS} />
        <Body.Content>
            <Body.Title>
                <strong style={{color: "#6D7989", fontWeight: 'normal'}}>Apps conectados {">"}</strong> <strong style={{fontWeight: 'normal'}}>Gerenciar Permissões {">"} </strong> {app} 
            </Body.Title>

            <Body.Text>
                Atribua usuários aos níveis de permissão existentes na aplicação.
            </Body.Text>

            <Body.Row>
                <Body.Card>
                    <Body.Icon />
                    <Body.Title>Administrador</Body.Title>
                    <Body.Text style={{textAlign: 'left', fontWeight: 'normal', fontSize: 12}}>
                    -Atribuir novos administradores, supervisores e técnicos <br/>
                    -Visualizar e editar parâmetros do sistema <br/>
                    -Dar ciência em alertas <br/>
                    -Adicionar intervenções realizadas na moenda
                    </Body.Text>
                    <Button color="blue" text="Editar usuários" action={()=>openModal('Administrador')} />
                </Body.Card>
                <Body.Card>
                    <Body.Icon />
                    <Body.Title>Supervisor</Body.Title>
                    <Body.Text style={{textAlign: 'left', fontWeight: 'normal', fontSize: 12}}>
                    -Atribuir novos técnicos <br/>
                    -Visualizar e alterar parâmetros do sistema <br/>
                    -Dar ciência em alertas <br/>
                    -Adicionar intervenções realizadas na moenda
                    </Body.Text>
                    <Button color="blue" text="Editar usuários" action={()=>openModal('Supervisor')} />
                </Body.Card>
                <Body.Card>
                    <Body.Icon />
                    <Body.Title>Técnico</Body.Title>
                    <Body.Text style={{textAlign: 'left', fontWeight: 'normal', fontSize: 12}}>
                    -Visualizar parâmetros do sistema <br/>
                    -Dar ciência em alertas <br />
                    -Adicionar intervenções realizadas na moenda
                    </Body.Text>
                    <Button color="blue" text="Editar usuários" action={()=>openModal('Técnico')} />
                </Body.Card>
            </Body.Row>
        </Body.Content>
        {modal?<Modals.ManagePermissions change_permissions={changePermissions} toggle={toggleModal}
             />:null}
    </Body.Container>
}

export default ManagePermissions