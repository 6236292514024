import React from "react"
import * as Body from './Styles'
import Spinner from '../../../../assets/icons/spinner.svg'

type ButtonProps = {
    action: any,
    text: string,
    color?: string
    width?: number,
    loading?: boolean,
    disabled?: boolean
}

const Button: React.FC <ButtonProps> = ({
    action, text, color, width, loading, disabled
}) => {

    const isDisabled = () =>{
        if(loading || disabled){
            return true
        }
        return false
    }

    const getColor = () => {
        if(disabled){
            return 'gray'
        }
        return color
    }

    return <Body.Content width={width}>
        <Body.Button onClick={action} color={getColor()} disable={disabled}
            width={width} disabled={isDisabled()}
            style={loading?{width: '40px'}:{}}>
            {loading?"":text}
        </Body.Button>
        {loading?<Body.Icon src={Spinner} />:null}
    </Body.Content>
}

export default Button