import React from "react";
import {
    BrowserRouter ,
    Routes,
    Route
} from "react-router-dom";
import './index.css'
import { Provider } from "react-redux";
import store from "../redux/store";
import Login from "../pages/Login/Login";
import Notify from "../components/Notify/Notify";
import Recover from "../pages/recover/Recover";
import ActivateAccount from "../pages/activateAccount/ActivateAccount";
import MyAccount from "../pages/myAccount/MyAccount";
import ManageUsers from "../pages/manageUsers/manageUsers";
import ChangeUserData from "../pages/changeUserData/ChangeUserData";
import Auth from "../pages/auth/Auth";
import ManageCompanies from "../pages/manageCompanies/ManageCompanies";
import ChangeCompanyData from "../pages/changeCompanyData/ChangeCompanyData";
import ConnectedApps from "../pages/connectedApps/ConnectedApps";
import DHM from "../pages/DHM/DHM";
import Alert from "../components/Alert/Alert";
import Privacy from "../pages/privacy/Privacy";
import PrivacyCompany from "../pages/privacyCompany/PrivacyCompany";
import InSight from "../pages/InSight/InSight";


const RoutesMobile = () => {
    return <BrowserRouter>
                <Provider store={store}>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/recuperar" element={<Recover />} />
                    <Route path="/ativar-conta" element={<ActivateAccount />} />
                    <Route path="/minha-conta" element={<MyAccount />} />
                    <Route path="/gerenciar-usuarios" element={<ManageUsers />} />
                    <Route path="/alterar-dados" element={<ChangeUserData />} />
                    <Route path="/autenticacao" element={<Auth />} />
                    <Route path="/gerenciar-empresas" element={<ManageCompanies />} />
                    <Route path="/alterar-dados-empresa" element={<ChangeCompanyData />} />
                    <Route path="/apps-conectados" element={<ConnectedApps />} />
                    <Route path="/gerenciar-dhm" element={<DHM />} />
                    <Route path="/gerenciar-insight" element={<InSight />} />
                    <Route path="/privacidade" element={<Privacy />} />
                    <Route path="/privacidade-empresa" element={<PrivacyCompany />} />
                </Routes>
                <Notify />
                <Alert />
                </Provider>
            </BrowserRouter>
}

export default RoutesMobile