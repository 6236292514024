import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Modal from "../../../components/Modals/Modal/Modal";
import * as Body from '../Styles'
import * as ModalBody from './Styles'
import * as defines from '../../../../utils/defines/Defines'
import * as Types from '../../../../utils/types/Types'
import * as Functions from '../../../../utils/functions/Functions'
import SearchIcon from '../../../../assets/icons/Search.svg'
import TrashIcon from '../../../../assets/icons/trash-icon.svg'
import ChangeIcon from '../../../../assets/icons/change-icon.svg'
import Button from "../../../components/Buttons/Button/Button";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

/** MUDAR PERMISSÃO **/

type ChangePermissionProps = {
    user: {
        idUsuario: string,
        nomeCompleto: string,
        idDHM: string
    }
    toggle: Dispatch<SetStateAction<boolean>>
}


export const ChangePermission: React.FC <ChangePermissionProps> = ({
    user, toggle
}) => {

    const [selected, setSelected] = useState(defines.DHMAcesso.TECNICO)
    const [loading, toggleLoading] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const getCargo = () => {
        switch(selected){
            case defines.DHMAcesso.ADMINISTRADOR:
                return "Administrador"
            case defines.DHMAcesso.SUPERVISOR:
                return "Supervisor"
            case defines.DHMAcesso.TECNICO:
                return "Técnico"
        }
    }

    const tryUpdate = () => {
        toggleLoading(true)
        axios.put(defines.apiURL+"/api/DHMCrud", { idDHM: user.idDHM, nivelAcesso: selected},  {withCredentials: true})
        .then(response => {
                toggleLoading(false)
                dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '', text: user.nomeCompleto+' agora é um '+getCargo()})
                toggle(false)
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch)
                toggleLoading(false)
            })
    }

    return <Modal title="Mudar permissão" loading={loading}>
        <>
            <ModalBody.UserTitle>Alterar permissão de <strong>{user.nomeCompleto}</strong> para</ModalBody.UserTitle>
            <Body.Row style={{marginTop: '15px'}}>
                <ModalBody.SelectCard active={selected === defines.DHMAcesso.ADMINISTRADOR} 
                    onClick={()=>setSelected(defines.DHMAcesso.ADMINISTRADOR)}>
                        <Body.Icon style={{width: '50px', height: '50px',
                            backgroundColor: selected === defines.DHMAcesso.ADMINISTRADOR?
                            defines.BlueTec:"rgba(0,0,0,0.05)"}}/>
                        <Body.Text>Administrador</Body.Text>
                </ModalBody.SelectCard>
                <ModalBody.SelectCard active={selected === defines.DHMAcesso.SUPERVISOR}  
                    onClick={()=>setSelected(defines.DHMAcesso.SUPERVISOR)}>
                        <Body.Icon style={{width: '50px', height: '50px',
                            backgroundColor: selected === defines.DHMAcesso.SUPERVISOR?
                            defines.BlueTec:"rgba(0,0,0,0.05)"}}/>
                        <Body.Text>Supervisor</Body.Text>
                    </ModalBody.SelectCard>
                <ModalBody.SelectCard active={selected === defines.DHMAcesso.TECNICO} 
                    onClick={()=>setSelected(defines.DHMAcesso.TECNICO)}>
                        <Body.Icon style={{width: '50px', height: '50px',
                            backgroundColor: selected === defines.DHMAcesso.TECNICO?
                            defines.BlueTec:"rgba(0,0,0,0.05)"}}/>
                        <Body.Text>Técnico</Body.Text>
                    </ModalBody.SelectCard>
            </Body.Row>
            <Body.Row style={{gridGap: '0px 10px', justifyContent: 'center', marginTop: '20px'}}>
                <Button text="Alterar" color="green" action={()=>tryUpdate()} loading={loading}
                    width={120} />
                <Button text="Cancelar" color="red" action={()=>toggle(false)} disabled={loading}
                    width={120} />
            </Body.Row>
        </>
    </Modal>
}


/** ADICIONAR USUARIO */

type AddUserProps = {
    toggle: Dispatch<SetStateAction<boolean>>,
    idDHM: string,
    app: string,
    nome: string,
    cargo: string,
    action: any
}

const AddUserToFunction: React.FC <AddUserProps> = ({
    nome, app, cargo, toggle, idDHM
}) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, toggleLoading] = useState(false) 

     const getLevel = () => {
        switch(cargo){
            case 'Administrador':
                return defines.DHMAcesso.ADMINISTRADOR
            case 'Supervisor':
                return defines.DHMAcesso.SUPERVISOR
            case 'Técnico':
                return defines.DHMAcesso.TECNICO
        }
    }


    const tryAdd = () => {
        toggleLoading(true)
        axios.put(defines.apiURL+"/api/DHMCrud", { idDHM, nivelAcesso: getLevel()},  {withCredentials: true})
        .then(response => {
            dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '', text: nome+' agora é um '+cargo})
            toggleLoading(false)
            toggle(false)
        })
        .catch(error => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
        })
    }

    return <Modal title="" loading={loading} >
                <div style={{display: 'flex', flexDirection: 'column',
                        alignItems: 'center'}}>
                    <Body.Text style={{marginBottom: '20px', fontWeight: 400}}>
                        Conceder privilégios de <strong>{cargo}</strong> para <strong>{nome}</strong> no app
                        <strong> {app}</strong></Body.Text>
                    <Body.Row>
                        <Button text="Salvar" width={200} action={()=>tryAdd()} loading={loading} />
                        <Button text="Cancelar" width={200} action={()=>toggle(false)} disabled={loading} />
                    </Body.Row>
                </div>
            </Modal>
}


/** DELETE USER */

// type DeleteUserProps = {
//     toggle: Dispatch<SetStateAction<boolean>>,
//     nome: string,
//     app: string,
//     cargo: string,
//     action: any
// }


// const DeleteUser: React.FC <DeleteUserProps> = ({
//     nome, toggle, app, cargo, action
// }) => {

//     return <Modal title="" >
//                 <div style={{display: 'flex', flexDirection: 'column',
//                         alignItems: 'center'}}>
//                     <Body.Text style={{marginBottom: '20px'}}>
//                         Remover privilégios de <strong>{cargo}</strong> de <strong>{nome}</strong> no app
//                         <strong> {app}</strong></Body.Text>
//                     <Body.Row>
//                         <Button text="Remover" color="red" width={200} action={()=>action()} />
//                         <Button text="Cancelar" width={200} action={()=>toggle(false)} />
//                     </Body.Row>
//                 </div>
//             </Modal>
// }

/** SELECIONAR USUÁRIOS **/

type SelectUser = {
    toggle: Dispatch<SetStateAction<boolean>>,
    change_permissions: {
        function: string,
        app: string
    },
}

export const AddUser: React.FC <SelectUser> = ({
    toggle, change_permissions
}) => {
    const [selectedUser, setSelectedUser] = useState({id: '', nome: ''})
    const [changePermissionModal, toggleChangePermissionModal] = useState(false)
    // const [deleteModal, toggleDelete] = useState(false)
    const [loading, toggleLoading] = useState(false)
    const [filtered, setFiltered] = useState<Types.DHMInfo[]>([])
    const [data, setData] = useState<Types.DHMInfo[]>([])
    const dispatch = useDispatch()
    const navigate = useNavigate()

    
    
    useEffect(()=>{
        const getBody = () => {
            switch(change_permissions.function){
                case 'Administrador':
                    return 'availableAdmin'
                case 'Supervisor':
                    return 'availableSup'
                case 'Técnico':
                    return 'availableTec'
            }
        }
        const getURL = () => {
            switch(change_permissions.app){
                case "DHM4.0":
                    return defines.apiURL+"/api/DHMCrud"
            }
        }
        
        toggleLoading(true)
        axios.get(getURL()+"&"+getBody())
            .then((response)=>{
                setData(response.data.content)
                setFiltered(response.data.content)
                toggleLoading(false)
            })
            .catch((error)=>{
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch)
                toggleLoading(false)
            })
    }, [dispatch, navigate, change_permissions])

    const select = (id: string, nome: string) => {
        setSelectedUser({id, nome})
        toggleChangePermissionModal(true)
    }

    const getCargo = (cargo?: number) => {
        if(!cargo){
            return ""
        }
        switch(cargo){
            case defines.NivelAcesso.ADMINISTRADOR:
                return "Administrador"
            case defines.NivelAcesso.TECNICO:
                return "Técnico"
            case defines.NivelAcesso.TECTROL:
                return "Tectrol"
        }
    }

    const filterData = (name: string) => {
        if(name.length === 0){
            setFiltered(data)
            // toggleHasLength(false)
            return
        }
        // toggleHasLength(true)
        const filtered = data.filter(item => item.nomeCompleto.toUpperCase().includes(name.toUpperCase()))
        setFiltered(filtered!)
    }

    return <Modal title="Selecionar usuário" toggleModal={toggle} loading={loading} >
        <>
        <ModalBody.FindContent>
            <ModalBody.Find onChange={e => filterData(e.target.value)}/> 
            <ModalBody.Icon src={SearchIcon} />       
        </ModalBody.FindContent>
        <ModalBody.Scroll style={{width: '500px'}}>
            {filtered.map((item, index) => {
                return <ModalBody.User style={{marginBottom: '5px'}}
                    key={index.toString()} onClick={()=>select(item.idDHM, item.nomeCompleto)}>
                    <Body.Row style={{alignItems: 'center', gridGap: '0px 10px'}}>
                        {/* <ModalBody.UserPhoto src={item.imagemUsuario} /> */}
                        <ModalBody.UserTitle>{item.nomeCompleto}</ModalBody.UserTitle>
                    </Body.Row>
                    <ModalBody.UserText>{getCargo(item.nivelAcesso)}</ModalBody.UserText>
                </ModalBody.User>
            })}
        </ModalBody.Scroll>
        {changePermissionModal?<AddUserToFunction nome={selectedUser.nome} app={change_permissions.app} 
            cargo={change_permissions.function} toggle={toggleChangePermissionModal} action={()=>{}} idDHM={selectedUser.id} />:null}
        </>
    </Modal>
}

/** GERENCIAR PERMISSÕES **/

type ManagePermissionProps = {
    change_permissions: {
        function: string,
        app: string
    },
    toggle: Dispatch<SetStateAction<boolean>>
}
export const ManagePermissions: React.FC <ManagePermissionProps> = ({
    change_permissions, toggle
}) => {
    const [selectUserModal, toggleSelectUserModal] = useState(false)
    const [data, setData] = useState<Types.DHMInfo[]>([])
    // const [hasLength, toggleHasLength] = useState(false)
    const [filteredUsers, setFiltered] = useState<Types.DHMInfo[]>([])
    const [selectedUser, setSelectedUser] = useState({idUsuario:'', nomeCompleto: '',idDHM: ''})
    const [changePermissionModal, toggleChangePermissionModal] = useState(false)
    const [loading, toggleLoading] = useState(true)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const select = (idUsuario: string, nomeCompleto: string, idDHM: string) => {
        setSelectedUser({idUsuario, nomeCompleto, idDHM})
        toggleChangePermissionModal(true)
    }

    
    // const getUsers = () => {
        //     toggleLoading(true)
        //     axios.get(getURL()+"&"+getBody())
        //         .then((response)=>{
            //             setData(response.data.content)
            //             setFiltered(response.data.content)
            //             toggleLoading(false)
            //         })
            //         .catch((error)=>{
                //             Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch)
                //             toggleLoading(false)
                //         })
                // }
                
    useEffect(()=>{
        const getBody = () => {
            switch(change_permissions.function){
                case 'Administrador':
                    return 'adm'
                case 'Supervisor':
                    return 'sup'
                case 'Técnico':
                    return 'tec'
            }
        }
    
        const getURL = () => {
            switch(change_permissions.app){
                case "DHM4.0":
                    return defines.apiURL+"/api/DHMCrud"
            }
        }
        toggleLoading(true)
        axios.get(getURL()+"&"+getBody())
        .then((response)=>{
            setData(response.data.content)
            setFiltered(response.data.content)
            toggleLoading(false)
        })
        .catch((error)=>{
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch)
            toggleLoading(false)
        })
    }, [dispatch, navigate, change_permissions])

    const filterData = (name: string) => {
        if(name.length === 0){
            setFiltered(data)
            // toggleHasLength(false)
            return
        }
        // toggleHasLength(true)
        const filtered = data.filter(item => item.nomeCompleto.toUpperCase().includes(name.toUpperCase()))
        setFiltered(filtered!)
    }


    return <Modal change_permission={change_permissions} loading={loading}
     add={()=>toggleSelectUserModal(true)} toggleModal={toggle}>
        <>
            <ModalBody.FindContent>
                <ModalBody.Find onChange={e => filterData(e.target.value)}/> 
                <ModalBody.Icon src={SearchIcon} />       
            </ModalBody.FindContent>
            <ModalBody.Scroll>
                {filteredUsers.map((item, index)=> {
                    return <Body.Row style={{width: '100%', alignItems: 'center', marginBottom: '5px'}}>
                    <ModalBody.SelectCard style={{width: 'calc(100% - 60px)',
                        height: '40px', padding: '5px', alignItems: 'flex-start',
                        paddingLeft: '10px'}} onClick={()=>select(item.idUsuario, item.nomeCompleto, item.idDHM)}>
                        <Body.Text>{item.nomeCompleto}</Body.Text>
                    </ModalBody.SelectCard>
                    <ModalBody.ButtonIcon src={TrashIcon} />
                    <ModalBody.ButtonIcon src={ChangeIcon} onClick={()=>
                        select(item.idUsuario, item.nomeCompleto, item.idDHM)} />
                </Body.Row>
                })}
            </ModalBody.Scroll>
            {selectUserModal?<AddUser toggle={toggleSelectUserModal} change_permissions={change_permissions}/>:null}
            {changePermissionModal?<ChangePermission toggle={toggleChangePermissionModal}
                user={selectedUser} />:null}
        </>
    </Modal>
}