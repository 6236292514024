import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Modal from "../../../components/Modals/Modal/Modal";
import * as Types from '../../../../utils/types/Types'
import * as Body from '../Styles'
import * as defines from '../../../../utils/defines/Defines'
import * as Functions from '../../../../utils/functions/Functions'
import Button from "../../../components/Buttons/Button/Button";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SearchIcon from '../../../../assets/icons/Search.svg'

type UnitProps = {
    toggle: Dispatch<SetStateAction<boolean>>,
    idUsuario: string,
    email: string,
    nome: string,
}

type ActionUserUnitProps = {
    nome: string,
    unidade: string,
    email: string,
    idFilial?: string,
    idPertenceFilial?: string,
    nivelAcesso?: number,
    toggle: any,
    sent: any,
}

const RemoveUserUnit: React.FC<ActionUserUnitProps> = ({
    nome, unidade, toggle, nivelAcesso, sent, idPertenceFilial
}) => {

    const [loading, toggleLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const tryRemoveAdm = () => {
        toggleLoading(true)
        axios.delete(defines.apiURL+"/api/UsuarioAdministraFilialCRUD?idAdministraFilial="+
            Functions.ReplaceSpecialCharacters(idPertenceFilial!),  {withCredentials: true})
            .then((response) => {
                sent()
                toggle(false)
                toggleLoading(false)
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    }

    const tryRemoveTecnico = () => {
        toggleLoading(true)
        axios.delete(defines.apiURL+"/api/UsuarioPertenceFilialCRUD?idPertenceFilial="+
            Functions.ReplaceSpecialCharacters(idPertenceFilial!), {withCredentials: true})
            .then((response) => {
                sent()
                toggleLoading(false)
                toggle(false)
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    }

    return <Modal title="">
        <div style={{display: 'flex', flexDirection: 'column',
            alignItems: 'center'}}>
            <Body.Text style={{marginBottom: '20px'}}>
                Remover <strong>{nome}</strong> da unidade 
                <strong> {unidade}</strong></Body.Text>
            <Body.Row>
                <Button text="Remover" width={120} color="red" loading={loading}
                    action={nivelAcesso === defines.NivelAcesso.TECNICO?()=>
                        tryRemoveTecnico():()=>tryRemoveAdm()} />
                <Button text="Cancelar" width={120} action={()=>toggle(false)} disabled={loading} />
            </Body.Row>
        </div>
    </Modal>
}

const AddUserUnit: React.FC <ActionUserUnitProps> = ({
    nome, unidade, toggle, sent, email, idFilial
}) => {

    const [loading, toggleLoading] = useState(false)
    const [loadingTec, toggleLoadingTec] = useState(false)
    const [dpo, toggleDPO] = useState(false)
    const navigate = useNavigate()
    
    const dispatch = useDispatch()

    const trySendAdm = () => {
        toggleLoading(true)
        axios.post(defines.apiURL+"/api/UsuarioAdministraFilialCRUD", {
                email, idFilial, encarregadoDados: Number(dpo)}, {withCredentials: true})
            .then((response) => {
                sent()
                toggleLoading(false)
                toggle(false)
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    }

    const trySendTecnico = () => {
        toggleLoadingTec(true)
        axios.post(defines.apiURL+"/api/UsuarioPertenceFilialCRUD", {
            email, idFilial, encarregadoDados: Number(dpo)}, {withCredentials: true})
            .then((response) => {
                sent()
                toggleLoadingTec(false)
                toggle(false)
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoadingTec(false)
            })
    }

    return <Modal title="" toggleModal={toggle}>
        <div style={{display: 'flex', flexDirection: 'column',
            alignItems: 'center'}}>
        <Body.Text style={{marginBottom: '20px'}}>
            Adicionar <strong>{nome}</strong> a unidade 
            <strong> {unidade}</strong></Body.Text>
        <Body.Row style={{marginBottom: '15px', alignItems: 'center'}}>
            <Body.CardInput onClick={()=>toggleDPO(!dpo)}>
                <input type="radio"
                    checked={dpo}/>
                <Body.Text>Gerente de dados</Body.Text>
            </Body.CardInput>
        </Body.Row>
        <Body.Row>
            <Button text="Administrador" width={120} action={()=>trySendAdm()}
                loading={loading} disabled={loadingTec} />
            <Button text="Técnico" width={120} action={()=>trySendTecnico()}
                loading={loadingTec} disabled={loading} />
        </Body.Row>
    </div>
</Modal>
}

export const ChangeUnit: React.FC <UnitProps> = ({
    toggle, idUsuario, nome, email
}) => {
    const [parte, setParte] = useState<Types.Unidade[]>([])
    const [naoFazParte, setNaoFaz] = useState<Types.Unidade[]>([])
    const [filterParte, setFilterParte] = useState<Types.Unidade[]>([])
    const [filterNaoFazParte, setFilterNaoFaz] = useState<Types.Unidade[]>([])
    const [loading, toggleLoading] = useState(true)
    const [unidade, setUnidade] = useState("")
    const [idFilial, setId] = useState('')
    const [idPertenceFilial, setIdPertence] = useState('')
    const [invited, setInvited] = useState<string[]>([])
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const [modalRemove, toggleRemove] = useState(false)
    const [modalAdd, toggleAdd] = useState(false)

    const getData = () => {
        toggleLoading(true)
        axios.get(defines.apiURL+"/api/ParticipationList?userUnavailableBranches="+idUsuario,
            {withCredentials: true})
            .then((response)=>{
                const sorted = Functions.SortResponse(response.data.content, "nomeFilial")
                setParte(sorted)
                setFilterParte(sorted)
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })

        axios.get(defines.apiURL+"/api/ParticipationList?userAvailableBranches="+idUsuario,
            {withCredentials: true})
            .then((response)=>{
                const sorted = Functions.SortResponse(response.data.content, "nomeFilial")
                setNaoFaz(sorted)
                setFilterNaoFaz(sorted)
                toggleLoading(false)
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })

    }

    useEffect(()=>{
        toggleLoading(true)
        axios.get(defines.apiURL+"/api/ParticipationList?userUnavailableBranches="+idUsuario,
            {withCredentials: true})
            .then((response)=>{
                const sorted = Functions.SortResponse(response.data.content, "nomeFilial")
                setParte(sorted)
                setFilterParte(sorted)
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })

        axios.get(defines.apiURL+"/api/ParticipationList?userAvailableBranches="+idUsuario,
            {withCredentials: true})
            .then((response)=>{
                const sorted = Functions.SortResponse(response.data.content, "nomeFilial")
                setNaoFaz(sorted)
                setFilterNaoFaz(sorted)
                toggleLoading(false)
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    }, [dispatch, navigate, idUsuario])

    const sent = () => {
        dispatch({type: 'ADD_ALERT', title: 'Convite enviado', text: 'O convite irá expirar em 2 minutos'})
        setInvited(old => [...old, unidade])
    }

    const removed = () => {
        dispatch({type: 'ADD_NOTIFICATION', title: 'Removido com sucesso', status: 1})
        getData()
    }

    const tryRemove = (text: string, idPertenceFilial: string) => {
        setUnidade(text)
        setIdPertence(idPertenceFilial)
        toggleRemove(true)
    }

    const tryAdd = (text: string, idFilial: string) => {
        setUnidade(text)
        setId(idFilial)
        setInvited(old => [...old, idFilial])
        toggleAdd(true)
    }

    const filterData = (name: string) => {
        if(name.length === 0){
            setFilterNaoFaz(naoFazParte)
            setFilterParte(parte)
            return
        }
        const filtered = parte.filter(item => item.nomeFilial.toUpperCase().includes(name.toUpperCase()))
        setFilterParte(filtered!)
        const usersFiltered = naoFazParte.filter(item => item.nomeFilial.toUpperCase().includes(name.toUpperCase()))
        setFilterNaoFaz(usersFiltered!)
    }

    return <>
    <Modal title="Unidades" toggleModal={toggle} loading={loading}>
        <>
        <Body.FindContent style={{width: '100%'}}>
                <Body.Find onChange={e => filterData(e.target.value)} style={{maxWidth: '100%', width: '100%'}}/> 
                <Body.Icon src={SearchIcon} />       
        </Body.FindContent>
        <Body.Scroll>
            {(filterParte.length === 0 && filterNaoFazParte.length ===0) &&
                <Body.Row>
                    <Body.Text>Não existem unidades disponíveis para este usuário</Body.Text>
                </Body.Row>
            }

            {filterParte.map((item, index) => {
                return <Body.Row key={index} 
                    style={{marginBottom: '10px', alignItems: 'center', width: '100%', justifyContent: 'space-between'}}>
                    <Body.Text >
                        {item.nomeFilial}
                    </Body.Text>
                    <Button text="Remover" action={()=>tryRemove(item.nomeFilial, item.idAdministra!)}
                        color="red" width={100}/>
                </Body.Row>
            })}
            {filterNaoFazParte.map((item, index) => {
                return <Body.Row key={index} style={{marginBottom: '10px', alignItems: 'center', width: '100%', justifyContent: 'space-between'}}>
                    <Body.Text>
                        {item.nomeFilial}
                    </Body.Text>
                    <Button text={invited.includes(item.idFilial)?"Convidado":"Convidar"}
                        disabled={invited.includes(item.idFilial)} 
                        action={()=>tryAdd(item.nomeFilial, item.idFilial)} width={100}/>
                </Body.Row>
            })}            
        </Body.Scroll>
        </>
    </Modal>
    {modalRemove?<RemoveUserUnit sent={removed} nome={nome} unidade={unidade}
        idPertenceFilial={idPertenceFilial}  toggle={()=>toggleRemove(false)}
        email={email} />:null}
    {modalAdd?<AddUserUnit sent={sent} nome={nome} unidade={unidade} 
        toggle={()=>toggleAdd(false)} email={email} idFilial={idFilial} />:null}
    </>
}

type ModalDeleteProps = {
    toggle: Dispatch<SetStateAction<boolean>>,
    idUsuario: string,
    nome: String,
    action: any
}

export const ModalDeleteUser: React.FC <ModalDeleteProps> = ({
    toggle, idUsuario, nome, action
}) => {

    return <Modal title="">
            <div style={{display: 'flex', flexDirection: 'column',
                alignItems: 'center'}}>
                <Body.Text style={{marginBottom: '20px'}}>
                    Tem certeza que deseja excluir <strong>{nome}</strong>
                </Body.Text>
                <Body.Row>
                    <Button text="Excluir" width={120} color="red" action={()=>action()} />
                    <Button text="Cancelar" width={120} action={()=>toggle(false)} />
                </Body.Row>
            </div>
        </Modal>
}