import React, {useState, useEffect} from 'react'
import Loading from '../../components/Loading/Loading'
import * as Body from  './Styles'
import * as defines from '../../../utils/defines/Defines'
import * as Types from '../../../utils/types/Types'
import * as Functions from '../../../utils/functions/Functions'
import * as Modals from './modals/Modals'
import axios from 'axios'
import Input from '../../components/Inputs/Input/Input'
import Button from '../../components/Buttons/Button/Button'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import ButtonWithoutBG from '../../components/Buttons/ButtonWithoutBG/Button'
import IsAuth from '../../components/Modals/IsAuth/IsAuth'


const ChangeCompanyData = () => {
    const location = useLocation()
    const params = location.state as {empresa: string}

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(()=>{
        axios.get(defines.apiURL+"/api/EmpresaCRUD?id="+params.empresa,  {withCredentials: true})
            .then(response => {
                setEmpesa(response.data.content.nomeEmpresa)
                setCNPJ(response.data.content.cnpj)
                setFiliais([])
                axios.get(defines.apiURL+"/api/FilialCRUD?idEmpresa="+params.empresa,
                    {withCredentials: true})
                    .then((response)=>{
                        setFiliais(response.data.content)
                        console.log(response.data.content)
                        toggleLoading(false)
                    })
                    .catch(error => {
                        Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                        toggleLoading(false)
                    })
                setTimeout(()=>{
                    toggleLoading(false)
                }, 2000)
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
        })
    }, [params, dispatch, navigate])

    const [nomeEmpresa, setEmpesa] = useState('')
    const [cnpj, setCNPJ] = useState('')
    const [modalUnits, toggleUnits] = useState(false)
    const [modalDelete, toggleDelete] = useState(false)
    const [loading, toggleLoading] = useState(false)
    const [loadingChange, toggleChange] = useState(false)
    const [isAuthDelete, toggleAuthDelete] = useState(false)
    const [isAuthChange, toggleAuthChange] = useState(false)
    const [filiais, setFiliais] = useState<Types.FilialInfo[]>([])

    const updateCompany = () => {
        if(nomeEmpresa.length === 0 || cnpj.length === 0){
            dispatch({type: 'ADD_NOTIFICATION', status: 3, title: '', text: 'Preencha todos os campos'})
            return
        }
        toggleChange(true)
        axios.put(defines.apiURL+"/api/EmpresaCRUD", {idEmpresa: Functions.UnreplaceSpecialCharacters(params.empresa), nomeEmpresa, cnpj},  {withCredentials: true})
        .then(response => {
            dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '', text: 'Dados da empresa alterados com sucesso'})
            toggleChange(false)
        }) 
        .catch(error => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleChange(false)
        })       
    }

    const actionDelete = () => {
        toggleLoading(true)
        axios.delete(defines.apiURL+"/api/EmpresaCRUD?idEmpresa="+params.empresa,
            {withCredentials: true})
            .then(response => {
                dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '', text: 'Empresa excluida com sucesso'})
                toggleLoading(false)
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
        navigate("/gerenciar-empresas")
    }

    return <Body.Container>
                <Body.Content>
                    <Body.Row style={{justifyContent: 'space-between', width: '100%',
                        marginBottom: '10px', alignItems: 'center'}}>
                        <Body.Title>ALTERAR DADOS</Body.Title>
                        <ButtonWithoutBG text="Voltar" action={()=>navigate(-1)} size={16} />
                    </Body.Row>
                    <Input text={nomeEmpresa} setText={setEmpesa} title="Nome" />
                    <Input text={cnpj} setText={setCNPJ} title="CNPJ" /> 
                    <Body.Row>
                            <Body.ResultContent>
                                <Body.SubTitle>Unidades </Body.SubTitle>
                                <Body.Row style={{gridGap: '0px 5px'}}>
                                    {filiais.map((item, index)=>{
                                        return <Body.Text key={index.toString()}>
                                            {item.nomeFilial}{index!==(filiais.length! - 1)?", ":''}
                                        </Body.Text>
                                    })}
                                </Body.Row>
                            </Body.ResultContent>
                        </Body.Row>
                        <Button text="Editar unidades" action={()=>toggleAuthChange(true)} width={320}/>
                    <Body.Row>
                        <Button text='Salvar' action={()=>updateCompany()} width={155} loading={loadingChange} />
                        <Button text='Excluir' color="red" action={()=>toggleAuthDelete(true)} width={155} 
                            disabled={loadingChange}/>
                    </Body.Row>                        
                </Body.Content>
                {loading?<Loading />:null}
                {modalUnits?<Modals.Units id={params.empresa!} nomeEmpresa={nomeEmpresa} toggle={toggleUnits} />:null}
                {modalDelete?<Modals.ConfirmDeleteCompany nome={nomeEmpresa} id={params.empresa!} toggle={toggleDelete}
                    action={actionDelete}  />:null}
                {isAuthDelete?<IsAuth action={()=>toggleDelete(true)} toggleIsAuth={toggleAuthDelete} />:null}
                {isAuthChange?<IsAuth action={()=>toggleUnits(true)} toggleIsAuth={toggleAuthChange} />:null}
            </Body.Container>

}

export default ChangeCompanyData