import styled, { keyframes } from "styled-components";
import * as defines from '../../../utils/defines/Defines'

export const Container = styled.div`
    background: ${defines.BackgroundColor};
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    overflow: scroll;
`

export const Title = styled.h1`
    font-family: "Comfortaa";
    font-weight: 400;
    color: #000;
    font-size: 50px;
`

export const SubTitle = styled.h3`
    font-family: "Comfortaa";
    font-weight: normal;
    color: #000;
    font-size: 25px;
`

export const Row = styled.div`
    display: flex;
    grid-gap: 0px 10px;
    align-items: center;
    flex-wrap: wrap;
`
export const Circle = styled.div`
    width: 7px;
    height: 7px;
    background: #000;
    border-radius: 10px;
`

export const TitleCompany = styled.h2<{color: string}>`
    font-family: "Signa";
    font-weight: black;
    font-style: italic;
    font-size: 18px;
    color: ${props => props.color};
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 30px 0px;
    padding-bottom: 30px;
    padding-top: 20px;
    align-items: center;
    min-height: 495px;
`

export const TitleContent = styled.div`
    min-height: 200px;
    height: calc(100vh - 385px);
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const Text = styled.p`
    font-family: "Comfortaa";
    font-size: 12px;
    color: #000;
`
const TextAnimate = keyframes`
    from{
        margin-top: -10px;
        opacity: 0;
    }
    to{
        margin-top: 0px;
        opacity: 1;
    }
`

export const TextError = styled.p`
    font-family: "Comfortaa";
    font-size: 10px;
    color: ${defines.Red};
    animation: .35s ${TextAnimate} ease-in;
`