import React, { Dispatch, SetStateAction, useState, useEffect, useRef, useCallback } from "react";
import Sidebar from "../../components/SideBar/Sidebar";
import * as Body from './Styles'
import * as defines from '../../../utils/defines/Defines'
import * as Types from '../../../utils/types/Types'
import * as Functions from '../../../utils/functions/Functions'
import SearchIcon from '../../../assets/icons/Search.svg'
import { useNavigate } from "react-router-dom";
import Button from "../../components/Buttons/Button/Button";
import * as Modals from './Modals/Modals'
import { useDispatch } from "react-redux";
import axios from "axios";
import Loading from "../../components/Loading/Loading";
import IsAuth from "../../components/Modals/IsAuth/IsAuth";
import AdmIcon from '../../../assets/icons/adm-icon.svg'
import SupIcon from '../../../assets/icons/supervisor-icon.svg'
import TecIcon from '../../../assets/icons/tecnico-icon.svg'
import VisIcon from '../../../assets/icons/visita-icon.svg'

const CONTENT = {
    UNIDADE: 1,
    CATEGORIA: 2
}

type SelectUnityProps = {
    setId: Dispatch<SetStateAction<string>>,
    setNome: Dispatch<SetStateAction<string>>,
    setNivel: Dispatch<SetStateAction<number>>,
    toggleConent: Dispatch<SetStateAction<number>>,
    toggleIsAuth: Dispatch<SetStateAction<boolean>>,
    toggleLoading: any
}

const SelectUnity: React.FC<SelectUnityProps> = ({
    setId, setNome, setNivel, toggleConent, toggleLoading, toggleIsAuth
}) => {
    const [data, setData] = useState<Types.UnidadeDHM[]>([])
    const [filtered, setFiltered] = useState<Types.UnidadeDHM[]>([])
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const inputRef = useRef<any>()


    useEffect(() => {
        toggleLoading(true)
        axios.get(defines.apiURL+"/api/InSightCRUD?userFiliaisInSight=1",  {withCredentials: true})
        .then(response => {
            const sorted = Functions.SortResponse(response.data.content, "nomeFilial")
            setData(sorted)
            setFiltered(sorted)
            setTimeout(()=>{
                toggleLoading(false)
            }, 1000)
        })
        .catch(error => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
        })
    }, [dispatch, navigate, toggleLoading])

    const filterData = (name: string) => {
        if (name.length === 0) {
            setFiltered(data)
            return
        }
        const filtered = data.filter(item => item.nomeFilial.toUpperCase().includes(name.toUpperCase()))
        setFiltered(filtered!)
    }

    const selectUnity = (filial: Types.UnidadeDHM) => {
        setId(filial.idFilial)
        setNome(filial.nomeFilial)
        setNivel(filial.nivelAcesso)
        toggleIsAuth(true)
    }

    return <>
        <Body.Row style={{ justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
            <Body.Text>Selecionar unidade</Body.Text>
            <Body.FindContent>
                <Body.Find onChange={e => filterData(e.target.value)} />
                <Body.FindIcon src={SearchIcon} style={{ cursor: 'text' }}
                    onClick={() => inputRef.current.focus()} />
            </Body.FindContent>
        </Body.Row>
        <Body.Scroll >
            {filtered.map((item, index) => {
                return <Body.SelectFilial onClick={() => selectUnity(item)}
                    key={index.toString()} >
                    <Body.Text>{item.nomeFilial}</Body.Text>
                </Body.SelectFilial>
            })}
        </Body.Scroll>

    </>
}


type SelectCategoryProps = {
    openModal: any,
    openVisitorModal: any,
    nivel: number,
    toggleConent: Dispatch<SetStateAction<number>>
}

const SelectCategory: React.FC <SelectCategoryProps> = ({
    openModal, toggleConent, nivel, openVisitorModal
}) => {
    return <>
        <Button text="Voltar" action={() => toggleConent(CONTENT.UNIDADE)} />
        <Body.Row style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
            <Body.Text>
                Atribua usuários aos níveis de permissão existentes na aplicação.
            </Body.Text>
        </Body.Row>
        <Body.Scroll>
        {nivel >= defines.InSightAcesso.ADMINISTRADOR?<>
                <Body.Card>
                    <div style={{width: '100%'}}>
                    <Body.Icon src={AdmIcon} />
                    <Body.Title>Administrador</Body.Title>    
                    <div style={{width: '100%', textAlign: 'left'}}>   
                        <Body.Text style={{textAlign: 'left', fontWeight: 'normal'}}>
                        -Atribuir novos administradores, supervisores e técnicos <br/>
                        -Visualizar, cadastrar e remover áreas, equipamentos, sensores e atuadores <br/>
                        -Visualizar e cadastrar registros de manutenção <br/>
                        -Solicitar envio de dados pelo sistema <br/>
                        -Indicar usuários que receberam alertas de um determinado equipamento<br/>
                        -Visualizar gráficos
                        </Body.Text>
                    </div>
                    </div>
                    <Button color="blue" text="Editar usuários" action={()=>openModal('Administrador')} />
                </Body.Card>
                <Body.Card>
                    <div>
                    <Body.Icon src={SupIcon} />
                    <Body.Title>Supervisor</Body.Title>
                    <div style={{width: '100%', textAlign: 'left'}}>
                        <Body.Text style={{textAlign: 'left', fontWeight: 'normal'}}>
                        -Atribuir novos técnicos <br/>
                        -Visualizar áreas, equipamentos, sensores, atuadores e gráficos <br/>
                        -Visualizar e cadastrar registros de manutenção <br />
                        -Solicitar envio de dados pelo sistema <br/>
                        </Body.Text>
                    </div>
                    </div>
                    <Button color="blue" text="Editar usuários" action={()=>openModal('Supervisor')} />
                </Body.Card></>: null}
                {nivel >= defines.InSightAcesso.SUPERVISOR?
                <Body.Card>
                    <div>
                    <Body.Icon src={TecIcon} />
                    <Body.Title>Técnico</Body.Title>
                    <div style={{width: '100%', textAlign: 'left'}}>
                        <Body.Text style={{textAlign: 'left', fontWeight: 'normal'}}>
                        -Visualizar áreas, equipamentos, sensores, atuadores, registros de manutençã e gráficos <br/>
                        </Body.Text>
                    </div>
                    </div>
                    <Button color="blue" text="Editar usuários" action={()=>openModal('Técnico')} />
                </Body.Card>:null}
                {nivel === defines.InSightAcesso.ADMINISTRADOR?
                <Body.Card>
                    <div>
                    <Body.Icon src={VisIcon} />
                    <Body.Title>Visitante</Body.Title>
                    <div style={{width: '100%', textAlign: 'left'}}>
                        <Body.Text style={{textAlign: 'left', fontWeight: 'normal'}}>
                        Existem dois tipos de visitantes<br/>
                        - Tipo 1: <strong> Visualizar áreas, equipamentos, sensores, atuadores, registros de manutençã e gráficos </strong><br/>
                        - Tipo 2: <strong> Visualizar e cadastrar áreas, equipamentos, sensores, atuadores, registros de manutençã e gráficos </strong><br/>
                        </Body.Text>
                    </div>
                    </div>
                    <Button color="blue" text="Editar usuários" action={()=>openVisitorModal()} />
                </Body.Card>:null}
        </Body.Scroll>
    </>
}



const InSight = () => {

    const [cargo, setCargo] = useState('')
    const [modal, toggleModal] = useState(false)
    const [modalVisitor, toggleVisitor] = useState(false)
    const [nome, setNome] = useState('')
    const [id, setId] = useState('')
    const [nivelAcesso, setNivel] = useState(defines.InSightAcesso.ADMINISTRADOR)
    const [content, toggleContent] = useState(CONTENT.UNIDADE)
    const navigate = useNavigate()
    const [loading, toggleLoading] = useState(false)
    const [isAuth, toggleIsAuth] = useState(false)


    const openModal = (text: string) => {
        setCargo(text)
        toggleModal(true)
    }

    const changeLoading = useCallback(() => {
        toggleLoading(!loading);
    }, [loading]);

    return <Body.Container>
        <Sidebar content={defines.ContentSidebar.MANAGE_PERMISSIONS} />
        <Body.Content>
            <Body.Title>
                <strong style={{ color: "#6D7989", fontWeight: 'normal', cursor: 'pointer' }}
                    onClick={() => navigate('/apps-conectados')}>
                    Apps conectados {"> "}</strong>
                <strong style={{ fontWeight: 'normal', }}>Gerenciar Permissões {">"} </strong> Industry Sight
                {content === CONTENT.CATEGORIA ? <strong style={{ fontWeight: 'normal', }}>{" > " + nome}</strong> : null}
            </Body.Title>
            {content === CONTENT.UNIDADE ? <SelectUnity setId={setId} setNome={setNome} toggleConent={changeLoading} toggleLoading={toggleLoading} setNivel={setNivel}
                toggleIsAuth={toggleIsAuth} />
                : <SelectCategory openModal={openModal} openVisitorModal={()=>toggleVisitor(true)} toggleConent={toggleContent} nivel={nivelAcesso} />}
        </Body.Content>
        {modal ? <Modals.ManagePermissions cargo={cargo} toggle={toggleModal} idFilial={id} userAcess={nivelAcesso}
        /> : null}
         {modalVisitor?<Modals.ManageVisitors toggle={toggleVisitor} idFilial={id} />:null}
        {loading ? <Loading /> : null}
        {isAuth ? <IsAuth toggleIsAuth={toggleIsAuth} action={() => toggleContent(CONTENT.CATEGORIA)} /> : null}
    </Body.Container>
}

export default InSight