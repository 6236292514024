import * as Actions from "./actionsType";
const alert = (
    state: Actions.AlertProps[] = [],
    action: Actions.AlertProps
) => {
    switch(action.type){
        case Actions.ADD_ALERT:
            return [
                ...state,
            {title: action.title,
            text: action.text}]
        case Actions.REMOVE_ALERT: 
            return state.slice(1, state.length)
    }
    return state
}

export default alert