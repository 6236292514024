import axios from 'axios'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Button from '../../../components/Buttons/Button/Button'
import Input from '../../../components/Inputs/Input/Input'
import Modal from '../../../components/Modals/Modal/Modal'
import * as Body from '../Styles'
import * as Types from '../../../../utils/types/Types'
import * as Functions from '../../../../utils/functions/Functions'
import { useNavigate } from 'react-router-dom'
import { apiURL } from '../../../../utils/defines/Defines'

type AddCompanyProps = {
    toggle: Dispatch<SetStateAction<boolean>>,
    getData: any,
}

export const AddCompany: React.FC <AddCompanyProps> = ({
    toggle, getData
}) => {
    const [nomeEmpresa, setEmpresa] = useState('')
    const [cnpj, setCnpj] = useState('')
    
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, toggleLoading] = useState(false)

    const tryCreate = () => {
        toggleLoading(true)
        axios.post(apiURL+"/api/EmpresaCRUD", {cnpj, nomeEmpresa},  {withCredentials: true})
        .then(response => {
            dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '', text: 'Empresa cadastrada com sucesso'})
            getData()
            toggleLoading(false)
            toggle(false)
        })
        .catch(error => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
        })
    }

    return <Modal toggleModal={toggle} title="Cadastrar empresa">
       <>
        <Body.Scroll style={{gridGap: '10px 0px', marginBottom: '10px'}}>        
            <Input text={nomeEmpresa} setText={setEmpresa} title="Nome" />
            <div style={{height: '10px'}} />
            <Input text={cnpj} setText={setCnpj} title="CNPJ" />
            <div style={{height: '10px'}} />
        </Body.Scroll>
        <Button text="Criar" action={()=>tryCreate()} loading={loading} />
        </>
    </Modal>

}