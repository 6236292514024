import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import Modal from "../Modal/Modal";
import axios from "axios";
import * as Body from './Styles'
import * as Functions from '../../../../utils/functions/Functions'
import Button from "../../Buttons/Button/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { apiURL } from "../../../../utils/defines/Defines";

type IsAuthProps = {
    toggleIsAuth: Dispatch<SetStateAction<boolean>>,
    action: any
}

const IsAuth: React.FC <IsAuthProps> = ({
    action, toggleIsAuth
}) => {
    const [loading, toggleLoading] = useState(true)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(()=>{
        toggleLoading(true)
        axios.get(apiURL+"/api/UsuarioCRUD?currentUser=1", {withCredentials: true})
        .then(response => {
            if(response.data.content.isPermissionEnabled === 1){
                action()
                toggleIsAuth(false)
            }
            toggleLoading(false)
        })
        .catch(error => {
            toggleLoading(false)
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch)
        })
    
    },[navigate, dispatch, action, toggleIsAuth])

    return <Modal loading={loading} toggleModal={toggleIsAuth} title="Autenticação requerida">
        <>
            <Body.Text>Para sua segurança, precisamos que você realize uma nova autenticação antes de alterar dados sensíveis. 
                Atenção: a permissão para a alteração dura apenas 15 minutos.</Body.Text>
            <Button text="Autenticar" action={()=>navigate("/autenticacao")} />
        </>    
    </Modal>
}

export default IsAuth