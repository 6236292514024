import React from 'react';
import ReactDOM from 'react-dom/client';
import RoutesDesktop from './webapp/routes/Routes';
import reportWebVitals from './reportWebVitals';
import { GlobalStyles } from './GlobalStyle';
import { isMobile } from 'react-device-detect';
import RoutesMobile from './mobile/routes/Routes';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <GlobalStyles />
    {isMobile?<RoutesMobile />:<RoutesDesktop />}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
