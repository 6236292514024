import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Input from "../../../components/Inputs/Input/Input";
import Modal from "../../../components/Modals/Modal/Modal";
import * as Types from '../../../../utils/types/Types'
import * as Body from '../Styles'
import * as defines from '../../../../utils/defines/Defines'
import * as Functions from '../../../../utils/functions/Functions'
import Button from "../../../components/Buttons/Button/Button";
import { useDispatch,  } from "react-redux";
import axios from "axios";
import { verifyEmail } from "../../../../utils/functions/Functions";
import { useNavigate } from "react-router-dom";
import SearchIcon from '../../../../assets/icons/Search.svg'

type ModalProps = {
    toggle: Dispatch<SetStateAction<boolean>>, 
    action: any
}

type SelectUnitProps = {
    setId: Dispatch<SetStateAction<string>>
    setNome: Dispatch<SetStateAction<string>>
    toggle: Dispatch<SetStateAction<boolean>>
}

const SelectUnit: React.FC <SelectUnitProps> = ({
    setId, setNome, toggle
}) => {

    const [loading, toggleLoading] = useState(true)
    const [filiais, setFiliais] = useState<Types.Unidade[]>([])
    const [filteredFiliais, setFiltered] = useState<Types.Unidade[]>([])
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(()=>{
        toggleLoading(true)
        axios.get(defines.apiURL+"/api/FilialCRUD",  {withCredentials: true})
            .then((response)=>{
                const sorted = Functions.SortResponse(response.data.content, "nomeFilial")
                setFiliais(sorted)
                setFiltered(sorted)
                toggleLoading(false)
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    },[dispatch, navigate])

    const select = (id: string, nome: string) => {
        setId(id)
        setNome(nome)
        toggle(false)
    }

    const filterData = (name: string) => {
        if(name.length === 0){
            setFiltered(filiais)
            return
        }
        const filtered = filiais.filter(item => item.nomeFilial.toUpperCase().includes(name.toUpperCase()))
        setFiltered(filtered!)
    }

    return <Modal title="Selecionar unidade" toggleModal={toggle} loading={loading}>
        <>
        <Body.FindContent style={{width: '100%'}}>
                <Body.Find onChange={e => filterData(e.target.value)} style={{maxWidth: '100%', width: '100%'}}/> 
                <Body.Icon src={SearchIcon} />       
        </Body.FindContent>
        <Body.Scroll>
            {filteredFiliais.map((item, index)=>{
                return <Body.SelectUser key={index.toString()}
                onClick={()=>select(item.idFilial, item.nomeFilial)}>
                    <Body.Text>{item.nomeFilial}</Body.Text>
                </Body.SelectUser>
            })}
            {
                filteredFiliais.length === 0?<Body.Text style={{textAlign: "center"}}>Não foram encontradas filiais</Body.Text>:null
            }
        </Body.Scroll>
        </>
    </Modal>
}

export const CreateUser: React.FC <ModalProps> = ({
    toggle, action
}) => {

    const [nomeCompleto, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [ddd, setDDD] = useState('')
    const [celular, setTelefone] = useState('')
    const [nivelAcesso, toggleNivel] = useState(1)
    const [idFilial, setIdFilial] = useState('')
    const [dpo, toggleDPO] = useState('0')

    const [hasLenght, toggleLength] = useState(false)
    const [hasUpper, toggleUpper] = useState(false)
    const [hasLower, toggleLower] = useState(false)
    const [hasSpecial, toggleSpecial] = useState(false)

    useEffect(()=>{
        const verify = Functions.checkPasswordRestrictions(senha)

        toggleLength(verify.lenght)
        toggleLower(verify.lowercase)
        toggleUpper(verify.uppercase)
        toggleSpecial(verify.special)
    }, [senha])

    const getColor = (verify: boolean) => {
        if(verify){
            return "green"
        }
        return "red"
    }

    const [loading, toggleLoading] = useState(false)

    const [nomeFilial, setNomeFilial] = useState('')
    const [modalSelect, toggleSelect] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const tryCreate = () => {

        if(nomeCompleto.length === 0 || !verifyEmail(email) || senha.length < 12 ||
            ddd.length !== 2  || celular.length !== 9){
            dispatch({type: 'ADD_NOTIFICATION', title: 'Erro', text: 'Preencha todos os campos corretamente',
            status: 3})
            return
         }

         toggleLoading(true)
         axios.post(defines.apiURL+"/api/UsuarioCRUD", {
            email, senha,nomeCompleto, ddd, celular, nivelAcesso, idFilial, encarregadoDados: dpo
         }, {withCredentials: true})
            .then((response)=>{
                dispatch({type: 'ADD_NOTIFICATION', status: 1, text: 'Usuário criado com sucesso'})
                toggleLoading(false)
                action()
                toggle(false)
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
         
    }


    return <>
    <Modal toggleModal={toggle} title="Adicionar usuário">
        <>
        <Body.Scroll style={{gridGap: '10px 0px', marginBottom: '10px'}}>        
            <Input text={nomeCompleto} setText={setNome} title="Nome completo" />
            <div style={{height: '10px'}} />
            <Input text={email} setText={setEmail} title="Email" />
            <div style={{height: '10px'}} />
            <Input text={senha} setText={setSenha} title="Senha" password={true}/>
            <div style={{height: '10px'}} />
            <Body.Text style={{fontWeight: '300', fontSize: 14}}>
                Sua senha deve conter letras <strong style={{color: getColor(hasUpper)}}>maiúsculas</strong>
                , <strong style={{color: getColor(hasLower)}}>minúsculas</strong>
                , <strong style={{color: getColor(hasSpecial)}}>caracteres especiais (ex: @#$)
                </strong> e no minimo<strong style={{color: getColor(hasLenght)}}> 12 caracteres</strong>
            </Body.Text>
            <div style={{height: '10px'}} />
            <Body.Row style={{width: '100%', justifyContent: 'space-between',
                gridGap: '10px 10px'}}>
                <div style={{width: 'calc(25% - 5px)',}}>
                    <Input text={ddd} setText={setDDD} limit={2} title="DDD" />
                </div>
                <div style={{width: 'calc(75% - 5px)'}}>
                    <Input text={celular} setText={setTelefone} title="Telefone"
                        limit={9}/>
                </div>
            </Body.Row>
            <div style={{height: '10px'}} />
            <Body.SubTitle style={{marginBottom: '5px'}}>
                Nivel de acesso</Body.SubTitle>
            <Body.Row>
                <Body.CardInput onClick={()=>toggleNivel(defines.NivelAcesso.TECNICO)}>
                    <input type="radio"
                        checked={nivelAcesso === defines.NivelAcesso.TECNICO}/>
                    <Body.Text>Técnico</Body.Text>
                </Body.CardInput>
                <Body.CardInput onClick={()=>toggleNivel(defines.NivelAcesso.ADMINISTRADOR)}>
                    <input type="radio"
                        checked={nivelAcesso === defines.NivelAcesso.ADMINISTRADOR}/>
                    <Body.Text>Administrador</Body.Text>
                </Body.CardInput>
                {true?
                 <Body.CardInput onClick={()=>toggleNivel(defines.NivelAcesso.TECTROL)}>
                    <input type="radio"
                        checked={nivelAcesso === defines.NivelAcesso.TECTROL}/>
                    <Body.Text>Tectrol</Body.Text>
                </Body.CardInput>:null
                }
            </Body.Row>
            {true?
            <><div style={{height: '10px'}} />
            <Body.SubTitle style={{marginBottom: '5px'}}>
                Selecione uma unidade</Body.SubTitle>
            <Body.CardUnit onClick={()=>toggleSelect(true)}>
                <Body.TextUnit>
                    {nomeFilial}
                </Body.TextUnit>
            </Body.CardUnit></>:null}
            <div style={{height: '10px'}} />
            <Body.SubTitle style={{marginBottom: '5px'}}>
                Permissões</Body.SubTitle>
            <Body.CardInput onClick={()=>toggleDPO(dpo === "0"?"1":"0")}>
                <input type="radio"
                    checked={dpo === "1"}/>
                <Body.Text>Gerente de dados</Body.Text>
            </Body.CardInput>
        </Body.Scroll>
        <Button text="Criar" action={()=>tryCreate()} loading={loading}/>
        </>
    </Modal>
    {modalSelect?<SelectUnit setId={setIdFilial} toggle={toggleSelect}
        setNome={setNomeFilial}/>:null}
    </>
}
