import React, { useEffect, useState } from "react";
import Sidebar from "../../components/SideBar/Sidebar";
import * as Body from './Styles'
import * as defines from '../../../utils/defines/Defines'
import * as Functions from '../../../utils/functions/Functions'
import Input from "../../components/Inputs/Input/Input";
import * as Types from '../../../utils/types/Types'
import Button from "../../components/Buttons/Button/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading/Loading";
import * as Modals from './modals/Modals'
import { State } from "../../redux/store";
import IsAuth from "../../components/Modals/IsAuth/IsAuth";

const MyAccount = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const acessLevel = useSelector((state: State) => state.acessLevel.acessLevel)
    const [loading, toggleLoading] = useState(true)
    const [changePWD, togglePWD] = useState(false)
    const [deleteAccount, toggleDelete] = useState(false)
    const [isAuth, toggleIsAuth] = useState(false)

    const [idUsuario, setId] = useState("")
    const [nomeCompleto, setNome] = useState('')
    const [ddd, setDDD] = useState('')
    const [celular, setCelular] = useState('')
    const [email, setEmail] = useState('')
    const [empresa, setEmpresa] = useState('')
    const [cnpj, setCNPJ] = useState('')
    const [data, setData] = useState<Types.MinhaContaProps>()
    const [filiais, setFiliais] = useState<Types.Unidade[]>([])
    const [loadingSave, toggleSave] = useState(false)

    const getCompanyData = () => {
        axios.get(defines.apiURL+"/api/ParticipationList",  {withCredentials: true})
            .then((response)=>{
                setEmpresa(response.data.content.nomeEmpresa)
                setCNPJ(response.data.content.cnpj)
                setFiliais(response.data.content.filiais)
                toggleLoading(false)
            })
            .catch((error)=>{
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
                return
            })
    }

    const getData = () => {
        toggleLoading(true)

        axios.get(defines.apiURL+"/api/UsuarioCRUD?currentUser=1", {withCredentials: true})
            .then((response)=>{
                setData(response.data.content)
                setId(response.data.content.idUsuario)
                if(response.data.content.nivelAcesso !== defines.NivelAcesso.TECTROL){
                    getCompanyData()
                }else{
                    toggleLoading(false)
                }
            })
            .catch((error)=>{
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
                return
            })
    }
    
    useEffect(()=>{
        toggleLoading(true)

        axios.get(defines.apiURL+"/api/UsuarioCRUD?currentUser=1", {withCredentials: true})
            .then((response)=>{
                setData(response.data.content)
                setId(response.data.content.idUsuario)
                if(response.data.content.nivelAcesso !== defines.NivelAcesso.TECTROL){
                    axios.get(defines.apiURL+"/api/ParticipationList", {withCredentials: true})
                    .then((response)=>{
                        setFiliais(response.data.content.filiais)
                        setEmpresa(response.data.content.nomeEmpresa)
                        setCNPJ(response.data.content.cnpj)
                        toggleLoading(false)
                    })
                    .catch((error)=>{
                        Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                        toggleLoading(false)
                        return
                    })
                }else{
                    toggleLoading(false)
                }
            })
            .catch((error)=>{
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
                return
            })
    },[dispatch, navigate])

    const changeUserData = () => {
        toggleSave(true)
        axios.put(defines.apiURL+"/api/UsuarioCRUD", {nomeCompleto, email, celular, ddd,  idUsuario},
        {withCredentials: true})
        .then(response => {
            dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '', text: 'Dados alterados com sucesso'})
            getData()
            toggleSave(false)
        })
        .catch(error => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleSave(false)
        })
    }

    return <Body.Container>
        <Sidebar content={defines.ContentSidebar.MY_ACCOUNT} />
        <Body.Content>
        <Body.Title>DADOS PESSOAIS</Body.Title>
            <Input text={nomeCompleto} setText={setNome} title="Nome Completo"  placeholder={data?.nomeCompleto}/>
            <Input text={email} setText={setEmail} title="Email"  placeholder={data?.email} />
            <Body.Row style={{gridGap: '0px 10px'}}>
                <div style={{width: 'calc(25% - 5px)'}}>
                    <Input text={ddd} setText={setDDD} limit={2} title="DDD"  placeholder={data?.ddd} />
                </div>
                <div style={{width: 'calc(75% - 5px)'}}>
                    <Input text={celular} setText={setCelular} title="Telefone"  placeholder={data?.celular}
                        limit={9}/>
                </div>
            </Body.Row>
            <Body.Row>
                <Button text="Salvar" color="blue" action={()=>changeUserData()}
                    loading={loadingSave} width={150}/>
                <Button text="Alterar senha" action={()=>toggleIsAuth(true)} width={150}/>
            </Body.Row>
            <Button text="Deletar conta" color="red" action={()=>toggleDelete(true)} width={150}/>
            {acessLevel !== defines.NivelAcesso.TECTROL?
            <>
            <Body.Title style={{marginTop: '20px'}}>DADOS DA EMPRESA</Body.Title>
            <Body.ResultContent>
                <Body.SubTitle>Nome</Body.SubTitle>
                <Body.Text>{empresa}</Body.Text>
            </Body.ResultContent>
            <Body.ResultContent>
                <Body.SubTitle>CNPJ</Body.SubTitle>
                <Body.Text>{cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4.$5")}</Body.Text>
            </Body.ResultContent>
            <Body.Row>
                <Body.ResultContent>
                    <Body.SubTitle>Unidades vinculadas à você</Body.SubTitle>
                    <Body.Row style={{gridGap: '0px 5px'}}>
                        {filiais.map((item, index)=>{
                            return <Body.Text key={index.toString()}>
                                {item.nomeFilial}{index!==(filiais.length! - 1)?", ":''}
                            </Body.Text>
                        })}
                    </Body.Row>
                </Body.ResultContent>
            </Body.Row>
            <Body.SubTitle>Você não pode alterar os dados da empresa.
                Se algo estiver divergente, por favor, entre em contato.</Body.SubTitle></>: null}
        </Body.Content>
        {loading?<Loading />:null}
        {deleteAccount?<Modals.DeleteAccount toggle={toggleDelete} idUsuario={Functions.ReplaceSpecialCharacters(idUsuario)} />:null}
        {changePWD?<Modals.ChangePWD toggle={togglePWD} idUsuario={idUsuario} />:null}
        {isAuth?<IsAuth action={()=>togglePWD(true)} toggleIsAuth={toggleIsAuth} />:null}
    </Body.Container>
}

export default MyAccount