import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import * as Body from './Styles'

import EyeSlashed from '../../../../assets/icons/eye-slash-regular.svg'
import EyeRegular from '../../../../assets/icons/eye-regular.svg'

 type InputProps = {
    text: string,
    placeholder?: string,
    setText: Dispatch<SetStateAction<string>> | any,
    width?: number,
    password?: boolean,
    error?: boolean,
    title?: string,
    limit?: number,
    action?: any
 }

const Input: React.FC <InputProps> = ({
    text, setText, width, password, placeholder, error, title, limit, action
}) => {
    const [visible, toggleVisibility] = useState(true)

    useEffect(()=>{
        if(password){
            toggleVisibility(false)
        }
    },[password])

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter' && action) {
            action();
        }
      };

    return <Body.Content width={width}  >
        {title?<Body.Text>{title}</Body.Text>:null}
        <Body.Input value={text} placeholder={placeholder} error={error?error:false}
            maxLength={limit?limit:100} onKeyDown={handleKeyDown}
            onChange={e => setText(e.target.value)} type={visible?"text":"password"} width={width} />
       {password?<Body.Icon src={visible?EyeSlashed:EyeRegular} 
            onClick={()=>toggleVisibility(old => !old)} />:null}
    </Body.Content>
}

export default Input