import React, { useRef, useState } from 'react';
import * as Body from './Style'

type Props = {
    setText: any,
    width?: string
    title?: string
    action?: any
}

const CodeInput: React.FC <Props> = ({
    setText, width,title, action
}) => {

    const [digito1, setDigito1] = useState("")
    const [digito2, setDigito2] = useState("")
    const [digito3, setDigito3] = useState("")
    const [digito4, setDigito4] = useState("")
    const [digito5, setDigito5] = useState("")
    const [digito6, setDigito6] = useState("")

    const number1 = useRef<any>()
    const number2 = useRef<any>()
    const number3 = useRef<any>()
    const number4 = useRef<any>()
    const number5 = useRef<any>()
    const number6 = useRef<any>()

    const field = (text: string, input: any) => {
        if(text.length === 6){
            setDigito1(text[0])
            setDigito2(text[1])
            setDigito3(text[2])
            setDigito4(text[3])
            setDigito5(text[4])
            setDigito6(text[5])
            setText(text)
            number6.current.focus()
            return
        }
        switch(input){
            case number1:
                setDigito1(text)
                setText(text.concat(digito2, digito3, digito4, digito5, digito6))
                if(text.length > 0) number2.current.focus()
                break
            case number2:
                setDigito2(text)
                setText(digito1.concat(text, digito3, digito4, digito5, digito6))
                if(text.length > 0) number3.current.focus()
                break
            case number3:
                setDigito3(text)
                setText(digito1.concat(digito2, text, digito4, digito5, digito6))
                if(text.length > 0 )number4.current.focus()
                break
            case number4:
                setDigito4(text)
                setText(digito1.concat(digito2, digito3, text, digito5, digito6))
                if(text.length > 0)number5.current.focus()
                break
            case number5:
                setDigito5(text)
                setText(digito1.concat(digito2, digito3, digito4, text, digito6))
                if(text.length > 0) number6.current.focus()
                break
            case number6:
                setDigito6(text)
                setText(digito1.concat(digito2, digito3, digito4, digito5, text))
                break
        }
    }

    const handleKeyDown = (event: any, input: any) => {
        if (event.key === 'Enter' && action) {
            action()
        }

        if (event.key === 'Backspace') {
          switch(input){
            case number2:
                if(digito2.length === 1){
                    setDigito2("")
                    return
                }
                else{
                    number1.current.focus()
                }
                break
            case number3:
                if(digito3.length === 1){
                    setDigito3("")
                    return
                }
                else{
                    number2.current.focus()
                }
                break
            case number4:
                if(digito4.length === 1){
                    setDigito4("")
                    return
                }
                else{
                    number3.current.focus()
                }
                break
            case number5:
                if(digito5.length === 1){
                    setDigito5("")
                    return
                }
                else{
                    number4.current.focus()
                }
                break
            case number6:
                if(digito6.length === 1){
                    setDigito6("")
                    return
                }
                else{
                    number5.current.focus()
                }
                break
          }
        }
    };

    return <Body.Content>
        {title?<Body.Text>{title}</Body.Text>:null}
        <Body.DigitsContent>
            <Body.CodeInput autoComplete='one-time-code' ref={number1} value={digito1}
                    onChange={e => field(e.target.value, number1)}/>
            <Body.CodeInput autoComplete='one-time-code' ref={number2} onKeyDown={e => handleKeyDown(e, number2)}
                value={digito2} maxLength={1} onChange={e => field(e.target.value, number2)}/>
            <Body.CodeInput autoComplete='one-time-code' ref={number3} onKeyDown={e => handleKeyDown(e, number3)}
                value={digito3} maxLength={1}  onChange={e => field(e.target.value, number3)}/>
            <Body.CodeInput autoComplete='one-time-code' ref={number4} onKeyDown={e => handleKeyDown(e, number4)}
                value={digito4} maxLength={1} onChange={e => field(e.target.value, number4)}/>
            <Body.CodeInput autoComplete='one-time-code' ref={number5} onKeyDown={e => handleKeyDown(e, number5)}
                value={digito5} maxLength={1} onChange={e => field(e.target.value, number5)}/>
            <Body.CodeInput autoComplete='one-time-code' ref={number6} onKeyDown={e => handleKeyDown(e, number6)}
                    value={digito6} maxLength={1} onChange={e => field(e.target.value, number6)}/>
        </Body.DigitsContent>
        </Body.Content>   
}

export default CodeInput;