import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../components/SideBar/Sidebar";
import * as Body from './Styles'
import SearchIcon from '../../../assets/icons/Search.svg'
import AddIcon from '../../../assets/icons/add-icon.svg'
import * as Functions from  '../../../utils/functions/Functions'
import * as defines from  '../../../utils/defines/Defines'
import * as Types from '../../../utils/types/Types'
import * as Modals from './Modals/Modals'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loading from "../../components/Loading/Loading";
import IsAuth from "../../components/Modals/IsAuth/IsAuth";

const ManageUsers = () => {
    const [modalCreateUser, toggleCreateUser] = useState(false)
    const [data, setData] = useState<Types.UserList>([])
    const [filteredUsers, setFiltered] = useState<Types.UserList>([])
    const [loading, toggleLoading] = useState(true)
    const [isAuthCreate, toggleAuthCreate] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const inputRef = useRef<any>()

    useEffect(()=>{
        toggleLoading(true)
        axios.get(defines.apiURL+"/api/UsuarioCRUD", {withCredentials: true})
            .then((response) => {
                const sorted = Functions.SortResponse(response.data.content, "nomeCompleto")
                setData(sorted)
                setFiltered(sorted)
                setTimeout(()=>{
                    toggleLoading(false)
                }, 2000)
            })
            .catch((error) => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    }, [dispatch, navigate])

    const getUsers = () => {
        toggleLoading(true)
        axios.get(defines.apiURL+"/api/UsuarioCRUD", {withCredentials: true})
            .then((response) => {
                const sorted = Functions.SortResponse(response.data.content, "nomeCompleto")
                setData(sorted)
                setFiltered(sorted)
                setTimeout(()=>{
                    toggleLoading(false)
                }, 2000)
            })
            .catch((error) => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    }

    const filterData = (name: string) => {
        if(name.length === 0){
            setFiltered(data)
            return
        }
        const filtered = data.filter(item => item.nomeCompleto.toUpperCase().includes(name.toUpperCase()))
        setFiltered(filtered!)
    }

    const selectUser = (idUsuario: string) => {
        navigate('/alterar-dados', {state: {user: Functions.ReplaceSpecialCharacters(idUsuario)}})
    }

    return <Body.Container>
        <Sidebar content={defines.ContentSidebar.MANAGER_USERS} />
        <Body.Content style={{flexDirection: 'column'}}>
            <Body.Title >
                Usuários
            </Body.Title>
            <Body.Row style={{width: '100%'}}>
                <Body.FindContent>
                    <Body.Find onChange={e => filterData(e.target.value)}
                        ref={inputRef} />
                    <Body.Icon src={SearchIcon} style={{cursor: 'text'}}
                        onClick={()=>inputRef.current.focus()} />
                </Body.FindContent>
                <Body.HeaderButton onClick={()=>toggleAuthCreate(true)}>
                    <Body.Icon src={AddIcon} style={{position: 'relative', top: 0, left: 0}} />
                </Body.HeaderButton>
            </Body.Row>  
            <Body.Scroll style={{maxHeight: 'calc(100vh - 200px)'}}>
                {filteredUsers.map((item, index)=> {
                    return <Body.SelectUser onClick={()=>selectUser(item.idUsuario)} 
                        key={index.toString()} >
                        <Body.Text>{item.nomeCompleto}</Body.Text>
                    </Body.SelectUser>
                })}
            </Body.Scroll>
        </Body.Content>
        {modalCreateUser?<Modals.CreateUser toggle={toggleCreateUser} action={()=>getUsers()} />:null}
        {loading?<Loading />:null}
        {isAuthCreate?<IsAuth action={()=>toggleCreateUser(true)} toggleIsAuth={toggleAuthCreate} />:null}
    </Body.Container>
}

export default ManageUsers