import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import * as Body from './Styles'
import * as defines from '../../../utils/defines/Defines'
import * as Functions from '../../../utils/functions/Functions'
import Button from "../../components/Buttons/Button/Button";
import ButtonWithoutBG from "../../components/Buttons/ButtonWithoutBG/Button";
import Shield from '../../../assets/icons/shield.svg'
import LockIcon from '../../../assets/icons/lock.svg'
import ShieldIcon from '../../../assets/icons/Shield-header.png'
import TrashIcon from '../../../assets/icons/trash-icon.svg'
import PhoneIcon from '../../../assets/icons/phone-icon.svg'
import { useDispatch } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CodeInput from "../../components/Inputs/InputCode/Input";

type Props = {
    toggleContent: Dispatch<SetStateAction<string>>,
}

type AgreeProps = {
    toggleContent: Dispatch<SetStateAction<string>>,
    loading: boolean,
    action: any
}

const Agree: React.FC <AgreeProps> = ({toggleContent, loading, action}) => {

    return <Body.Card>
        <div style={{display: 'flex', alignItems: 'center', marginBottom: 35, justifyContent: 'space-between'}}>
            <Body.SubTitle style={{fontWeight: 300, marginBottom: 0, width: 'calc(100% - 100px)'}}> 
                Seu direito à privacidade
            </Body.SubTitle>
            <Body.HeaderIcon src={ShieldIcon} />
        </div>
        <Body.Text style={{marginBottom: 10}}>
        <strong>Acreditamos que você deve estar ciente dos dados que coletamos de você</strong>.
         Ao utilizar o nosso app, essas são as informações que armazenamos sobre você:
        </Body.Text>
        <Body.Text style={{marginBottom: 20}}>
            <strong>
            · Nome completo<br/>
            · E-mail<br/>
            · Número de celular<br/>
            · Dispositivos e endereços IPs utilizados para acesso ao app
            </strong>
        </Body.Text>
        <div style={{display: 'flex', alignItems: 'center'}}>
            <Body.Icon src={Shield} style={{marginRight: 10}}/>
            <Body.Text style={{width: 'calc(100% - 40px)'}}>
                Todos os seus dados são criptografados e armazenados em um ambiente seguro.
            </Body.Text>
        </div>
        <div style={{display: 'flex', alignItems: 'center'}}>
            <Body.Icon src={LockIcon} style={{marginRight: 10}}/>
            <Body.Text style={{width: 'calc(100% - 40px)'}}>
                Nós não compartilhamos seus dados com terceiros.
            </Body.Text>
        </div>
        <Button text="Aceito o uso dos dados"  action={action} loading={loading}/>
        <ButtonWithoutBG text="Não aceito o uso dos dados" action={()=>toggleContent("REFUSE")} />
    </Body.Card>
}

const Refuse: React.FC <Props> = ({toggleContent}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const send = () => {
        axios.get(defines.apiURL+"/api/LGPDdenied", {withCredentials: true})
            .then(()=>{
                dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '', text: 'Código enviado com sucesso'})
                toggleContent("DELETE")
            })
            .catch(error =>{
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            })
    }

    return <Body.Card>
        <div style={{display: 'flex', alignItems: 'center', marginBottom: 35, justifyContent: 'space-between'}}>
            <Body.SubTitle style={{fontWeight: 300, marginBottom: 0, width: 'calc(100% - 100px)'}}> 
                Seu direito à privacidade
            </Body.SubTitle>
            <Body.HeaderIcon src={ShieldIcon} />
        </div>
        <Body.Text style={{marginBottom: 10}}>
        <strong>Para utilizar o app, você precisa aceitar o uso de dados</strong>.
         Se não aceitar, as seguintes ações serão realizadas:
        </Body.Text>
        <div style={{display: 'flex', marginBottom: 10, alignItems: 'center'}}>
            <Body.Icon src={TrashIcon} style={{marginRight: 10, width: 20}}/>
            <Body.Text>
                <strong>Todos os dados</strong> armazenados sobre você serão 
                <strong> excluídos.</strong>
            </Body.Text>
        </div>
        <div style={{display: 'flex', alignItems: 'center', marginBottom: 25}}>
            <Body.Icon src={PhoneIcon} style={{marginRight: 10,  width: 20}}/>
            <Body.Text>
                <strong>Você perderá acesso ao aplicativo web e mobile</strong>, 
                e só poderá acessá-los novamente caso alguem realize seu cadastro.
            </Body.Text>
        </div>
        <Button text="Voltar" action={()=>toggleContent("AGREE")} />
        <ButtonWithoutBG text="Não aceito o uso dos dados" action={()=>send()} />
    </Body.Card>
}

const DeleteAccount: React.FC <Props> = ({toggleContent}) => {
    const [tfaCode, setTFA] = useState('')
    const [loading, toggleLoading] = useState(false)
    const [loadingCode, toggleCode] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [time, setTime] = useState(120)
    const [lock, toggleLock] = useState(true)

    useEffect(() => {
        if(time === 0){
            toggleLock(false)
            return
        }
        const interval = setInterval(() => {
            setTime(seconds => seconds - 1);
        }, 1000);
        
        return () => clearInterval(interval);
    }, [time]);

    const deleteAccount = () => {
        toggleLoading(true)
        axios.post(defines.apiURL+"/api/LGPDdenied", {tfaCode}, {withCredentials: true})
            .then(()=>{
                dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '', text: 'Conta deletada com sucesso'})
                navigate('/')
                toggleLoading(false)
            })
            .catch(error =>{
                toggleLoading(false)
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch)
            })
    }

    const tryResend = () => {
        toggleCode(true)
        axios.get(defines.apiURL+"/api/LGPDdenied", {withCredentials: true})
            .then(()=>{
                toggleCode(false)
                dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '', text: 'Código enviado com sucesso'})
            })
            .catch(error =>{
                toggleCode(false)
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch)
            })
    }

    return <Body.Card>
                <Body.SubTitle> 
                    Deletar conta
                </Body.SubTitle>
                <Body.Text style={{marginBottom: 10}}>
                Enviamos um código para seu email, insira-o para prosseguir com a exclusão.
                </Body.Text>
                <CodeInput setText={setTFA} />
                {lock?<Body.Text>Aguarde {Functions.getTime(time)} para enviar novamente</Body.Text>
                :<ButtonWithoutBG text="Reenviar código" loading={loadingCode}  action={tryResend}/>}
                <Body.Row>
                    <Button text="Voltar" action={()=>toggleContent("REFUSE")} width={150} />
                    <Button text="Deletar conta" color={defines.Red} action={()=>deleteAccount()} width={150} loading={loading}  />
                </Body.Row>
            </Body.Card>
}

const Privacy = () => {

    const [content, toggleContent] = useState("AGREE")
    const [loading, toggleLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const getContent = () => {
        switch(content){
            case "AGREE":
                return <Agree toggleContent={toggleContent} action={accept}
                    loading={loading} />
            case "REFUSE":
                return <Refuse  toggleContent={toggleContent}/>
            case "DELETE": 
                return <DeleteAccount toggleContent={toggleContent} />
        }
    }

    const accept = () => {
        toggleLoading(true)
        axios.post(defines.apiURL+"/api/LGPDCrud", {},{withCredentials: true})
            .then((response)=> {
                navigate('/minha-conta')
                dispatch({type: 'ADD_NOTIFICATION', status: 1, title: "Termos aceitos"})
                toggleLoading(false)
            })
            .catch((error)=>{
                toggleLoading(false)
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            })
    }

    return <Body.Container>
         <Body.Row>
            <Body.TitleCompany color={defines.BlueTec}>
                tectrol
            </Body.TitleCompany>
            <Body.Circle />
            <Body.TitleCompany color={defines.GreenTec}>
                dínamo
            </Body.TitleCompany>
        </Body.Row>
        <Body.Title>Conecta</Body.Title>
        {getContent()}
    </Body.Container>
}

export default Privacy