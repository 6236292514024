export const BackgroundColor = '#F8F8F9'
export const GreenTec = '#4CB931'
export const BlueTec = '#1080D6'
export const Yellow = '#E79720'
export const Red = '#F34A4A'

export const ContentSidebar = {
    MY_ACCOUNT: 1,
    CONNECTED_APPS: 2,
    MANAGER_USERS: 3,
    MANAGE_PERMISSIONS: 4,
    MANAGE_COMPANY: 5
}

export const NivelAcesso = {
    TECNICO: 1,
    ADMINISTRADOR: 2,
    TECTROL: 3
}

export const DHMAcesso = {
    TECNICO: 1,
    SUPERVISOR: 2,
    ADMINISTRADOR: 3,
    TECTROL: 4
}

export const InSightAcesso = {
    TECNICO: 1,
    SUPERVISOR: 2,
    ADMINISTRADOR: 3,
    TECTROL: 4
}

export const apiURL = "https://www.conecta.tectrolnet.com.br"