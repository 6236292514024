import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Modal from "../../../components/Modals/Modal/Modal";
import * as Body from '../Styles'
import * as ModalBody from './Styles'
import * as defines from '../../../../utils/defines/Defines'
import * as Types from '../../../../utils/types/Types'
import * as Functions from '../../../../utils/functions/Functions'

import SearchIcon from '../../../../assets/icons/Search.svg'
import TrashIcon from '../../../../assets/icons/trash-icon.svg' 
import Button from "../../../components/Buttons/Button/Button";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

/** MUDAR PERMISSÃO **/

type ChangePermissionProps = {
    user: {
        idDHM: string,
        nomeCompleto: string,
    },
    action: any,
    toggle: Dispatch<SetStateAction<boolean>>
}

export const ChangePermission: React.FC <ChangePermissionProps> = ({
    user, toggle, action
}) => {

    const [selected, setSelected] = useState(defines.DHMAcesso.TECNICO)
    const [loading, toggleLoading] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const getCargo = () => {
        switch(selected){
            case defines.DHMAcesso.ADMINISTRADOR:
                return "Administrador"
            case defines.DHMAcesso.SUPERVISOR:
                return "Supervisor"
            case defines.DHMAcesso.TECNICO:
                return "Técnico"
        }
    }

    const tryChange = () => {
        toggleLoading(true)
        axios.put(defines.apiURL+"/api/DHMCrud", { idDHM: user.idDHM, nivelAcesso: selected},  {withCredentials: true})
        .then(response => {
            dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '', text: user.nomeCompleto+' agora é um '+getCargo()})
            toggleLoading(false)
            toggle(false)
            action()
        })
        .catch(error => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
        })
    }

    return <Modal title="Mudar permissão" loading={loading}>
        <>
            <ModalBody.UserTitle>Alterar permissão de <strong>{user.nomeCompleto}</strong> para <strong>DHM4.0</strong></ModalBody.UserTitle>
            <Body.Row style={{marginTop: '15px'}}>
                <ModalBody.SelectCard active={selected === defines.DHMAcesso.ADMINISTRADOR} 
                    onClick={()=>setSelected(defines.DHMAcesso.ADMINISTRADOR)}>
                        <Body.Icon style={{width: '10px', height: '10px',
                            backgroundColor: selected === defines.DHMAcesso.ADMINISTRADOR?
                            defines.BlueTec:"rgba(0,0,0,0.05)"}}/>
                        <Body.Text>Administrador</Body.Text>
                </ModalBody.SelectCard>
                <ModalBody.SelectCard active={selected === defines.DHMAcesso.SUPERVISOR}  
                    onClick={()=>setSelected(defines.DHMAcesso.SUPERVISOR)}>
                        <Body.Icon style={{width: '10px', height: '10px',
                            backgroundColor: selected === defines.DHMAcesso.SUPERVISOR?
                            defines.BlueTec:"rgba(0,0,0,0.05)"}}/>
                        <Body.Text>Supervisor</Body.Text>
                    </ModalBody.SelectCard>
                <ModalBody.SelectCard active={selected === defines.DHMAcesso.TECNICO} 
                    onClick={()=>setSelected(defines.DHMAcesso.TECNICO)}>
                        <Body.Icon style={{width: '10px', height: '10px',
                            backgroundColor: selected === defines.DHMAcesso.TECNICO?
                            defines.BlueTec:"rgba(0,0,0,0.05)"}}/>
                        <Body.Text>Técnico</Body.Text>
                    </ModalBody.SelectCard>
            </Body.Row>
            <Body.Row style={{gridGap: '0px 10px', justifyContent: 'center', marginTop: '20px'}}>
                <Button text="Alterar" color="green" action={()=>tryChange()}
                    width={120} />
                <Button text="Cancelar" color="red" action={()=>toggle(false)} disabled={loading}
                    width={120} />
            </Body.Row>
        </>
    </Modal>
}


/** ADICIONAR USUARIO */

type AddUserProps = {
    toggle: Dispatch<SetStateAction<boolean>>,
    nome: string,
    cargo: string,
    action: any,
    idFilial: string,
    idDHM: string
    newUser?: boolean
}

const AddUserToFunction: React.FC <AddUserProps> = ({
    nome, cargo, action, toggle, idFilial, idDHM, newUser
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, toggleLoading] = useState(false) 

     const getLevel = () => {
        switch(cargo){
            case 'Administrador':
                return defines.DHMAcesso.ADMINISTRADOR
            case 'Supervisor':
                return defines.DHMAcesso.SUPERVISOR
            case 'Técnico':
                return defines.DHMAcesso.TECNICO
        }
    }

    const tryCreate = () => {
        toggleLoading(true)
        axios.post(defines.apiURL+"/api/DHMCrud", { idUsuario: idDHM, idFilial, nivelAcesso: getLevel()},  {withCredentials: true})
        .then(response => {
            dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '', text: nome+' agora é um '+cargo})
            toggleLoading(false)
            toggle(false)
            action()
        })
        .catch(error => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
        })
    }

    const tryAdd = () => {
        toggleLoading(true)
        axios.put(defines.apiURL+"/api/DHMCrud", { idDHM, nivelAcesso: getLevel()},  {withCredentials: true})
        .then(response => {
            dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '', text: nome+' agora é um '+cargo})
            toggleLoading(false)
            toggle(false)
            action()
        })
        .catch(error => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
        })
    }

    return <Modal title="" >
                <div style={{display: 'flex', flexDirection: 'column',
                        alignItems: 'center'}}>
                    <Body.Text style={{marginBottom: '20px', fontWeight: 400}}>
                        Conceder privilégios de <strong>{cargo}</strong> para <strong>{nome}</strong> no app
                        <strong> DHM4.0</strong></Body.Text>
                    <Body.Row>
                        {newUser?<Button text="Salvar" width={120} action={()=>tryCreate()} loading={loading} />
                                :<Button text="Salvar" width={200} action={()=>tryAdd()} loading={loading} />}
                        <Button text="Cancelar" width={120} action={()=>toggle(false)}  disabled={loading}/>
                    </Body.Row>
                </div>
            </Modal>
}


/** DELETE USER */

type DeleteUserProps = {
    toggle: Dispatch<SetStateAction<boolean>>,
    nome: string,
    cargo: string,
    action: any,
    idDHM: string
}


const DeleteUser: React.FC <DeleteUserProps> = ({
    nome, toggle, cargo, action, idDHM
}) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, toggleLoading] = useState(false) 


    const tryDelete = () => {
        toggleLoading(true)
        axios.delete(defines.apiURL+"/api/DHMCrud?idDHM="+Functions.ReplaceSpecialCharacters(idDHM),  {withCredentials: true})
        .then(response => {
            dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '', text: nome+' não é mais um '+cargo})
            toggleLoading(false)
            toggle(false)
            action()
        })
        .catch(error => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
        })
    }

    return <Modal title="" >
                <div style={{display: 'flex', flexDirection: 'column',
                        alignItems: 'center'}}>
                    <Body.Text style={{marginBottom: '20px'}}>
                        Remover privilégios de <strong>{cargo}</strong> de <strong>{nome}</strong> no app
                        <strong> DHM4.0</strong></Body.Text>
                    <Body.Row>
                        <Button text="Remover" color="red" width={120}
                            loading={loading} action={()=>tryDelete()} />
                        <Button text="Cancelar" width={120} action={()=>toggle(false)} disabled={loading} />
                    </Body.Row>
                </div>
            </Modal>
}

/** SELECIONAR USUÁRIOS **/

type SelectUser = {
    toggle: Dispatch<SetStateAction<boolean>>,
    idFilial: string,
    cargo: string,
    att: any
}

export const AddUser: React.FC <SelectUser> = ({
    toggle, cargo, idFilial, att
}) => {
    const [selectedUser, setSelectedUser] = useState({id: '', nome: ''})
    const [changePermissionModal, toggleChangePermissionModal] = useState(false)
    const [createModal, toggleCreateModal] = useState(false)
    const [loading, toggleLoading] = useState(false)
    const [filteredUsers, setFilteredUsers] = useState<Types.UserToDHM[]>([])
    const [dataUsers, setDataUsers] = useState<Types.UserToDHM[]>([])
    const dispatch = useDispatch()
    const navigate = useNavigate()

    
    useEffect(()=>{
        toggleLoading(true)
        axios.get(defines.apiURL+"/api/DHMCrud?availableDHMNewUsers=1&idFilial="+Functions.ReplaceSpecialCharacters(idFilial),
            {withCredentials: true})
            .then((response)=>{
                const sorted = Functions.SortResponse(response.data.content, "nomeCompleto")
                setDataUsers(sorted)
                setFilteredUsers(sorted)
                toggleLoading(false)
            })
            .catch((error)=>{
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    }, [dispatch, idFilial, navigate])

    const selectNewUser = (id: string, nome: string) => {
        setSelectedUser({id, nome})
        toggleCreateModal(true)
    }

    const filterData = (name: string) => {
        if(name.length === 0){
            setFilteredUsers(dataUsers)
            return
        }
        const usersFiltered = dataUsers.filter(item => item.nomeCompleto.toUpperCase().includes(name.toUpperCase()))
        setFilteredUsers(usersFiltered!)
    }

    const action = () => {
        att()
        toggle(false)
    }

    return <Modal title="Selecionar usuário" toggleModal={toggle} loading={loading} >
        <>
        {dataUsers.length !== 0?<ModalBody.FindContent>
                <ModalBody.Find onChange={e => filterData(e.target.value)}/> 
                <ModalBody.Icon src={SearchIcon} />       
        </ModalBody.FindContent>:null}
        { (filteredUsers.length === 0)?<ModalBody.Text>Não foram encontrados usuários</ModalBody.Text>:<>
            <ModalBody.Scroll >
        {filteredUsers.map((item, index)=>{
            return <ModalBody.User style={{marginBottom: '5px'}} key={index.toString()} 
            onClick={()=>selectNewUser(item.idUsuario, item.nomeCompleto)}>
                        <Body.Row style={{alignItems: 'center', gridGap: '0px 10px'}}>
                            <ModalBody.UserTitle>{item.nomeCompleto}</ModalBody.UserTitle>
                        </Body.Row>
                    </ModalBody.User>})}
            </ModalBody.Scroll></>}
        {changePermissionModal?<AddUserToFunction nome={selectedUser.nome} idDHM={selectedUser.id} idFilial={idFilial}
            cargo={cargo} toggle={toggleChangePermissionModal} action={action} />:null}
        {createModal?<AddUserToFunction nome={selectedUser.nome} idDHM={selectedUser.id} idFilial={idFilial}
            cargo={cargo} toggle={toggle} action={action} newUser={true} />:null}
        </>
    </Modal>
}

/** GERENCIAR PERMISSÕES **/

type ManagePermissionProps = {
    cargo: string,
    idFilial: string,
    userAcess: number,
    toggle: Dispatch<SetStateAction<boolean>>
}
export const ManagePermissions: React.FC <ManagePermissionProps> = ({
    cargo, toggle, idFilial, userAcess
}) => {
    const [selectUserModal, toggleSelectUserModal] = useState(false)
    const [data, setData] = useState<Types.DHMInfo[]>([])
    const [filteredUsers, setFiltered] = useState<Types.DHMInfo[]>([])
    const [selectedUser, setSelectedUser] = useState({idDHM:'', nomeCompleto: ''})
    const [changePermissionModal, toggleChangePermissionModal] = useState(false)
    const [modalDelete, toggleDelete] = useState(false)
    const [loading, toggleLoading] = useState(true)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const select = (idDHM: string, nomeCompleto: string) => {
        setSelectedUser({idDHM, nomeCompleto})
        toggleChangePermissionModal(true)
    }

    const getBody = () => {
        switch(cargo){
            case 'Administrador':
                return 'DHMAdministratorsList=1'
            case 'Supervisor':
                return 'DHMSupervisorsList=1'
            case 'Técnico':
                return 'DHMTechniciansList=1'
        }
    }

    const getData = () => {
        toggleLoading(true)
        axios.get(defines.apiURL+"/api/DHMCrud?"+getBody()+"&idFilial="+Functions.ReplaceSpecialCharacters(idFilial),
            {withCredentials: true})
            .then((response)=>{
                const sorted = Functions.SortResponse(response.data.content, "nomeCompleto")
                setData(sorted)
                setFiltered(sorted)
                toggleLoading(false)
            })
            .catch((error)=>{
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    }

    useEffect(()=>{
        const getBodyUE = () => {
            switch(cargo){
                case 'Administrador':
                    return 'DHMAdministratorsList=1'
                case 'Supervisor':
                    return 'DHMSupervisorsList=1'
                case 'Técnico':
                    return 'DHMTechniciansList=1'
            }
        }
        toggleLoading(true)
        axios.get(defines.apiURL+"/api/DHMCrud?"+getBodyUE()+"&idFilial="+Functions.ReplaceSpecialCharacters(idFilial),
            {withCredentials: true})
            .then((response)=>{
                const sorted = Functions.SortResponse(response.data.content, "nomeCompleto")
                setData(sorted)
                setFiltered(sorted)
                toggleLoading(false)
            })
            .catch((error)=>{
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    }, [cargo, dispatch, idFilial, navigate])

    const filterData = (name: string) => {
        if(name.length === 0){
            setFiltered(data)
            return
        }
        const filtered = data.filter(item => item.nomeCompleto.toUpperCase().includes(name.toUpperCase()))
        setFiltered(filtered!)
    }

    const selectDelete = (idDHM: string, nomeCompleto: string) => {
        setSelectedUser({idDHM, nomeCompleto})
        toggleDelete(true)
    }

    return <Modal change_permission={{app: 'DHM4.0', function: cargo}} 
        loading={loading}>
        <>
            {data.length !== 0?<ModalBody.FindContent>
                <ModalBody.Find onChange={e => filterData(e.target.value)}/> 
                <ModalBody.Icon src={SearchIcon} />       
            </ModalBody.FindContent>:null}
            {filteredUsers.length === 0?
            <><ModalBody.Text>Não foram encontrados usuários</ModalBody.Text></>:
            <ModalBody.Scroll>
                {filteredUsers.map((item, index)=> {
                    return <Body.Row style={{width: '100%', alignItems: 'center', marginBottom: '5px'}}>
                    <ModalBody.SelectCard style={{width: 'calc(100% - 60px)',
                        height: '40px', padding: '5px', alignItems: 'flex-start',
                        paddingLeft: '10px'}} onClick={()=>select(item.idDHM, item.nomeCompleto)}>
                        <Body.Text>{item.nomeCompleto}</Body.Text>
                    </ModalBody.SelectCard>
                    <ModalBody.ButtonIcon src={TrashIcon} onClick={()=>selectDelete(item.idDHM, item.nomeCompleto)} />
                </Body.Row>
                })}
            </ModalBody.Scroll>}
            <Body.Row style={{justifyContent: "center"}}>
                <Button action={()=>toggleSelectUserModal(true)} text="Adicionar" color="green" width={120} />
                <Button action={()=>toggle(false)} text="Cancelar" width={120}/>
            </Body.Row>
            {selectUserModal?<AddUser att={()=>getData()} toggle={toggleSelectUserModal} cargo={cargo} idFilial={idFilial} />:null}
            {changePermissionModal?<ChangePermission toggle={toggleChangePermissionModal}
                user={selectedUser} action={getData} />:null}
            {modalDelete?<DeleteUser  toggle={toggleDelete} cargo={cargo}  idDHM={selectedUser.idDHM} 
                action={getData} nome={selectedUser.nomeCompleto} />:null}
        </>
    </Modal>
}