import styled, { keyframes } from "styled-components";
import * as defines from '../../../utils/defines/Defines'

export const Container = styled.div`
    background: ${defines.BackgroundColor};
    width: 100vw;
    min-height: 100vh;

    box-sizing: border-box;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    overflow-y: scroll;
`

export const Content = styled.div`
    padding-top: 10px;
    width: 100%;
    display: flex;
    grid-gap: 10px 10px;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;

`

export const Title = styled.h1`
    font-family: 'Nunito';
    font-weight: bold;
    font-size: 18px;
    color: #000;
    margin-bottom: 20px;
`

export const Row = styled.div`
    display: flex;
    grid-gap: 10px 20px;
    flex-wrap: wrap;
`

export const ResultContent = styled.div`
    display: flex;
    flex-direction: column;
`

export const SubTitle = styled.p`
    font-family: 'Nunito';
    font-size: 14px;    
    color: #6D7989;
    margin-bottom: 5px;
`
export const Text = styled.p`
    font-family: 'Nunito';
    font-weight: bold;
    font-size: 14px;    
    color: #000;
    margin-bottom: 5px;
`

export const HeaderButton = styled.div`
    background-color: #1080D6;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    :hover{
        transition: .3s;
        opacity: 0.8
    }
`

export const FindContent = styled.div`
    width: calc(100% - 50px);
    position: relative;
`

export const Icon = styled.img`
    top: 7.5px;
    left: 7.5px;
    width: 15px;
    height: 15px;
    position: absolute;
    z-index: 3;
`
export const Scroll = styled.div`
    width: 100%;
    padding-top: 10px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none; 
    }
    box-sizing: border-box; 
    max-height: 50vh;
`

export const SelectUser = styled.div`
    grid-gap: 0px 10px;
    width: 100%;
    height: 60px;
    border-radius: 12px;
    border: 1px solid rgba(200,200,200,0.5);
    cursor: pointer;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    transition: .5s;
    :hover {
        transition: .5s;
        background: rgba(0,0,0,0.02);
    }
`
export const Find = styled.input`
    width: 100%;
    height: 30px;
    border-radius: 15px;
    border: 1px solid #1080D6;
    padding-left: 30px;
    font-family: "Nunito";
    transition: .5s;
    background: none;
    z-index: 5;
`

export const CardUnit = styled.div`
    width: 100%;
    height: 45px;
    background: #FFF;
    border-radius: 12px;
    border: 1px solid rgba(200,200,200,0.5);
    outline: none;
    transition: .35s;
    display: flex;
    align-items: center;
    padding-left: 15px;
    box-sizing: border-box;
    cursor: pointer;
`

export const TextUnit = styled.a`
    font-family: "Comfortaa";
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    font-size: 13px;
`

export const CardInput = styled.div`
    display: flex;
    grid-gap: 0px 10px;
    padding: 10px;
    box-sizing: border-box;
    background: rgba(0,0,0,0.02);
    border-radius: 5px;
    cursor: pointer;

`