import React from "react";
import './index.css'
import {
    BrowserRouter ,
    Routes,
    Route
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "../redux/store";

import Login from "../pages/login/Login";
import Recover from "../pages/recover/Recover";
import MyAccount from "../pages/myAccount/myAccount";
import ConnectedApps from "../pages/connectedApps/connectedApps";
import ManagePermissions from "../pages/managePermission/managePermission";
import ManageUsers from "../pages/manageUsers/manageUsers";
import Notify from "../components/Notify/Notify";
import ActivateAccount from "../pages/activateAccount/ActivateAccount";
import Auth from "../pages/auth/Auth";
import Privacy from "../pages/privacy/Privacy";
import Alert from "../components/Alert/Alert";
import ManageCompanies from "../pages/manageCompanies/ManageCompanies";
import DHM from "../pages/DHM/DMH";
import Insight from "../pages/Insight/Insight";
import PrivacyCompany from "../pages/privacyCompany/PrivacyCompany";

const RoutesDesktop = () => {
    return <BrowserRouter>
                <Provider store={store}>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/recuperar" element={<Recover />} />
                    <Route path="/minha-conta" element={<MyAccount />} />
                    <Route path="/apps-conectados" element={<ConnectedApps />} />
                    <Route path="/gerenciar-dhm" element={<DHM />} />
                    <Route path="/gerenciar-insight" element={<Insight />} />
                    <Route path="/gerenciar-permissoes" element={<ManagePermissions />} />
                    <Route path="/gerenciar-usuarios" element={<ManageUsers />} />
                    <Route path="/gerenciar-empresas" element={<ManageCompanies />} />
                    <Route path="/ativar-conta" element={<ActivateAccount />} />
                    <Route path="/autenticacao" element={<Auth />} />
                    <Route path="/privacidade" element={<Privacy />} />
                    <Route path="/privacidade-empresa" element={<PrivacyCompany />} />
                </Routes>
                <Notify />
                <Alert />
                </Provider>
            </BrowserRouter>
}

export default RoutesDesktop