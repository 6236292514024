import axios from 'axios'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Button from '../../../components/Buttons/Button/Button'
import Input from '../../../components/Inputs/Input/Input'
import Modal from '../../../components/Modals/Modal/Modal'
import * as Body from '../Styles'
import * as Types from '../../../../utils/types/Types'
import * as Functions from '../../../../utils/functions/Functions'
import { useNavigate } from 'react-router-dom'
import { apiURL } from '../../../../utils/defines/Defines'
import SearchIcon from '../../../../assets/icons/Search.svg'

type AddCompanyProps = {
    toggle: Dispatch<SetStateAction<boolean>>,
    getData: any,
}

export const AddCompany: React.FC <AddCompanyProps> = ({
    toggle, getData
}) => {
    const [nomeEmpresa, setEmpresa] = useState('')
    const [cnpj, setCnpj] = useState('')
    
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, toggleLoading] = useState(false)

    const tryCreate = () => {
        toggleLoading(true)
        axios.post(apiURL+"/api/EmpresaCRUD", {cnpj, nomeEmpresa},  {withCredentials: true})
        .then(response => {
            dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '', text: 'Empresa cadastrada com sucesso'})
            getData()
            toggleLoading(false)
            toggle(false)
        })
        .catch(error => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
        })
    }

    return <Modal toggleModal={toggle} title="Cadastrar empresa">
       <>
        <Body.Scroll style={{width: '400px', 
            gridGap: '10px 0px', marginBottom: '10px'}}>        
            <Input text={nomeEmpresa} setText={setEmpresa} title="Nome" />
            <div style={{height: '10px'}} />
            <Input text={cnpj} setText={setCnpj} title="CNPJ" />
            <div style={{height: '10px'}} />
        </Body.Scroll>
        <Button text="Criar" action={()=>tryCreate()} loading={loading} />
        </>
    </Modal>

}

type CreateUnityProps = {
    toggle: Dispatch<SetStateAction<boolean>>,
    id: string,
    nomeEmpresa: string, 
    getData: any,
}

const CreateUnity: React.FC <CreateUnityProps> = ({
    toggle, id, nomeEmpresa, getData
}) => {
    const [loading, toggleLoading] = useState(false)
    const [nomeFilial, setNome] = useState('')
    const [cnpj, setCNPJ] = useState('')
    const [endereco, setEndereco] = useState('')

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const tryCreate = () => {
        toggleLoading(true)
        axios.post(apiURL+"/api/FilialCRUD", {
            nomeFilial, cnpj, endereco, idEmpresa: id
        },  {withCredentials: true})
        .then((response)=>{
            dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '', text: 'Unidade criada com sucesso'})
            getData()
            toggleLoading(false)
            toggle(false)
        })
        .catch(error => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
        })
    }

    return <Modal toggleModal={toggle} title="Cadastrar filial" subtitle={nomeEmpresa}>
    <>
     <Body.Scroll style={{width: '400px', 
         gridGap: '10px 0px', marginBottom: '10px'}}>        
         <Input text={nomeFilial} setText={setNome} title="Nome" />
         <div style={{height: '10px'}} />
         <Input text={cnpj} setText={setCNPJ} title="CNPJ" />
         <div style={{height: '10px'}} />
         <Input text={endereco} setText={setEndereco} title="Endereço" />
         <div style={{height: '10px'}} />
     </Body.Scroll>
     <Button text="Criar" action={()=>tryCreate()} loading={loading} />
     </>
 </Modal>
}

type ConfirmDeleteProps = {
    toggle: Dispatch<SetStateAction<boolean>>,
    id: string,
    nome: string,
    action: any,
}

const ConfirmDeleteUnity: React.FC <ConfirmDeleteProps> = ({
    toggle, id, action, nome
}) => {
    const [loading, toggleLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const tryDelete = () => {
        toggleLoading(true)
        axios.delete(apiURL+"/api/FilialCRUD?idFilial="+Functions.ReplaceSpecialCharacters(id),
        {withCredentials: true})
        .then(response => {
            dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '', text: 'Unidade excluida com sucesso'})
            toggleLoading(false)
            action()
            toggle(false)
        })
        .catch(error => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
        })
    }

    return <Modal title="">
        <div style={{display: 'flex', flexDirection: 'column',
            alignItems: 'center'}}>
            <Body.Text style={{marginBottom: '20px', fontWeight: 400}}>
                Excluir <strong>{nome}</strong></Body.Text>
            <Body.Row>
                <Button text="Excluir" width={200} color="red" 
                    action={()=>tryDelete()} loading={loading} />
                <Button text="Cancelar" width={200} action={()=>toggle(false)} 
                    disabled={loading} />
            </Body.Row>
        </div>
    </Modal>
}


export const ConfirmDeleteCompany: React.FC <ConfirmDeleteProps> = ({
    toggle, id, action, nome
}) => {
    const [loading, toggleLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const tryDelete = () => {
        toggleLoading(true)
        axios.delete(apiURL+"/api/EmpresaCRUD?idEmpresa="+Functions.ReplaceSpecialCharacters(id),
            {withCredentials: true})
            .then(response => {
                dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '', text: 'Empresa excluida com sucesso'})
                toggleLoading(false)
                action()
                toggle(false)
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    }

    return <Modal title="">
        <div style={{display: 'flex', flexDirection: 'column',
            alignItems: 'center'}}>
            <Body.Text style={{marginBottom: '20px', fontWeight: 400}}>
                Excluir <strong>{nome}</strong></Body.Text>
            <Body.Row>
                <Button text="Excluir" width={200} color="red" 
                    action={()=>tryDelete()} loading={loading} />
                <Button text="Cancelar" width={200} action={()=>toggle(false)} 
                    disabled={loading} />
            </Body.Row>
        </div>
    </Modal>
}

type UnityProps = {
    idUnidade: string,
    nomeEmpresa: string,
    getData: any,
    toggle: Dispatch<SetStateAction<boolean>>
}

const Unity: React.FC <UnityProps> = ({
    idUnidade, nomeEmpresa, toggle, getData
}) => {
    const [loading, toggleLoading] = useState(true)
    const [loadingBTN, toggleLoadingBTN] = useState(false)
    const [nomeFilial, setNome] = useState('')
    const [cnpj, setCNPJ] = useState('')
    const [endereco, setEndereco] = useState('')

    const [modalDelete, toggleDelete] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const tryUpdate = () => {
        toggleLoadingBTN(true)
        axios.put(apiURL+"/api/FilialCRUD", {
            nomeFilial, endereco, idFilial: idUnidade
        },  {withCredentials: true})
        .then((response)=>{
            dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '', text: 'Unidade alterada com sucesso'})
            getData()
            getUnityData()
            toggleLoading(false)
            toggleLoadingBTN(false)
        })
        .catch(error => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
        })
    }

    const getUnityData = () => {
        toggleLoading(true)
        axios.get(apiURL+"/api/FilialCRUD?id="+Functions.ReplaceSpecialCharacters(idUnidade),
            {withCredentials: true})
        .then(response => {
            setNome(response.data.content.nomeFilial)
            setCNPJ(response.data.content.cnpj)
            setEndereco(response.data.content.endereco)
            toggleLoading(false)
        }) 
        .catch(error => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
        })
    }

    const action = () => {
        getData()
        toggle(false)
    }

    useEffect(()=>{
        toggleLoading(true)
        axios.get(apiURL+"/api/FilialCRUD?id="+Functions.ReplaceSpecialCharacters(idUnidade),
            {withCredentials: true})
            .then(response => {
                setNome(response.data.content.nomeFilial)
                setCNPJ(response.data.content.cnpj)
                setEndereco(response.data.content.endereco)
                toggleLoading(false)
            }) 
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    }, [dispatch, navigate, idUnidade])

    return <Modal toggleModal={toggle} title={nomeFilial} subtitle={nomeEmpresa} loading={loading}>
    <>
     <Body.Scroll style={{width: '400px', 
            gridGap: '10px 0px', marginBottom: '10px'}}>        
            <Input text={nomeFilial} setText={setNome} title="Nome" />
            <div style={{height: '10px'}} />
            <Input text={cnpj} setText={setCNPJ} title="CNPJ" disabled />
            <div style={{height: '10px'}} />
            <Input text={endereco} setText={setEndereco} title="Endereço" />
            <div style={{height: '10px'}} />
        </Body.Scroll>
        <Body.Row>
            <Button text="Salvar" width={190} action={()=>tryUpdate()} loading={loadingBTN} />
            <Button text="Excluir" width={190} color="red" action={()=>toggleDelete(true)}  />
        </Body.Row>
        {modalDelete?<ConfirmDeleteUnity toggle={toggleDelete} nome={nomeFilial} id={idUnidade}
            action={action} />:null}
        </>
    </Modal>
}

type UnitsProps = {
    id: string,
    nomeEmpresa: string, 
    toggle: Dispatch<SetStateAction<boolean>>
}

export const Units: React.FC <UnitsProps> = ({
    id, nomeEmpresa, toggle
}) => {

    const [loading, toggleLoading] = useState(true)
    const [filiais, setFiliais] = useState<Types.FilialInfo[]>([])
    const [filteredFiliais, setFiltered] = useState<Types.FilialInfo[]>([])

    const [idFilial, setId] = useState('')

    const [createModal, toggleCreate] = useState(false)
    const [unityModal, toggleUnity] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const getData = () => {
        toggleLoading(true)
        axios.get(apiURL+"/api/FilialCRUD?idEmpresa="+Functions.ReplaceSpecialCharacters(id),
            {withCredentials: true})
        .then((response)=>{
            const sorted = Functions.SortResponse(response.data.content, "nomeFilial")
            setFiliais(sorted)
            setFiltered(sorted)
            toggleLoading(false)
        })
        .catch(error => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
        })
    }

    useEffect(()=>{
        toggleLoading(true)
        axios.get(apiURL+"/api/FilialCRUD?idEmpresa="+Functions.ReplaceSpecialCharacters(id),
            {withCredentials: true})
            .then((response)=>{
                const sorted = Functions.SortResponse(response.data.content, "nomeFilial")
                setFiliais(sorted)
                setFiltered(sorted)
                toggleLoading(false)
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    },[dispatch, navigate, id])

    const select = (id: string, nome: string) => {
        setId(id)
        toggleUnity(true)
    }

    const filterData = (name: string) => {
        if(name.length === 0){
            setFiltered(filiais)
            return
        }
        const filtered = filiais.filter(item => item.nomeFilial.toUpperCase().includes(name.toUpperCase()))
        setFiltered(filtered!)
    }


    return <Modal title={nomeEmpresa} subtitle="Unidades" toggleModal={toggle} loading={loading} 
        add={()=>toggleCreate(true)}>
        <>
        <Body.FindContent style={{width: '100%'}}>
                <Body.Find onChange={e => filterData(e.target.value)} style={{maxWidth: '100%', width: '100%'}}/> 
                <Body.Icon src={SearchIcon} />       
        </Body.FindContent>
        <Body.Scroll style={{width: '400px'}}>
            {filteredFiliais.map((item, index)=>{
                return <Body.SelectUser key={index.toString()}
                    onClick={()=>select(item.idFilial, item.nomeFilial)}>
                    <Body.Text>{item.nomeFilial}</Body.Text>
                </Body.SelectUser>
            })}
        </Body.Scroll>
        {createModal?<CreateUnity toggle={toggleCreate} nomeEmpresa={nomeEmpresa} id={id} 
            getData={getData} />:null}
        {unityModal?<Unity getData={getData} nomeEmpresa={nomeEmpresa}
            idUnidade={idFilial} toggle={toggleUnity}/>:null}
        
        </>
    </Modal>
}