import styled, {keyframes} from "styled-components";

export const Container = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    background: #FFF;
    z-index: 200;
`

const animateIcon = keyframes`
    0%{
        transform: rotate(0deg);
        width: 100px;
        height: 100px;
    }
    20%{
        width: 100px;
        height: 100px;
    }
    40%{
        width: 110px;
        height: 110px;
    }
    60%{
        width: 110px;
        height: 110px;
    }
    80%{
        width: 100px;
        height: 100px;
    }
    100%{
        transform: rotate(360deg);
    }
`

export const Icon = styled.img`
    width: 100px;
    height: 100px;
    animation: 2s ${animateIcon} infinite;
`