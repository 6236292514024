import React, {useState, useRef, useEffect} from 'react'
import Loading from '../../components/Loading/Loading'
import Sidebar from '../../components/SideBar/Sidebar'
import * as Body from  './Styles'
import * as defines from '../../../utils/defines/Defines'
import SearchIcon from '../../../assets/icons/Search.svg'
import AddIcon from '../../../assets/icons/add-icon.svg'
import * as Types from '../../../utils/types/Types'
import * as Functions from '../../../utils/functions/Functions'
import * as Modals from './modals/Modals'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import IsAuth from '../../components/Modals/IsAuth/IsAuth'

const ManageCompanies = () => {
    const inputCompanyRef = useRef<any>()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isAuthCreate, toggleAuthCreate] = useState(false)

    const [loading, toggleLoading] = useState(true)
    const [modalAddCompany, toggleModalCompany] = useState(false)

    const [companies, setCompanies] = useState<Types.EmpresaInfo[]>([])

    const [filteredCompanies, setFilteredCompanies] = useState<Types.EmpresaInfo[]>([])

    const getCompanies = () => {
        toggleLoading(true)
        axios.get(defines.apiURL+"/api/EmpresaCRUD",  {withCredentials: true})
        .then(response => {
            const sorted = Functions.SortResponse(response.data.content, "nomeEmpresa")
            setCompanies(sorted)
            setFilteredCompanies(sorted)
            setTimeout(()=>{
                toggleLoading(false)
            }, 2000)
        })
        .catch(error => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
        })
    }

    useEffect(()=>{
        toggleLoading(true)
        axios.get(defines.apiURL+"/api/EmpresaCRUD",  {withCredentials: true})
        .then(response => {
            const sorted = Functions.SortResponse(response.data.content, "nomeEmpresa")
            setCompanies(sorted)
            setFilteredCompanies(sorted)
            setTimeout(()=>{
                toggleLoading(false)
            }, 2000)
        })
        .catch(error => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
        })
    }, [dispatch, navigate])

    const filterCompanyData = (name: string) => {
        if(name.length === 0){
            setFilteredCompanies(companies)
            return
        }
        const filtered = companies.filter(item => item.nomeEmpresa.toUpperCase().includes(name.toUpperCase()))
        setFilteredCompanies(filtered!)
    }

    const selectCompany = (company: Types.EmpresaInfo) => {
        navigate("/alterar-dados-empresa", {state: {empresa: Functions.ReplaceSpecialCharacters(company.idEmpresa)}})
    }

    return <Body.Container>
                <Sidebar content={defines.ContentSidebar.MANAGE_COMPANY} />
                <Body.Content>
                    <Body.Title >
                        Empresas
                    </Body.Title>
                    <Body.Row style={{width: "100%"}}>
                        <Body.FindContent>
                            <Body.Find onChange={e => filterCompanyData(e.target.value)}
                                ref={inputCompanyRef} />
                            <Body.Icon src={SearchIcon} style={{cursor: 'text'}}
                                onClick={()=>inputCompanyRef.current.focus()} />
                        </Body.FindContent>
                        <Body.HeaderButton onClick={()=>toggleAuthCreate(true)} >
                            <Body.Icon src={AddIcon} style={{position: 'relative', top: 0, left: 0}} />
                        </Body.HeaderButton>
                    </Body.Row> 
                    <Body.Scroll style={{maxHeight: 'calc(100vh - 200px)'}}>
                        {filteredCompanies.map((item, index) => {
                            return <Body.SelectUser onClick={()=>selectCompany(item)} 
                                key={index.toString()} >
                                <Body.Text>{item.nomeEmpresa}</Body.Text>
                            </Body.SelectUser>
                        })}

                    </Body.Scroll>
                </Body.Content>  
                {loading?<Loading />:null}
                {isAuthCreate?<IsAuth action={()=>toggleModalCompany(true)} toggleIsAuth={toggleAuthCreate} />:null}
                {modalAddCompany?<Modals.AddCompany toggle={toggleModalCompany} getData={getCompanies}  />:null}      
            </Body.Container>
}

export default ManageCompanies 